package com.kelimesoft.cowmaster.models

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class NewListCow(
    var hid: Long = -1,
    var kupe: String = "",
    var name: String = "",
    var gender: Int = 0,
    var dogum: String = "",
    var breed: String = "",
    var dam: String = "",
    var sire: String = "",
    var notes: String = "",
    //var herd: Long = -1L,
    //var image: String = ""
) {
    var errorText by mutableStateOf("")

    fun checkNewCow(): Boolean {
        if (kupe.isEmpty()) {
            errorText = "newcow_kuperr".tr()
        } else if (dogum.isEmpty()) {
            errorText = "newcow_dogumerr".tr()
        }
        return errorText.isNotEmpty()
    }
}

@Serializable
data class ErrorCow(
    var hid: Long,
    var error: String
)


