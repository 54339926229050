package com.kelimesoft.cowmaster.models

class GestationDay(private val breed: String) {
    private val gestationDays = mapOf(
        "simental" to 284,
        "simenta" to 284,
        "siment" to 284,
        "simen" to 284,
        "sime" to 284,
        "sim" to 284,
        "si" to 284,
        "simmental" to 284,
        "simmenta" to 284,
        "simment" to 284,
        "simmen" to 284,
        "simme" to 284,
        "siyah alaca" to 277,
        "siyah ala" to 277,
        "alaca" to 277,
        "simm" to 284,
        "jersey" to 279,
        "jerse" to 279,
        "jers" to 279,
        "jer" to 279,
        "je" to 279,
        "cerse" to 279,
        "cers" to 279,
        "cer" to 279,
        "ce" to 279,
        "brown swiss" to 288,
        "brown swis" to 288,
        "brown swi" to 288,
        "brown sw" to 288,
        "brown s" to 288,
        "brown " to 288,
        "brownswiss" to 288,
        "brownswis" to 288,
        "brownswi" to 288,
        "brownsw" to 288,
        "browns" to 288,
        "brown" to 288,
        "brow" to 288,
        "bro" to 288,
        "br" to 288,
        "montofon" to 288,
        "montofo" to 288,
        "montof" to 288,
        "monto" to 288,
        "mont" to 288,
        "mon" to 288,
        "mo" to 288,
        "mondofon" to 288,
        "mondofo" to 288,
        "mondof" to 288,
        "mondo" to 288,
        "mond" to 288,
        "ayrshire" to 279,
        "ayrshir" to 279,
        "ayrshi" to 279,
        "ayrsh" to 279,
        "ayrs" to 279,
        "ayr" to 279,
        "ay" to 279,
        "holstein" to 277,
        "holstei" to 277,
        "holste" to 277,
        "holst" to 277,
        "hols" to 277,
        "holş" to 277,
        "hol" to 277,
        "ho" to 277,
        "hoştayn" to 277,
        "hoşteyn" to 277,
        "hoştey" to 277,
        "hoşte" to 277,
        "hoşta" to 277,
        "hoşt" to 277,
        "hoş" to 277,
        "şarole" to 285,
        "şarol" to 285,
        "şaro" to 285,
        "şar" to 285,
        "şa" to 285,
        "charolais" to 285,
        "charolai" to 285,
        "charola" to 285,
        "charol" to 285,
        "charo" to 285,
        "char" to 285,
        "cha" to 285,
        "ch" to 285,
        "gelbvieh" to 287,
        "gelbvi" to 287,
        "gelbv" to 287,
        "gel" to 287,
        "blonde d’aquitaine" to 294,
        "blonde" to 294,
        "texas longhorn" to 287,
        "texas" to 287,
        "longhorn" to 287,
        "long" to 287,
        "aberdeen angus" to 285,
        "aberdeen" to 285,
        "aberdee" to 285,
        "aberde" to 285,
        "aberd" to 285,
        "aber" to 285,
        "angus" to 285,
        "angu" to 285,
        "ang" to 285,
        "an" to 285,
        "red angus" to 285,
        "red angu" to 285,
        "black angus" to 285,
        "black angu" to 285,
        "black ang" to 285,
        "black an" to 285,
        "black a" to 285,
        "black" to 285,
        "limousin" to 285,
        "limousi" to 285,
        "limous" to 285,
        "limou" to 285,
        "limo" to 285,
        "lim" to 285,
        "limuzin" to 285,
        "limuzi" to 285,
        "limuz" to 285,
        "limu" to 285,
        "belgian blue" to 286,
        "belgian" to 286,
        "blue" to 286,
        "belçika mavisi" to 286,
        "belçika" to 286,
        "mavisi" to 286,
        "montbeliarde" to 287,
        "montbeli" to 287,
        "montb" to 287,
        "hereford" to 285,
        "herefor" to 285,
        "herefo" to 285,
        "heref" to 285,
        "here" to 285,
        "her" to 285,
        "buffalo" to 312,
        "buffal" to 312,
        "buffa" to 312,
        "buff" to 312,
        "bufa" to 312,
        "buf" to 312,
        "kömüş" to 312,
        "kömü" to 312,
        "köm" to 312,
        "camış" to 312,
        "camız" to 312,
        "camı" to 312,
        "cam" to 312,
        "manda" to 312,
        "mand" to 312,
        "man" to 312,
        "بھینس" to 312,
        "بھین" to 312
    )
    fun getGestation(): Int {
        val brdlen = breed.length
        if (brdlen > 2) {
            val smlbreed = breed.lowercase()
            for (i in (2..brdlen).reversed()) {
                val prefix = smlbreed.subSequence(0, i)
                val gest = gestationDays[prefix]
                if (gest != null) {
                    return gest
                }
            }
        }
        return 283
    }
}