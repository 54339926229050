package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.dateDiff
import kotlin.js.Date


data class CowKiloData(
    val kiloList: List<CowEvent>,
) {
    var latestGain: Double = 0.0
    var totalGain: Double = 0.0

    init {
        if (kiloList.size > 1) {
            val ilkkilo = kiloList[0].deger.toDouble()
            val ilktrh = Date(kiloList[0].date)
            val sonkilo = kiloList[kiloList.size - 1].deger.toDouble()
            val sontrh = Date(kiloList[kiloList.size - 1].date)
            val oncekikilo = kiloList[kiloList.size - 2].deger.toDouble()
            val oncekitrh = Date(kiloList[kiloList.size - 2].date)
            totalGain = (sonkilo - ilkkilo) / dateDiff(sontrh, ilktrh, "d").toDouble()
            latestGain = (sonkilo - oncekikilo) / dateDiff(sontrh, oncekitrh, "d").toDouble()
        }
    }
}

