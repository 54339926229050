package com.kelimesoft.cowmaster.pages.finance

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.FinanceVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.components.forms.Switch
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorScheme
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import kotlin.js.Date




@Composable
fun AddFinItem(
    scope: CoroutineScope, type: FinType,
    financeVM: FinanceVM, finItem: FinItem? = null,
    onClose: () -> Unit
) {
    val today = Date()
    var errorText by remember { mutableStateOf("") }
    var finDate by remember { mutableStateOf(Date()) }
    var payDate by remember { mutableStateOf(Date().nextMonth()) }
    var finCat by remember { mutableStateOf("") }
    var finTutar = remember { mutableStateOf(0.0) }
    var finNotes by remember { mutableStateOf("") }
    var payMethod by remember { mutableStateOf(PayMethod.Cash) }
    var finPaid by remember { mutableStateOf(0) }

    var taksitList = remember { mutableStateListOf<FinTaksit>() }

    LaunchedEffect(true) {

        if (finItem != null) {
            console.log("fnitem:", finItem.toString())
            finDate = Date(finItem.gdate!!)
            (document.getElementById("fin-date") as HTMLInputElement).value = finDate.dateToDbStr()
            finCat = finItem.cat
            (document.getElementById("fin-cat") as HTMLInputElement).value = finCat
            finNotes = finItem.notes
            (document.getElementById("fin-notes") as HTMLInputElement).value = finNotes
            finTutar.value = finItem.tutar
            (document.getElementById("fin-tutar") as HTMLInputElement).value = finTutar.value.toString()

            payMethod = finItem.method
            finPaid = finItem.paid
            payDate = Date(finItem.pdate!!)
            if (payMethod == PayMethod.Vade || payMethod == PayMethod.Card || payMethod == PayMethod.Taksit) {
                (document.getElementById("fin-paydate") as HTMLInputElement).value = payDate.dateToDbStr()
            }


        }
    }

    fun addNewItems() {
        if (!Funcs.checkForm("form-fin")) {
            return
        }
        val finItemList = mutableListOf<FinItem>()
        if (payMethod == PayMethod.Taksit) {
            val topTak = taksitList.size
            var takNum = 1
            for (taksit in taksitList) {
                if (taksit.tTutar <= 0) {
                    errorText = "Taksit tutarı 0.0'dan büyük olmalı!"
                    return
                }
                val notes = "($takNum/$topTak) $finNotes"
                val tutar = taksit.tTutar.rountTo(2)
                val nItem = FinItem(
                    id = 0,
                    type = type,
                    tutar = tutar,
                    cat = finCat,
                    notes = notes,
                    gdate = finDate.dateToDbStr(),
                    pdate = taksit.pDate.dateToDbStr(),
                    method = payMethod,
                    paid = 0
                )
                finItemList.add(nItem)
                takNum++
            }
        } else {
            if (finTutar.value <= 0.0) {
                errorText = "İşlem tutarı 0.0'dan büyük olmalı!"
                return
            }

            if (payMethod == PayMethod.Cash) {
                payDate = finDate
            }
            val nItem = FinItem(
                id = 0,
                type = type,
                tutar = finTutar.value.rountTo(2),
                cat = finCat,
                notes = finNotes,
                gdate = finDate.dateToDbStr(),
                pdate = payDate.dateToDbStr(),
                method = payMethod,
                paid = if (payMethod == PayMethod.Cash) 1 else 0
            )
            finItemList.add(nItem)
        }
        scope.launch {
            financeVM.addNewFinItems(type, finItemList)
            onClose()
        }
    }

    fun ifChanged(f1: FinItem, f2: FinItem): Boolean {
        if (f1.paid != f2.paid) return true
        if (f1.tutar != f2.tutar) return true
        if (f1.cat != f2.cat) return true
        if (f1.notes != f2.notes) return true
        if (f1.gdate != f2.gdate) return true
        if (f1.pdate != f2.pdate) return true
        return false
    }

    fun editFinItem(){
        if (!Funcs.checkForm("form-fin")) {
            return
        }
        finItem?.let {fin ->
            if (payMethod == PayMethod.Cash) {
                payDate = finDate
            }
            val nItem = FinItem(
                id = fin.id,
                type = fin.type,
                tutar = finTutar.value.rountTo(2),
                cat = finCat,
                notes = finNotes,
                gdate = finDate.dateToDbStr(),
                pdate = payDate.dateToDbStr(),
                method = payMethod,
                paid = if (payMethod == PayMethod.Cash) 1 else finPaid
            )
            if(ifChanged(fin, nItem)){
                scope.launch {
                    financeVM.updateFinItem(nItem)
                    onClose()
                }
            }else{
                onClose()
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle(
            if (type == FinType.Gelir) "finadd_gelir".tr()
            else "finadd_gider".tr(), onClose = onClose
        )

        Column(
            Modifier
                .fillMaxWidth()
                .padding(topBottom = 6.px, leftRight = 8.px)
        ) {

            Form(

                attrs = Modifier
                    .id("form-fin")
                    .fillMaxWidth()
                    .toAttrs()
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.Start
                ) {
                    if (finItem != null){
                        Span(
                            attrs = Modifier
                                .fontSize(14.px)
                                .margin(leftRight = 6.px, top = 4.px)
                                .fontWeight(FontWeight.SemiBold)
                                .padding(1.px)
                                .color(AppColors.TextColor.copyf(alpha = 0.6f))
                                .toAttrs()
                        ) {
                            Text("Ödeme Yöntemi: ${payMethod.text}")
                        }
                    }else {
                        Span(
                            attrs = Modifier
                                .fontSize(12.px)
                                .margin(leftRight = 6.px, top = 4.px)
                                .padding(1.px)
                                .color(AppColors.TextColor.copyf(alpha = 0.6f))
                                .toAttrs()
                        ) {
                            Text("Ödeme Yöntemi".tr())
                        }
                        Row(
                            modifier = Modifier.fillMaxWidth()
                                .padding(4.px)
                                .borderRadius(r = 8.px)
                                .backgroundColor(Colors.White.copyf(alpha = 0.9f))
                                .boxShadow(1.px, 1.px, 1.px, color = Colors.Black.copyf(alpha = 0.2f)),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceAround
                        ) {
                            PayMethod.entries.forEach { method ->
                                Box(
                                    contentAlignment = Alignment.Center,
                                    modifier = PayMethodStyle.toModifier()
                                        .padding(3.px)
                                        .color(AppColors.TextColor.opacity(if (method == payMethod) 1f else 0.5f))
                                        .fontSize(14.px)
                                        .margin(2.px)
                                        .backgroundColor(Colors.Gray.copyf(alpha = if (method == payMethod) 0.4f else 0.1f))
                                        .onClick {
                                            if (payMethod != method) {
                                                payMethod = method
                                                if (payMethod == PayMethod.Taksit && taksitList.isEmpty()) {
                                                    taksitList.add(FinTaksit(finDate.nextMonth(), finTutar.value))
                                                } else if (payMethod == PayMethod.Vade || payMethod == PayMethod.Card) {
                                                    payDate = finDate.nextMonth()
                                                }
                                            }
                                        }
                                ) {
                                    Text(method.text)
                                }
                            }
                        }
                    }

                    Row(
                        modifier = Modifier.fillMaxWidth()
                            .margin(top = 6.px, bottom = 10.px),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        Column(
                            modifier = Modifier.fillMaxWidth()
                                .padding(1.px)
                        ) {
                            Span(
                                attrs = Modifier
                                    .fontSize(12.px)
                                    .padding(left = 4.px)
                                    .color(AppColors.TextColor.copyf(alpha = 0.6f))
                                    .toAttrs()
                            ) {
                                Text("finadd_date".tr())
                            }
                            Input(
                                type = InputType.Date,
                                attrs = CowInputStyle.toModifier()
                                    .id("fin-date")
                                    .padding(5.px)
                                    .attrsModifier {
                                        attr("required", "true")
                                        attr("value", finDate.dateToDbStr())
                                        attr("max", Date().endOfDay().dateToDbStr())
                                    }
                                    .toAttrs()
                                    {
                                        onInput {
                                            finDate = Date(it.value)
                                        }
                                    }
                            )
                        }


                        if (payMethod == PayMethod.Card || payMethod == PayMethod.Vade ||
                            (finItem != null && payMethod == PayMethod.Taksit)
                            ) {
                            Column(
                                modifier = Modifier.fillMaxWidth()
                                    .margin(left = 2.px)
                                    .padding(1.px)
                            ) {
                                Span(
                                    attrs = Modifier
                                        .fontSize(12.px)
                                        .padding(left = 4.px)
                                        .color(AppColors.DarkRed.copyf(alpha = 0.6f))
                                        .toAttrs()
                                ) {
                                    Text("finadd_paydate".tr())
                                }
                                Input(
                                    type = InputType.Date,
                                    attrs = CowInputStyle.toModifier()
                                        .id("fin-paydate")
                                        .padding(5.px)
                                        .color(if (finPaid == 1) AppColors.PrimaryDark else AppColors.DarkRed)
                                        .attrsModifier {
                                            attr("required", "true")
                                            attr("value", payDate.dateToDbStr())
                                            attr("min", finDate.dateToDbStr())
                                        }
                                        .toAttrs()
                                        {
                                            onInput {
                                                payDate = Date(it.value)
                                            }
                                        }
                                )
                            }
                        }
                    }
                }

                Input(
                    type = InputType.Text,
                    attrs = CowInputStyle.toModifier()
                        .id("fin-cat")
                        .attrsModifier {
                            attr("required", "true")
                            attr("name", "cat")
                            attr("placeholder", "finadd_cat".tr())
                        }
                        .margin(bottom = 8.px)

                        .toAttrs()
                        {
                            onInput {
                                finCat = it.value
                            }
                        }
                )
                if (finItem == null && payMethod == PayMethod.Taksit) {
                    Column(
                        modifier = Modifier.fillMaxWidth()
                            .backgroundColor(AppColors.Background.opacity(0.2f))
                            .borderRadius(r = 4.px)
                            .padding(2.px)
                            .boxShadow(1.px, (-1).px, (-1).px, color = Colors.Black.copyf(alpha = 0.2f))
                            .margin(bottom = 4.px),
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween,
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            Span(
                                attrs = Modifier
                                    .fontSize(14.px)
                                    .margin(leftRight = 6.px)
                                    .padding(1.px)
                                    .color(AppColors.TextColor.copyf(alpha = 0.6f))
                                    .toAttrs()
                            ) {
                                Text("Taksit Sayısı: ${taksitList.size}")
                            }

                            Row {
                                if (taksitList.size < 9) {
                                    Button(
                                        attrs = TextButtonStyle.toModifier()
                                            .color(AppColors.PrimaryDark)
                                            .fontWeight(FontWeight.SemiBold)
                                            .onClick {
                                                val laterDate = taksitList[taksitList.size - 1].pDate.nextMonth()
                                                val lastTutar = taksitList[taksitList.size - 1].tTutar
                                                taksitList.add(
                                                    FinTaksit(
                                                        laterDate,
                                                        if (lastTutar > 0) lastTutar else 0.0
                                                    )
                                                )
                                                it.preventDefault()
                                            }
                                            .toAttrs()) {
                                        Text("+Ekle")
                                    }
                                }
                                if (taksitList.size > 1) {
                                    Button(attrs = TextButtonStyle.toModifier()
                                        .color(AppColors.DarkRed)
                                        .fontWeight(FontWeight.SemiBold)
                                        .onClick {
                                            taksitList.removeLast()
                                            it.preventDefault()
                                        }
                                        .toAttrs()) {
                                        Text("-Remove")
                                    }
                                }
                            }

                        }
                        VDivider()
                        taksitList.forEachIndexed { index, finTaksit ->
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                horizontalArrangement = Arrangement.SpaceBetween,
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                //DateInput(finDate.dateToDbStr())
                                Span(
                                    attrs = Modifier
                                        .fontSize(14.px)
                                        .margin(leftRight = 6.px)
                                        .padding(1.px)
                                        .color(AppColors.TextColor.copyf(alpha = 0.6f))
                                        .toAttrs()
                                ) {
                                    Text("Taksit:${index + 1}")
                                }
                                Input(
                                    type = InputType.Date,
                                    attrs = CowInputStyle.toModifier()
                                        .fontSize(13.px)
                                        .id("fin-date$index")
                                        .padding(4.px)
                                        .attrsModifier {
                                            attr("required", "true")
                                            attr("value", finTaksit.pdate.dateToDbStr())
                                            //attr("min", Date().endOfDay().dateToDbStr())
                                        }
                                        .toAttrs()
                                        {
                                            onInput {
                                                finTaksit.pDate = Date(it.value)
                                            }
                                        }
                                )

                                Input(
                                    type = InputType.Text,
                                    attrs = CowInputStyle.toModifier()
                                        .id("fin-tutar$index")
                                        .fontSize(15.px)
                                        .padding(4.px)
                                        .textAlign(TextAlign.End)
                                        .attrsModifier {
                                            inputMode(InputMode.Decimal)
                                            attr("value", if (finTaksit.tTutar > 0.0) "${finTaksit.tTutar}" else "")
                                            attr("required", "true")
                                            attr("min", "1")
                                            attr("placeholder", "0.00")
                                        }
                                        .toAttrs()
                                        {
                                            onInput {
                                                finTaksit.tTutar = it.value.toDouble()
                                                //console.log("deger:", eventDeger.value)
                                            }
                                        }
                                )
                            }

                        }
                    }
                } else {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        FinTutar(finTutar)
                        if (finItem != null && payMethod != PayMethod.Cash){
                            Row(
                                modifier = Modifier.margin(left = 6.px),
                                verticalAlignment = Alignment.CenterVertically
                            ){
                                Label(forId = "fin-paid") {
                                    SpanText("Ödendi ", modifier = Modifier
                                        .margin(right = 6.px)
                                        .color(AppColors.TextColor)
                                    )
                                }
                                Switch(
                                    modifier = Modifier
                                        .id("fin-paid")
                                    ,
                                    checked = finPaid == 1,
                                    onCheckedChange = {
                                        finPaid = if(it) 1 else 0
                                        if (it && payDate.isAfter(today.startOfDay())){
                                            payDate = today
                                        }
                                    },
                                )
                            }


                        }
                    }

                }

                Input(
                    type = InputType.Text,
                    attrs = CowInputStyle.toModifier()
                        .id("fin-notes")
                        .attrsModifier {
                            attr("name", "notes")
                            attr("placeholder", "finadd_not".tr())
                        }
                        .margin(bottom = 10.px)
                        .toAttrs()
                        {
                            onInput {
                                finNotes = it.value
                            }
                        }
                )


            }
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }

                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            if (finItem != null){
                                editFinItem()
                            }else{
                                addNewItems()
                            }

                        }
                        .toAttrs()

                ) {
                    Text("shared_save".tr())
                }
            }
        }
    }

}




@Composable
fun FinTutar(eventDeger: MutableState<Double>) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .margin(bottom = 10.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Span(
            attrs = Modifier
                .whiteSpace(WhiteSpace.NoWrap)
                .margin(right = 10.px)
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("finadd_tutar".tr())
        }

        Input(
            type = InputType.Text,
            attrs = CowInputStyle.toModifier()
                .id("fin-tutar")
                .fontSize(16.px)
                .textAlign(TextAlign.End)
                .attrsModifier {
                    inputMode(InputMode.Decimal)
                    attr("required", "true")
                    attr("min", "1")
                    attr("placeholder", "0.00")
                }
                .toAttrs()
                {
                    onInput {
                        eventDeger.value = it.value.toDouble()
                        //console.log("deger:", eventDeger.value)
                    }
                }
        )
    }
}


