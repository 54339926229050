package com.kelimesoft.cowmaster.models

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class ManyMilkItem(
    val hid: Long,
    val milk: Double
)

@Serializable
data class ManyCowMilk(
    var mdate: String,
    var period: MilkTime,
    var milk: Double,
    var sagmal: Int,
    var list: List<ManyMilkItem>
)

@Serializable
data class SagmalInek(
    val hid: Long = 0L,
    var kupe: String = "",
    var name: String = "",
) {

    var milk by  mutableStateOf(0.0)
}


@Serializable
data class SagmalListResponse(
    val data: ArrayList<SagmalInek>?,
    val error: String? = null
)

@Serializable
data class ManyMilkRequest(
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    var milk: ManyCowMilk
)