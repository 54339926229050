package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.NavButtons
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.styles.BottomNavButtonStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text


@Composable
fun BottomNavigation(breakpoint: Breakpoint, path: String = Values.imgPath1){
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .position(Position.Fixed)
            .bottom(0.px)
            .minWidth(220.px)
            .height(Values.bottomBarHeight)
            .pointerEvents(PointerEvents.Auto)
            .backgroundImage(AppColors.GradientGray)
        , horizontalArrangement = Arrangement.SpaceAround,
        verticalAlignment = Alignment.Bottom
    ) {
        NavButtons.values().forEach { nav ->
            var thisSelected = AppData.appViewModel.activeScreens.path == nav.destination
                        || (nav.destination.startsWith("/list")
                        && AppData.appViewModel.activeScreens.path.startsWith("/list")
                        )


            Button(
                attrs = BottomNavButtonStyle.toModifier()
                    .styleModifier {
                        if (thisSelected){
                            property("background", Colors.Black.copyf(alpha = 0.1f))
                        }
                    }
                    .toAttrs()
            ) {
                ReplaceLink(
                    modifier = Modifier
                        .textAlign(textAlign = TextAlign.Center)
                        .textDecorationLine(TextDecorationLine.None)
                        .fontSize(if (breakpoint>=Breakpoint.MD) 15.px else 11.px)
                        .color(Colors.White),
                    path = nav.destination,
                    replaceLink = true

                    ) {
                    Column(horizontalAlignment = Alignment.CenterHorizontally) {
                        Image(
                            modifier = Modifier
                                .size(36.px),
                            src = path + nav.icon
                        )

                        if (thisSelected){
                            Span(attrs = Modifier.textAlign(TextAlign.Center)
                                .toAttrs()) {
                                Text(nav.title)
                            }

                        }


                    }

                }
            }
        }
    }

}