package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.Images
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Progress
import org.jetbrains.compose.web.dom.Text

@Composable
fun SplashScreen() {
   Box(modifier = Modifier.fillMaxSize(),
       contentAlignment = Alignment.Center

   ){
       Column(modifier = Modifier,
           horizontalAlignment = Alignment.CenterHorizontally,
           verticalArrangement = Arrangement.Center) {

           Image(
               src = Images.appIcon120
           )

           P(attrs = Modifier
               .textAlign(TextAlign.Center)
               .fontSize(20.px)
               .toAttrs()) {
               Text("Cow Master....")
           }
           Progress()
       }

   }


}