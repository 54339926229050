package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.models.CowUser
import com.kelimesoft.cowmaster.utils.Funcs



object AppData {
    var sessionId = ""
    val appViewModel = AppViewModel()
    var summaryViewModel = SummaryViewModel()
    var cowListVM = CowListViewModel()
    var notifListVm = NotifListVm()
    var financeVM = FinanceVM()
    var milkVM = MilkVM()
    var dismissVM = DismissVM()
    var eventListVM = EventListVM()
    var cowUser: CowUser? by mutableStateOf(null)
    var userLang: String = Funcs.getLanguage()
}