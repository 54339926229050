package com.kelimesoft.cowmaster.models

import kotlinx.serialization.Serializable

@Serializable
data class DeletedCow(
    val hid: Long,
    val kupe: String,
    val name: String,
    val date: String
)

@Serializable
data class DeletedListResponse(
    val data: List<DeletedCow>?,
    val error: String? = null
)


