package com.kelimesoft.cowmaster.pages.cowlist

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.rememberCoroutineScope
import com.kelimesoft.cowmaster.components.CircleBox
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.CowOrder
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.styles.DropDownButtonStyle
import com.kelimesoft.cowmaster.styles.DropMenuItemStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.SearchBarStyles
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.DropDownMenu
import com.kelimesoft.cowmaster.models.HerdItem
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaAngleLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretUp
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun ListBar(search: MutableState<String>, onAdd: () -> Unit) {
    //val breakpoint = rememberBreakpoint()
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .minWidth(240.px)
            .backgroundColor(AppColors.BarColor)
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically
    ) {
        CowSearch(search)
        Spacer()
            Button(
                attrs = MainButtonStyle.toModifier()
                    .margin(left = 20.px)
                    .height(30.px)
                    .minWidth(110.px)
                    .maxWidth(160.px)
                    .fontSize(14.px)
                    .onClick {
                        onAdd()
                    }
                    //.backgroundColor(AppColors.Primary)
                    .toAttrs()
            ) {

                Text("list_baradd".tr())

            }

    }
}

@Composable
fun CowSearch(text: MutableState<String>) {
    Input(
        type = InputType.Text,
        attrs = SearchBarStyles.toModifier()
            .attrsModifier {
                attr("placeholder", "list_barara".tr())
            }
            .toAttrs()
            {
                onInput {
                    text.value = it.value
                }
            }
    )
}

@Composable
fun ListInnerBar(search: MutableState<String>? = null, selectedHerd: MutableState<HerdItem?>) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(if(search!= null) 44.px else 30.px)
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .backgroundColor(AppColors.BarColor.copyf(alpha = if( search!= null) 0.6f else 0.3f))
            .height(Values.innerBarHeight),
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (search != null){
            Link(modifier = Modifier
                .margin(right = 6.px, top = 4.px)
                .textDecorationLine(TextDecorationLine.None),
                path = "/ozet"
            ) {
                FaAngleLeft(
                    modifier = Modifier.color(AppColors.DetailBlue),
                    size = IconSize.XL
                )
            }
            CowSearch(search)
        }else{
            Span(attrs = Modifier
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .toAttrs()) {
                Text("list_haylist".tr())
            }
        }

        Spacer()

        HerdSelectMenu(selectedHerd)
        ListOrderButton()
    }
}

@Composable
private fun ListOrderButton() {
    val scope = rememberCoroutineScope()
    DropDownMenu(
        butonModifier = DropDownButtonStyle.toModifier()
        .padding(4.px)
        .margin(leftRight = 5.px)
        .whiteSpace(WhiteSpace.NoWrap)
        .maxHeight(24.px)
        .onClick {
            it.stopPropagation()
            val menu = document.getElementById("drop2") as HTMLDivElement
            if (menu.style.display == "block") {
                menu.style.display = "none"
            } else {
                closeDropDowns()
                menu.style.display = "block"
            }
        },
        rightAlign = true,
        id = "drop2",
        content = {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text("list_order".tr().Sprintf(AppData.cowListVM.cowOrder.text))
                if (AppData.cowListVM.cowOrder.sql.endsWith("desc")){
                    FaCaretDown(modifier = Modifier
                        .margin(left = 4.px)
                        .color(Colors.White),
                        size = IconSize.SM)
                }else{
                    FaCaretUp(modifier = Modifier
                        .margin(left = 4.px)
                        .color(AppColors.NumBlue),
                        size = IconSize.SM)
                }
            }
        }) {

        CowOrder.values().forEach { order ->
            Button(
                attrs = DropMenuItemStyle.toModifier()
                    .padding(3.px)
                    .color(AppColors.TextColor)
                    .fontSize(14.px)
                    .margin(bottom = 2.px)
                    .onClick {
                        scope.launch {
                            AppData.cowListVM.chanceSort(order)
                        }
                    }
                    .toAttrs()) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    if (order.sql.endsWith("desc")){
                        FaCaretDown(modifier = Modifier
                            .margin(right = 6.px)
                            .color(AppColors.GrayDark),
                            size = IconSize.SM)
                    }else{
                        FaCaretUp(modifier = Modifier
                            .margin(right = 6.px)
                            .color(AppColors.NumBlue),
                            size = IconSize.SM)
                    }
                    Text(order.text)
                }
            }
        }
    }
}


@Composable
private fun HerdSelectMenu(selectedHerd: MutableState<HerdItem?>) {
    val listVM = AppData.cowListVM
    DropDownMenu(
        butonModifier = DropDownButtonStyle.toModifier()
        .padding(4.px)
        .whiteSpace(WhiteSpace.NoWrap)
        .margin(leftRight = 5.px)
        .maxHeight(24.px)
        .onClick {
            it.stopPropagation()
            val menu = document.getElementById("drop1") as HTMLDivElement
            if (menu.style.display == "block") {
                menu.style.display = "none"
            } else {
                closeDropDowns()
                menu.style.display = "block"
            }
        },
        id = "drop1",
        content = {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                CircleBox(
                    modifier = Modifier
                        .margin(right = 3.px)
                        .size(15.px)
                        .backgroundColor(if(selectedHerd.value == null) Colors.Transparent
                        else AppColors.herdColorList[selectedHerd.value!!.color -1])
                ) {}
                Text(if (selectedHerd.value == null) "list_all".tr() else selectedHerd.value?.name ?: "")
                FaCaretDown(modifier = Modifier
                    .margin(left = 4.px)
                    .color(Colors.White),
                    size = IconSize.SM)

            }
        }) {
        if(selectedHerd.value != null){
            Button(
                attrs = DropMenuItemStyle.toModifier()
                    .padding(3.px)
                    .color(AppColors.TextColor)
                    .fontSize(14.px)
                    .margin(bottom = 2.px)
                    .whiteSpace(WhiteSpace.NoWrap)
                    .onClick {
                        selectedHerd.value = null
                    }
                    .toAttrs()) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Start
                ) {
                    CircleBox(
                        modifier = Modifier
                            .margin(right = 3.px)
                            .size(16.px)
                            .backgroundColor(Colors.Transparent)
                    ) {}
                    Text("list_all".tr())
                }
            }
        }


        listVM.selectHerdSet.forEach { herd ->
            //console.log("herd-set-item:", herd.toString())
            if (herd.color > 0 ) {
                Button(
                    attrs = DropMenuItemStyle.toModifier()
                        .padding(3.px)
                        .color(AppColors.TextColor)
                        .fontSize(14.px)
                        .whiteSpace(WhiteSpace.NoWrap)
                        .margin(bottom = 2.px)
                        .onClick {
                            selectedHerd.value = herd
                        }
                        .toAttrs()) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Start
                    ) {
                        CircleBox(
                            modifier = Modifier
                                .margin(right = 3.px)
                                .size(16.px)
                                .backgroundColor(AppColors.herdColorList[herd.color - 1])
                        ) {}
                        Text(herd.name)
                    }
                }
            }
        }

    }
}