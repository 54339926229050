package com.kelimesoft.cowmaster.utils

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLFormElement
import kotlin.js.Date

object Funcs {
    fun getLanguage(): String{
        //console.log("languages: ", window.navigator.languages)
        //console.log("language: ", window.navigator.language)

        try {
            var lang = window.navigator.language
            if (lang.length > 1){
                return lang.substring(0,2)
            }
            val langs = window.navigator.languages
            return if (langs.isNotEmpty()){
                langs[0].substring(0,2)
            }else{
                return "en"
            }
        }catch (e: Exception){
            console.log(e.toString())
        }
        return "tr"
    }

    fun checkForm(id: String): Boolean {
        val form = document.getElementById(id) as HTMLFormElement
        if (!form.checkValidity()){
            form.reportValidity()
            return false
        }
        return true
    }


    fun getAnID(): Long {
        val idStr = Date.now().toLong().toString()
        return idStr.substring(idStr.length - 10).toLong()
    }
}


