package com.kelimesoft.cowmaster.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/*
{"data":{"session":"E4A3F63E-F1D7-406E-9EA3-57A813128E67",
"user":"sdrustun@gmail.com","shareid":60019,"type":7,"admin":true,
"name":"Sdr Ustun","dairy":"ksoftDemo","date":"2023-05-10",
"canpurchase":true,"yetki":[1,1,1,1,1,1,1]},"error":null}
 */



@Serializable
data class CowUser(
    val email: String = "",
    @SerialName("session")
    val sessionId: String = "",
    val id: Long= 0,
    @SerialName("sid")
    val shareId: Long= 0,
    var paket: Int = 0,
    @SerialName("start")
    var startDate: String = "",
    @SerialName("canpurchase")
    var canPurchase:Boolean = true,
    @SerialName("admin")
    val isAdmin: Boolean = true,
    val name: String = "",
    val logo: String = "",
    @SerialName("dairy")
    val dairyId: String = "",
    @SerialName("yetki")
    val yetki: UserYetki
    /*
    @SerialName("yetki")
    var userYetki: List<Int> = listOf(1, 1, 1, 1, 1, 1, 1)*/
){
    var userSid: Long = 0
        get() = if (shareId > 0) shareId else id
    var canSeeCattle: Boolean = false
        get() = yetki.hr == 1

    var canEditCattle: Boolean = false
        get() = yetki.hw == 1

    var canSeeMilk: Boolean = false
        get() = yetki.mr == 1
    var canEditMilk: Boolean = false
        get() = paket in 6..8 && yetki.mw == 1

    var canSeeFin: Boolean = false
        get() = yetki.fr == 1
    var canEditFin: Boolean = false
        get() = paket in 6..8 && yetki.fw == 1
    var rasyonYetki: Boolean = false
        get() = paket in 6..8 && yetki.ras == 1

    var hayvanLimit: Int = 0
        get() = if (paket == 6) 60 else if (paket == 7) 200 else if (paket == 8) 4000 else 0

    var userLimit: Int = 0
        get() = if (paket == 6) 1 else if (paket == 7) 2 else if (paket == 8) 4 else 0
}

@Serializable
data class UserYetki(
    val hr: Int= 1,
    var hw: Int = 1,
    val hmw: Int= 1,
    var mr: Int = 1,
    val mw: Int= 1,
    var fr: Int = 1,
    val fw: Int= 1,
    var ras: Int = 1
)

