package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.utils.AppStorage
import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.utils.tr
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import org.w3c.xhr.FormData
import kotlin.js.Date


class LoginVM {
    var userName by mutableStateOf("")
    var userPass by mutableStateOf("")
    var userLoginCaptcha by mutableStateOf("")

    var errorText by mutableStateOf("")
    var loginCaptcha by mutableStateOf("")

    var userNameForgot by mutableStateOf("")

    var userNameRegister by mutableStateOf("")
    var userPassRegister by mutableStateOf("")
    var userPassRegister1 by mutableStateOf("")

    var userForgotCaptcha by mutableStateOf("")
    var forgetCaptcha by mutableStateOf("")

    var showNewRegister by mutableStateOf(false)

    var showRenewPass by mutableStateOf(false)
    var renewPass1 by mutableStateOf("")
    var renewPass2 by mutableStateOf("")


    fun loginUser(scope: CoroutineScope) {
        if (!Funcs.checkForm("login-form")){
            return
        }

        if (userLoginCaptcha != loginCaptcha) {
            errorText = "login_capterr".tr()
            getLoginCaptcha()
            return
        }


        scope.launch {
            userLoginImpl()
        }

    }


    fun registerUser(scope: CoroutineScope) {
        scope.launch {

        }

    }

    suspend fun requestPass(): Boolean {
        if (!Funcs.checkForm("forgot-form")){
            return false
        }

        if (userForgotCaptcha != forgetCaptcha) {
            errorText = "login_capterr".tr()
            getForgotCaptcha()
            return false
        }
        console.log("forgot-vm", userNameForgot)
        RestApi.forgotPass(userNameForgot, AppData.userLang)?.let { res ->
            console.log("forgot-vm", res)
            Json.decodeFromString<BoolResponse>(res).let { resp ->
                if (resp.data == true) {
                    return true
                } else if (resp.error != null) {
                    errorText = resp.error.tr()
                }
            }
        }
        return false
    }

    fun resetUserPass(scope: CoroutineScope) {
        if (!Funcs.checkForm("renew-form")){
            return
        }

        if (renewPass1 != renewPass2) {
            errorText = "Yeni şifreniz aynı olmalı".tr()
            return
        }
        scope.launch {
            RestApi.resetUserPass(userName, userPass, renewPass1)?.let { res ->
                console.log("renew-vm", res)
                Json.decodeFromString<BoolResponse>(res).let { resp ->
                    if (resp.data == true) {
                        userPass = renewPass1
                        userLoginImpl()
                    } else if (resp.error != null) {
                        errorText = resp.error.tr()
                    }
                }
            }

        }
    }


    private suspend fun userLoginImpl() {
        val offset = Date().getTimezoneOffset()
        val form = FormData()
        form.append("username", userName)
        form.append("password", userPass)
        form.append("offset", offset.toString())

        RestApi.userLogin(form)?.let { res ->
            Json.decodeFromString<UserResponse>(res).let { userResponse ->
                if (userResponse.data != null) {
                    AppData.cowUser = userResponse.data
                    AppData.sessionId = AppData.cowUser!!.sessionId
                    AppStorage.setString(AppStorage.sessionId, AppData.sessionId)
                    AppData.appViewModel.activeScreens = Screens.Summary
                } else if (userResponse.error != null) {
                    if (userResponse.error == "temp_password"){
                        showRenewPass = true
                    }else{
                        errorText = userResponse.error.tr()
                        getLoginCaptcha()
                    }

                }
            }
        }


    }


    fun getLoginCaptcha() {
        var uniqueChar = ""
        val randomChar = "89710ABCDEFGHJKLMNOPQRSTUVWXYZ3456abcdefghijkmnopqrstuvwxyz0123456789"
        // Generate captcha for a length of 5 with random characters
        for (i in 0 until 3) {
            val randomIndex = randomChar.indices.random()
            uniqueChar += (randomChar[randomIndex])
        }
        loginCaptcha = uniqueChar
    }

    fun getForgotCaptcha() {
        var uniqueChar = ""
        val randomChar = "8910ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789"
        // Generate captcha for a length of 5 with random characters
        for (i in 0 until 4) {
            val randomIndex = randomChar.indices.random()
            uniqueChar += (randomChar[randomIndex])
        }
        forgetCaptcha = uniqueChar

    }

}