package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date


@Composable
fun LatestEvents(eventList: ArrayList<ListEvent>) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .maxHeight(300.px)
            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Unset)
            .scrollBehavior(ScrollBehavior.Smooth)
            .styleModifier {
                property("scrollbar-width", "thin")
            }
            .padding(leftRight = 4.px),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {
        eventList.forEach { event ->
            EventRow(event)
        }

    }

}
@Composable
fun EventRow(event: ListEvent) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .padding(2.px),
            contentAlignment = Alignment.TopEnd
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Image(
                    modifier = Modifier
                        .size(24.px)
                        .margin(right = 8.px)
                    ,
                    src = event.icon
                )

                Column(modifier = Modifier.fillMaxWidth()) {
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(14.px)
                            .margin(1.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        if (event.kupe.isNotEmpty()){
                            Text(event.kupe)
                        }
                        if (event.name.isNotEmpty()){
                            Text(", ")
                            Text(event.name)
                        }
                    }
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(14.px)
                            .margin(1.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        if (event.title.isNotEmpty()){
                            Span(attrs = Modifier
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()) {
                                Text(event.title)
                            }

                        }
                        if (event.not.isNotEmpty()){
                            if (event.title.isNotEmpty()){
                                Text(", ")
                            }
                            Text(event.not)
                        }
                    }
                }

            }
            Column(horizontalAlignment = Alignment.End) {
                P(
                    attrs = Modifier
                        .margin(1.px)
                        .textAlign(textAlign = TextAlign.End)
                        .color(AppColors.TextColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text(Date(event.date).toLocaleDateString())
                }
                P(
                    attrs = Modifier
                        .margin(1.px)
                        .textAlign(textAlign = TextAlign.End)
                        .fontSize(12.px)
                        .color(AppColors.TextColor)
                        .fontWeight(FontWeight.Normal)
                        .toAttrs()
                ) {
                    Text(Date(event.date).toLocaleTimeString())
                }
            }

        }
        VDivider()
    }

}
