package com.kelimesoft.cowmaster.styles

import com.kelimesoft.cowmaster.models.AppColors
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.style.CssStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px


val MainPageStyle  = CssStyle {
    base {
        Modifier
            .fillMaxSize()
            .overflow(Overflow.Hidden)
            .position(Position.Absolute)
            .backgroundColor(AppColors.Background)

    }
}


val PaneCardStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .padding(4.px)
            .backgroundColor(Colors.White)
            .borderRadius(r = 10.px)
    }
}

