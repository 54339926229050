package com.kelimesoft.cowmaster.pages.dismiss

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.models.opacity

import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.DismissVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize

import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div


@Composable
fun DismissBar(dismissVM: DismissVM) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .fontFamily(Values.FONT_FAMILY)
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .minWidth(240.px)
            .backgroundColor(AppColors.BarColor.opacity(0.7f))
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText(
            "diss_title".tr(),
            modifier = Modifier.fontSize(16.px)
                .color(AppColors.DarkRed)
        )
        SpanText(
            dismissVM.disposedList.size.toString(),
            modifier = Modifier.fontSize(16.px)
                .fontWeight(FontWeight.SemiBold)
                .color(AppColors.DarkRed)
        )
        Spacer()
        YearSelecttor(dismissVM)
    }
}


@Composable
fun YearSelecttor(dismissVM: DismissVM) {

    val scope = rememberCoroutineScope()
    Row(
        modifier = Modifier
            .backgroundColor(Colors.Gray.copyf(alpha = 0.1f))
            .borderRadius(r = 6.px)
            .boxShadow(1.px, 2.px, blurRadius = 2.px, color = Colors.Gray.copyf(alpha = 0.3f))
            .margin(4.px)
            .padding(4.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaArrowLeft(
            modifier = Modifier
                .color(AppColors.DarkRed.opacity(0.8f))
                .padding(2.px)
                .onClick {
                    scope.launch {
                        dismissVM.prevYear()
                    }
                },
            size = IconSize.LG,
        )
        Div(attrs = Modifier
            .minWidth(20.px)
            .toAttrs())
        SpanText(
            dismissVM.dismissYear.toString(),
            modifier = Modifier
                .color(AppColors.TextColor)
                .fontSize(18.px)
                .fontWeight(FontWeight.SemiBold)
        )
        Div(attrs = Modifier
            .minWidth(20.px)
            .toAttrs())

        FaArrowRight(
            modifier = Modifier
                .color(AppColors.DarkRed.opacity(0.8f))
                .padding(2.px)
                .onClick {
                    scope.launch {
                        dismissVM.nextYear()
                    }
                },
            size = IconSize.LG,
        )
    }
}