package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.utils.sameDay
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import org.jetbrains.compose.web.css.CSSColorValue
import kotlin.js.Date

@Serializable
data class SumItems(
    var num: Int = 0,
    var dur: Int = 0,
    var herd: Int = 0,
    var nlist: Int = 0,
    var elist: Int = 0,
    var milk: Int = 0,
    var fin: Int = 0,
    var dis: Int = 0,
    var del: Int = 0,
    var all: Int = 0,
    var notif: Int = 0
) {
    fun requestZero(): SumItems {
        return this.apply {
            num = 0
            dur = 0
            herd = 0
            nlist = 0
            elist = 0
            milk = 0
            fin = 0
            dis = 0
            del = 0
            all = 0
            notif = 0
        }
    }

    fun resetRequest(): SumItems {
        return this.apply {
            num = 1
            dur = 1
            herd = 1
            nlist = 1
            elist = 1
            milk = 1
            fin = 1
            dis = 1
            del = 1
            all = 1
            notif = 1
        }
    }

    fun totalSum(): Int {
        return num + dur + herd + nlist + elist + milk + fin + dis + del + all + notif
    }

}

@Serializable
data class SummaryData(
    var numbers : Numbers? = null,
    var durums: Durums? = null,
    var herd: ArrayList<HerdItem>? = null,
    var notiflist: ArrayList<ListNotif>? = null,
    var eventlist: ArrayList<ListEvent>? = null,
    var milk: AppMilk? = null,
    var milk2: MilkPeriod? = null,
    var fin: FinSum? = null,
    var disposed: Int? = null,
    var deleted: Int? = null,
    var allcows: Int? = null,
    var notif: NotifSum? = null
)

@Serializable
data class Numbers(
    val inek: Int = 0,
    val duve: Int = 0,
    val tosun: Int = 0,
    val dana: Int = 0,
    val buz: Int = 0,
    val total: Int = 0
)

@Serializable
data class Durums(
    val hasta: Int = 0,
    val gebe: Int = 0,
    val sagmal: Int = 0,
    val kuru: Int = 0,
    val tohum: Int = 0,
    val fresh: Int = 0,
    val open: Int = 0
)

@Serializable
data class DismissGroup(
    val sebep: String = "",
    val sayi: Int = 0
)




@Serializable
data class ListNotif(
    val id: Long,
    val hid: Long,
    val kupe: String,
    val name: String,
    val eid: Long,
    val ecat: String,
    val edate: String,
    val title: String,
    val text: String,
    val start: String,
    val end: String,
    val done: Int
){
    fun notifColor(): CSSColorValue {
        if (done == 1){
            return AppColors.Primary
        }
        val today = Date().startOfDay()
        val localDate = Date(Date(start).toLocaleDateString())
        if (localDate.sameDay(today)){
            return AppColors.NotifNow
        }else if (localDate.startOfDay().isBefore(today)){
            return AppColors.NotifPassed
        }
        return AppColors.NotifLater
    }

}


@Serializable
data class ListEvent(
    val id: Long,
    val hid: Long,
    val cat: String,
    val date: String,
    val deger: Int,
    val info: String,
    val not: String,
    val kupe: String,
    val name: String,
    //transient olmazsa da olabiliyor
    @Transient var icon: String = "",
    @Transient var title: String = ""
){
    init {
        setTitleIcon()
    }

    private fun setTitleIcon() {
        when (cat) {
            "TY" -> {
                icon = Icons.tohumlu
                title = "durumTY".tr()
            }

            "DY" -> {
                icon = Icons.dogumyapti
                title = "durumDY".tr()
            }

            "HG" -> {
                icon = Icons.hasta
                title = "durumHG".tr()
            }

            "AY" -> {
                icon = Icons.asi
                title = "durumAY".tr()
            }

            "AB" -> {
                icon = Icons.dusuk
                title = "durumAB".tr()
            }

            "KG" -> {
                icon = Icons.kizginlik
                title = "durumKG".tr()
            }

            "KA" -> {
                icon = Icons.kuru
                title = "durumKA".tr()
            }

            "SK" -> {
                icon = Icons.sutkes
                title = "durumSK".tr()
            }

            "GK" -> if (deger == 1) {
                title = "durumGKp".tr()
                icon = Icons.gebetrue
            } else if (deger == 0) {
                title = "durumGKn".tr()
                icon = Icons.gebefalse
            } else {
                title = "durumGK".tr()
                icon = Icons.gebekontrol
            }
            "KO" -> {
                title = "durumKOr".tr().Sprintf(deger)
                icon = Icons.tarti
            }
            else -> icon = Icons.diger
        }
    }
}

data class EventGrup(var cat: String, var sayi: Int, var color: CSSColorValue? = null){
    var icon: String =
        when(cat) {
            "TY" -> Icons.tohumlu
            "DY" -> Icons.dogumyapti
            "HG" -> Icons.hasta
            "AY" -> Icons.asi
            "AB" -> Icons.dusuk
            "KG" -> Icons.kizginlik
            "KA" -> Icons.kuru
            "SK" -> Icons.sutkes
            "GK" -> Icons.gebekontrol
            "KO" -> Icons.tarti
            else -> Icons.diger
        }
    var title: String = "durum$cat".tr()
}


@Serializable
data class AppMilk(
    var dim: Int = 0,
    var sut: Double = 0.0,
)

@Serializable
data class MilkPeriod(
    var dim: Int = 0,
    var start: String = Date().toDateString(),
    var end: String = Date().toDateString(),
    var sutmik: MilkItem = MilkItem(),
    var buzmik: MilkItem = MilkItem(),
    var kalan: MilkItem = MilkItem(),
    var sagmal: MilkItem = MilkItem(),
    var sagmalort: MilkItem = MilkItem(),
    var gelir: MilkItem = MilkItem(),
    var gunluk: MilkItem = MilkItem()

)

@Serializable
data class MilkItem(
    var mik: Double = 0.0,
    var oran: Double = 0.0,
    var yon: String = "up"

)

@Serializable
data class FinSum(
    val gelir: Double = 0.0,
    val gider: Double = 0.0,
    val alacak: Double = 0.0,
    val borc: Double = 0.0
)

@Serializable
data class NotifSum(
    @SerialName("day")
    val daily: Int,
    @SerialName("week")
    val weekly: Int
)




