package com.kelimesoft.cowmaster.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/*
type NewListCow struct {
    Id    int64        `json:"id"`
    Kupe  string       `json:"kupe"`
    Name  string       `json:"name"`
    Birth string       `json:"birth"`
    Sinif int          `json:"snf"`
    Herd  *NewHerd     `json:"herd"`
            Res1  int          `json:"res1"`
    Res2  int          `json:"res2"`
    Uyari int          `json:"uyari"`
    State CowStateText `json:"durum"`
    Image *ListImage   `json:"image"`
}
 */


@Serializable
data class ListCow(
    val id: Long = 0L,
    var kupe: String = "",
    var name: String = "",
    var birth: String = "",
    var snf: Int = 0,
    var herd: HerdItem? = HerdItem(-1, "Tümü"),
    var res1: Int = 0,
    var res2: Int = 0,
    var uyari: Int = 0,
    var durum: CowStateText = CowStateText()
){
    fun getSnfIcon(): String {
        return when (snf) {
            1 -> Icons.buz
            2 -> Icons.dana
            3 -> Icons.tosun
            4 -> Icons.duve
            else -> Icons.inek
        }
    }

    fun getIcon(res: Int): String {
        return when (res) {
            1 -> Icons.dogumyapti
            2 -> Icons.dusuk
            3 -> Icons.repeatbreeding
            4 -> Icons.hasta
            5 -> Icons.kizginlik
            6 -> Icons.tohumlu
            7 -> Icons.gebe
            8 -> Icons.gebesoru
            9 -> Icons.kuru
            10 -> Icons.asi
            11 -> Icons.sutkes
            12 -> Icons.tarti
            13 -> Icons.sagmal
            14 -> Icons.biberon
            else -> ""
        }
    }

}


@Serializable

data class CowStateText(
    var key: String = "",
    var vals: ArrayList<String>? = null
)

