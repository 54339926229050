package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.kelimesoft.cowmaster.components.PieChart
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.FinSum
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text


@Composable
fun FinanceSummary(finSum: FinSum) {
    val colors by remember { mutableStateOf(listOf(AppColors.Primary, Colors.Red)) }
    //console.log("pie:", finance.toString())
    Row(
        modifier = Modifier.fillMaxSize()
            .minHeight(160.px)
            .maxHeight(300.px)
            .padding(leftRight = 4.px),
        verticalAlignment = Alignment.CenterVertically,
    ) {
        PieChart(modifier = Modifier.fillMaxSize(),
            data = listOf(finSum.gelir, finSum.gider), colors)

        GelirInfo(finSum)

    }

}

@Composable
fun GelirInfo(finSum: FinSum) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(right = 4.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween) {
            Span(
                attrs = Modifier
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text("sumfin_gelir".tr())
            }
            Span(
                attrs = Modifier
                    .fontWeight(FontWeight.Bold)
                    .fontSize(15.px)
                    .color(AppColors.PrimaryDark)
                    .toAttrs()
            ) {
                Text(finSum.gelir.round(2))
            }

        }


        VDivider()
        Row(modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween) {
            Span(
                attrs = Modifier
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text("sumfin_gider".tr())
            }
            Span(
                attrs = Modifier
                    .fontWeight(FontWeight.Bold)
                    .fontSize(15.px)
                    .color(Colors.Red)
                    .toAttrs()
            ) {
                Text(finSum.gider.round(2))
            }

        }
        VDivider()

        Row(modifier = Modifier.fillMaxWidth()
            .margin(top = 6.px),
            horizontalArrangement = Arrangement.SpaceBetween) {
            Span(
                attrs = Modifier
                    .fontWeight(FontWeight.Medium)
                    .color(AppColors.TextColor)
                    .fontSize(15.px)
                    .toAttrs()
            ) {
                Text("sumfin_kar".tr())
            }
            Span(
                attrs = Modifier
                    .fontWeight(FontWeight.Bold)
                    .color(if ((finSum.gelir - finSum.gider) < 0.0) Colors.Red else AppColors.PrimaryDark)
                    .fontSize(15.px)
                    .toAttrs()
            ) {
                Text((finSum.gelir - finSum.gider).round(2))
            }

        }


    }


}
