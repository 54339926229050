package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.styles.RowButtonStyle
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.pointerEvents
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CircleBox(modifier: Modifier = Modifier, content: @Composable BoxScope.() -> Unit ) {
    Box(modifier = Modifier
        .borderRadius(50.percent)
        .cursor(Cursor.Pointer)
        .size(30.px).then(modifier),
        contentAlignment = Alignment.Center
    ) {
        content()
    }

}

@Composable
fun RowButton(modifier: Modifier = Modifier, content: @Composable BoxScope.() -> Unit ) {
    Box(modifier = RowButtonStyle.toModifier().then(modifier),
        contentAlignment = Alignment.Center
    ) {
        content()
    }
}