package com.kelimesoft.cowmaster.pages.cowdetails

import StaggeredVerticalGrid2
import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.cowlist.AddSingleCow
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.dateDiff
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.CowDetailViewModel
import com.kelimesoft.cowmaster.viewmodals.CowListViewModel
import com.kelimesoft.cowmaster.models.Routes
import com.kelimesoft.cowmaster.pages.summary.HerdListView
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.get
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import kotlin.js.Date

@Composable
fun CowDetayView(listVM: CowListViewModel, showClose: Boolean = false, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var showDissmissCow by remember { mutableStateOf(false) }
    var showDeleteCow by remember { mutableStateOf(false) }
    var showAddDurum by remember { mutableStateOf(false) }
    var showAddNotif by remember { mutableStateOf(false) }
    var showAddMilk by remember { mutableStateOf(false) }
    var showCowEdit by remember { mutableStateOf(false) }
    var showAddCalf by remember { mutableStateOf(false) }
    var showHerdPicker by remember { mutableStateOf(false) }
    var showCowImage: CowImage? by remember { mutableStateOf(null) }
    var selectedCow = listVM.selectedCow
    var cowDetailVM = listVM.cowDetailVM

    LaunchedEffect(selectedCow) {
        if (selectedCow != null) {
            if (showDissmissCow) showDissmissCow = false
            if (showCowEdit) showCowEdit = false
            if (showDeleteCow) showDeleteCow = false
            if (showCowImage != null ) showCowImage = null
            if (showHerdPicker) showHerdPicker = false
            cowDetailVM.setListCow(selectedCow)
            cowDetailVM.getCowDetails()
        }

    }
    DetailsBox(
        modifier = Modifier.overflow(Overflow.Hidden)
    ) {
        if (listVM.selectedCow != null) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .overflow(Overflow.Hidden)
                    .padding(leftRight = 2.px),
                verticalArrangement = Arrangement.Top
            ) {
                DetayBar(listVM.selectedCow!!, showClose, onClose = {
                    onClose()
                }, onDismiss = {
                    showDissmissCow = true
                }, onDelete = {
                    showDeleteCow = true
                })

                if (cowDetailVM.cowDetay != null) {
                    CowData(cowDetailVM, breakpoint, showClose, onEdit = {
                        showCowEdit = true
                    }, onDurum = {
                        showAddDurum = true
                    }, addNotif = {
                        showAddNotif = true
                    }, addMilk = {
                        showAddMilk = true
                    }, addCalf = {
                        showAddCalf = true
                    }, onHerd = {
                        showHerdPicker = true
                    }, showImage = {
                        showCowImage = it
                    })
                }

            }
        } else {
            P(
                attrs = Modifier
                    .margin(20.px)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Medium)
                    .toAttrs()
            ) {
                Text("detay_sec".tr())

            }
        }


        if (showCowEdit) {
            DialogView(type = DialogType.CenterDialog) {
                AddSingleCow(cowDetay = cowDetailVM.cowDetay) {
                    showCowEdit = false
                }
            }
        } else if (showDissmissCow) {
            DialogView {
                DismissCow(listVM) {
                    showDissmissCow = false
                }
            }
        } else if (showDeleteCow) {
            DialogView {
                DeleteAlertCow(listVM) {
                    showDeleteCow = false
                }
            }
        } else if (showAddDurum) {
            DialogView {
                AddDurum(scope, cowDetailVM) {
                    showAddDurum = false
                }
            }
        } else if (showAddNotif) {
            DialogView {
                AddCowNotif(scope, cowDetailVM) {
                    showAddNotif = false
                }
            }
        } else if (showAddMilk) {
            DialogView {
                AddCowMilk(scope, cowDetailVM) {
                    showAddMilk = false
                }
            }
        } else if (showAddCalf) {
            DialogView(type = DialogType.CenterDialog) {
                AddSingleCow(dam = cowDetailVM.cowDetay!!.kupe) {
                    showAddCalf = false
                }
            }
        } else if (showCowImage != null) {
            DialogView(type = DialogType.CenterDialog) {
                CowImageViever(showCowImage!!, onFav = {
                    scope.launch {
                        cowDetailVM.setCowFavImage(showCowImage!!)
                        showCowImage = null
                    }
                }, onDelete = {
                    scope.launch {
                        cowDetailVM.deleteCowImage(showCowImage!!)
                        showCowImage = null
                    }
                }) {
                    showCowImage = null
                }
            }
        }else if (showHerdPicker){
            DialogView {
                HerdListView(true, onSelect = {
                    scope.launch {
                        if (cowDetailVM.updateCowHerd(it)){
                            listVM.updateSingleCowHerd(cowDetailVM.cowDetay!!.hid, it)
                        }
                        showHerdPicker = false
                    }

                }, onClose = {
                    showHerdPicker = false
                })
            }
        }



        if (cowDetailVM.loadingDetails) {
            DialogView {
                Progress()
            }
        }

    }
}


@Composable
fun CowData(
    detailVM: CowDetailViewModel, breakpoint: Breakpoint,
    asDialog: Boolean, onEdit: () -> Unit, onDurum: () -> Unit,
    addNotif: () -> Unit, addMilk: () -> Unit, addCalf: () -> Unit,
    onHerd: () -> Unit,
    showImage: (CowImage) -> Unit
) {
    val scope = rememberCoroutineScope()
    var kiloEvents by remember { mutableStateOf(listOf<CowEvent>()) }
    var showKilo by remember { mutableStateOf(false) }
    var kiloData: CowKiloData? by remember { mutableStateOf(null) }
    LaunchedEffect(detailVM.cowEvents) {
        kiloEvents = detailVM.cowEvents?.filter { evt -> evt.cat == "KO" }?.sortedBy { it.date } ?: listOf()
        //console.log("kiloevents: ", kiloEvents)
        if (kiloEvents.isNotEmpty()) {
            if (dateDiff(Date(), Date(kiloEvents.first().date)) < 120) showKilo = true
            kiloData = CowKiloData(kiloEvents)
        } else {
            kiloData = null
        }

    }

    fun getCols(): Int {
        return if (asDialog) {
            if (breakpoint < Breakpoint.SM) 1 else 2
        } else {
            if (breakpoint < Breakpoint.LG) 1 else 2
        }
    }

    Column(
        modifier = Modifier.fillMaxWidth()
            .overflow { y(Overflow.Auto) }
            .overflow { x(Overflow.Hidden) },
        //.backgroundColor(Colors.White),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
            CowImages(detailVM.cowImages, showImage = { img ->
                showImage(img)
            }, onAdd = { base64 ->
                scope.launch {
                    detailVM.addCowImage(base64)
                }

            })
            detailVM.cowIcons?.let { icons ->
                CowWarningIcons(icons)
            }

        StaggeredVerticalGrid2(col = getCols(), gap = 6) {
            CowGeneralInfo(detailVM, onEdit = {
                onEdit()
            }, onHerd = {
                onHerd()
            })
            CowEventList(detailVM.cowEvents, onDurum)

            CowNotifList(detailVM.cowNotifs, addNotif)

            if (detailVM.showDisiExtra) {
                CowMilkList(detailVM.cowMilk, addMilk)
                CowCalvesList(detailVM.cowCalves, addCalf)
            }


            if (detailVM.cowDetay?.gender == 1 ||
                dateDiff(Date(), Date(detailVM.cowDetay!!.birth)) < 16 || showKilo
            ) {
                CowKiloView(kiloData)
            }

        }

    }
}

@Composable
fun CowImages(images: List<CowImage>?, showImage: (CowImage) -> Unit, onAdd: (String) -> Unit) {
    val shareId = AppData.cowUser?.shareId
    val favImage = images?.first { it.fav == 1}
    val otherImages = images?.filter{ it.exist }?.filter { it.fav != 1 }
    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {

        favImage?.let { fav ->
            if (fav.exist) {
                Box(
                    contentAlignment = Alignment.TopEnd,
                    modifier = Modifier
                        .width(180.px)
                        .height(140.px)
                        .margin(6.px)
                        .cursor(Cursor.Pointer)
                        .onClick {
                            showImage(fav)
                        }
                        .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Hidden)
                ) {
                    Image(
                        modifier = Modifier
                            .borderRadius(r = 8.px)
                            .attrsModifier {
                                //on error özelliği ile attr ekledim, resim var ama alınamadı/ başka folder da
                                attr("onerror", "this.onerror=null;this.src=\"${Routes.WebRoot}${Images.noImage}\";")
                            }
                            .objectFit(ObjectFit.Cover)
                            .fillMaxSize()
                        ,
                        src = if (fav.img.isNotEmpty()) {
                            "${Routes.ImgRoot}$shareId/${fav.img}"
                        } else Values.imgPath2 + Images.noImage)

                    Box(contentAlignment = Alignment.Center){
                        FaIcon("star", modifier = Modifier
                            .padding(top = 12.px, right = 4.px)
                            //.backgroundColor(Colors.Gray.copyf(alpha = 0.6f))
                            .color(Color.yellow)
                            , IconCategory.SOLID,
                            size = IconSize.LG
                        )
                    }
                }
            }
        }

        otherImages?.forEach { image ->
            Box(
                modifier = Modifier
                    .width(170.px)
                    .height(130.px)
                    .margin(6.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        showImage(image)
                    }

            ) {
                Image(
                    modifier = Modifier
                        .borderRadius(r = 8.px)
                        .fillMaxSize()
                        .attrsModifier {
                            //on error özelliği ile attr ekledim, resim var ama alınamadı/ başka folder da
                            attr("onerror", "this.onerror=null;this.src=\"${Routes.WebRoot}${Images.noImage}\";")
                        }
                        .objectFit(ObjectFit.Cover),
                    src = if (image.img.isNotEmpty()) {
                        "${Routes.ImgRoot}$shareId/${image.img}"
                    } else Values.imgPath2 + Images.noImage,

                    )

            }
        }

        if ((images?.filter { it.exist }?.size ?: 0) < 3) {
            CowImagePicker {base64 ->
                onAdd(base64)
            }
        }

    }

}

@Composable
fun CowWarningIcons(icons: List<CowStateInfo>) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(6.px)

    ) {
        icons.forEach { icon ->
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(topBottom = 4.px)
                    .padding(4.px)
                    .borderRadius(r = 8.px)
                    .backgroundColor(Colors.White.copyf(alpha = 0.9f)),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    modifier = Modifier.size(48.px)
                        .margin(right = 6.px),
                    src = Values.imgPath2 + icon.icon
                )
                Column {
                    Span(
                        attrs = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(15.px)
                            .margin(bottom = 4.px)
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) {
                        Text(icon.title)
                    }
                    Span(
                        attrs = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        val splitted = icon.text?.split("\n")
                        splitted?.forEachIndexed { index, txt ->
                            Div {
                                Span(attrs = Modifier
                                    .styleModifier {
                                        if ((icon.res == 6 ||
                                                    icon.res == 7 || icon.res == 9) &&
                                            index == splitted.size - 1
                                        ) {
                                            property("color", "${AppColors.Red}")
                                        }
                                    }
                                    .toAttrs()
                                ) {
                                    Text(txt)
                                }
                            }

                        }
                        //Text(icon.text.replace("<br>", "\n"))
                    }
                }
            }
        }

    }

}


@Composable
fun DetayBar(
    listCow: ListCow, showClose: Boolean,
    onClose: () -> Unit, onDismiss: () -> Unit,
    onDelete: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .backgroundColor(AppColors.BarColor)
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically,
    ) {
        P(
            attrs = Modifier
                .margin(2.px)
                .fontSize(16.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Medium)
                .toAttrs()
        ) {
            Text(listCow.kupe)
            if (listCow.name.isNotEmpty()) {
                Text(", ")
                Text(listCow.name)
            }

        }

        Spacer()
        Button(
            attrs = DetailButtonStyle.toModifier()
                .margin(leftRight = 6.px)
                .height(28.px)
                .minWidth(110.px)
                .maxWidth(160.px)
                .onClick {
                    onDismiss()
                }
                .backgroundColor(AppColors.Red.copyf(alpha = 0.8f))
                .color(Colors.White)
                .toAttrs()

        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                FaRightFromBracket(
                    modifier = Modifier
                        .margin(right = 4.px)
                        .color(Colors.White),
                    size = IconSize.SM
                )
                Text("detay_dismiss".tr())
            }
        }

        Button(
            attrs = DetailButtonStyle.toModifier()
                .margin(leftRight = 4.px)
                .height(28.px)
                .maxWidth(160.px)
                .onClick {
                    onDelete()
                }
                .backgroundColor(AppColors.DarkRed)
                .fontWeight(FontWeight.Medium)
                .color(Colors.White)
                .toAttrs()

        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                FaTrashCan(
                    modifier = Modifier
                        .margin(right = 4.px)
                        .color(Colors.White),
                    size = IconSize.SM
                )
                Text("detay_sil".tr())
            }
        }

        if (showClose) {
            Box(modifier = CloseButtonStyle.toModifier()
                .margin(left = 20.px)
                .onClick {
                    onClose()
                }
            ) {
                FaIcon(
                    "close", modifier = Modifier, IconCategory.SOLID,
                    size = IconSize.XL
                )
            }
        }


    }
}



@Composable

fun CowImagePicker(onAdd: (String) -> Unit) {
    val scope = rememberCoroutineScope()
    //var imageBase64: String by remember { mutableStateOf("") }

    fun resizeImage(file: File) {
        val maxSize = 560
        val reader = FileReader()
        reader.onload = { readerEvent ->
            val image = org.w3c.dom.Image()
            image.onload = {
                // Resize the image
                val canvas = document.createElement("canvas") as HTMLCanvasElement
                val context = canvas.getContext("2d") as? CanvasRenderingContext2D
                var width = image.width.toDouble()
                var height = image.height.toDouble()

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width
                        width = maxSize.toDouble()
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height
                        height = maxSize.toDouble()
                    }
                }
                canvas.width = width.toInt()
                canvas.height = height.toInt()
                context?.drawImage(image, 0.0, 0.0, width, height)
                val dataUrl = canvas.toDataURL("image/jpeg")
                val clearBase64 = dataUrl.replace(Regex("^data:.+;base64,"), "")
                //imageBase64 = dataUrl
                //newCow.image = clearBase64
                // Now you can use the 'base64String' for further processing or display
                onAdd(clearBase64)
            }
            if (readerEvent.target != null) {
                image.src = readerEvent.target.asDynamic().result.toString()
            }
            scope.launch {

            }
            //image.src = readerEvent.target.asDynamic().result as String
        }
        reader.readAsDataURL(file)

    }

    fun acceptFileDrop(dropEvt: SyntheticDragEvent) {
        dropEvt.dataTransfer?.items?.let { items ->
            for (i in 0 until items.length) {
                if (items[i]?.kind == "file") {
                    val file = items[i]?.getAsFile()
                    if (file?.type?.matches(Regex("^image/.*")) == true) {
                        scope.launch {
                            resizeImage(file)
                        }
                    }
                }
            }
        }
    }

    fun selectImage() {
        (document.getElementById("file-select") as HTMLInputElement).click()
    }

    Box(
        contentAlignment = Alignment.Center
    )
    {
        Box(modifier = Modifier
            .width(180.px)
            .height(140.px)
            .margin(6.px)
            .backgroundColor(Colors.Gray.copyf(alpha = 0.4f))

            .cursor(Cursor.Pointer)
            .overflow(Overflow.Hidden)
            .border((0.5).px, LineStyle.Solid, color = Colors.Gray.copyf(alpha = 0.6f))
            .borderRadius(r = 8.px)

            .onDrop { dropevt ->
                dropevt.preventDefault()

                acceptFileDrop(dropevt)

            }
            .onDragOver {
                it.preventDefault()
                //fileExist = true
            }
            .onClick {

                selectImage()

            }
            , contentAlignment = Alignment.Center) {
            Input(
                type = InputType.File,
                attrs = CowInputStyle.toModifier()
                    .id("file-select")
                    .display(DisplayStyle.None)
                    .attrsModifier {
                        attr("name", "file")
                        attr("placeholder", "Select Image")
                        attr("accept", "image/*")
                    }
                    .margin(bottom = 10.px)
                    .toAttrs()
                    {
                        onInput { evt ->
                            val file = evt.target.files?.get(0)
                            if (file?.type?.matches(Regex("^image/.*")) == true) {
                                scope.launch {
                                    resizeImage(file)
                                }
                            }
                        }
                    }
            )
            Span(
                attrs = Modifier
                    .fontSize(20.px)
                    .color(AppColors.TextColor)
                    .padding(6.px)
                    .backgroundColor(Colors.White.copyf(alpha = 0.8f))
                    .borderRadius(r = 10.px)
                    .toAttrs()
            ) {
                Text("single_addimg".tr())
            }

        }
    }
}





