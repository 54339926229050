package com.kelimesoft.cowmaster.pages.milk

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.components.DialogView
import com.kelimesoft.cowmaster.components.DropDownMenu
import com.kelimesoft.cowmaster.components.ErrorText
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.kelimesoft.cowmaster.pages.cowdetails.AddCowMilk
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.DropMenuItemStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.CowDetailViewModel
import com.kelimesoft.cowmaster.viewmodals.MilkVM
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import kotlin.js.Date

@Composable
fun DairyMilkView(milkVM: MilkVM) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var showAdd by remember { mutableStateOf(false) }
    Box(modifier = Modifier.fillMaxSize()) {
        Column(modifier = Modifier.fillMaxSize()
            .padding(2.px)
            .overflow { y(Overflow.Hidden) }
            .overflow { x(Overflow.Hidden) }
        ) {
            DairyPeriodMilk(milkVM){
                showAdd = true
            }
            DairyMilkList(milkVM)
        }

        if (showAdd){
            DialogView {
                AddDairyMilk(onAdd = { tmilk ->
                    scope.launch {
                        milkVM.addTotalMilk(tmilk)
                    }
                    showAdd = false

                }) {
                    showAdd = false
                }
            }
        }
    }

    if (breakpoint > Breakpoint.SM) {
        Box(modifier = Modifier.width(2.px)) { }

    } else {
        Box(modifier = Modifier.width(4.px)) { }
    }
}



@Composable
fun AddDairyMilk(onAdd:(TotalMilk)-> Unit, onClose: () -> Unit) {
    var errorText by remember { mutableStateOf("") }
    var milkPeriod: MilkTime by remember { mutableStateOf(MilkTime.Morning) }
    var milkDate by remember { mutableStateOf(Date()) }
    var milkQuantity by remember { mutableStateOf(0.0) }
    var buzMiktar by remember { mutableStateOf(0.0) }
    var sagmalSay by remember { mutableStateOf(AppData.summaryViewModel.cattleStates.sagmal) }
    var milkPrice by remember { mutableStateOf(AppStorage.getDouble(AppStorage.milkPrice))}

    LaunchedEffect(true){
        val dayhour = milkDate.getHours()
        if (dayhour > 15){
            milkPeriod = MilkTime.Evening
        }else if (dayhour > 10){
            milkPeriod = MilkTime.Noon
        }
    }

    fun saveMilk() {
        if (!Funcs.checkForm("form-addmilk")) {
            return
        }
        if (milkQuantity <= 0){
            errorText = "milkview_miktarerr".tr()
            return
        }else if(sagmalSay <= 0){
            errorText = "milkview_sagmalerr".tr()
            return
        }

        val tMilk = TotalMilk(
            milk = milkQuantity.rountTo(2),
            buz = buzMiktar.rountTo(2),
            cows = sagmalSay,
            mdate = milkDate.dateToDbStr(),
            period = milkPeriod,
            price = milkPrice.rountTo(2)
        )
        AppStorage.setDouble(AppStorage.milkPrice, milkPrice)
        onAdd(tMilk)

    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("milkview_adddairy".tr(), onClose = onClose)

        Column(
            Modifier
                .fillMaxWidth()
                .padding(topBottom = 6.px, leftRight = 8.px)
        ) {

            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.Start
            ) {
                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .margin(top = 4.px)
                        .padding(1.px)
                        .color(AppColors.TextColor.copyf(alpha = 0.6f))
                        .toAttrs()
                ) {
                    Text("addmilk_time".tr())
                }


                Row(modifier = Modifier.fillMaxWidth()
                    .margin(bottom = 6.px)
                    , verticalAlignment = Alignment.CenterVertically
                ) {
                    Box(
                        Modifier
                            .fillMaxWidth(percent = 35.percent)
                            .margin(2.px)
                        ,
                        contentAlignment = Alignment.Center
                    ) {
                        DropDownMenu(
                            menuModifier = Modifier.fillMaxWidth(),
                            butonModifier = CowInputStyle.toModifier()
                                .fillMaxWidth()
                                .padding(6.px)
                                .margin(topBottom = 8.px)
                                .whiteSpace(WhiteSpace.NoWrap)
                                .onClick {
                                    it.stopPropagation()
                                    val menu = document.getElementById("add-drop") as HTMLDivElement
                                    if (menu.style.display == "block") {
                                        menu.style.display = "none"
                                    } else {
                                        closeDropDowns()
                                        menu.style.display = "block"
                                    }
                                },
                            id = "add-drop",
                            content = {
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Text(milkPeriod.text)
                                    Spacer()
                                    FaCaretDown(
                                        modifier = Modifier
                                            .color(AppColors.GrayDark),
                                        size = IconSize.LG
                                    )

                                }
                            }) {
                            MilkTime.entries.forEach { evt ->
                                Box(
                                    modifier = DropMenuItemStyle.toModifier()
                                        .padding(3.px)
                                        .color(AppColors.TextColor)
                                        .fontSize(14.px)
                                        .margin(bottom = 2.px)
                                        .onClick {
                                            if (milkPeriod != evt) {
                                                milkPeriod = evt
                                            }
                                        }
                                ) {

                                    Text(evt.text)

                                }
                            }
                        }

                    }

                    Input(
                        type = InputType.Date,
                        attrs = CowInputStyle.toModifier()
                            .fillMaxWidth()
                            .id("milk-date")
                            .padding(7.px)
                            .attrsModifier {
                                attr("required", "true")
                                attr("value", milkDate.dateToDbStr())
                                attr("max", Date().dateToDbStr())
                                attr("min", "2019-01-01")
                            }
                            .margin(2.px)
                            .toAttrs()
                            {
                                onInput {
                                    var ndate = milkDate
                                    try {
                                        ndate = Date(it.value)
                                        errorText = ""
                                    } catch (_: Exception) {
                                    }
                                    milkDate = ndate
                                }
                            }
                    )
                }

            }

            Form(
                attrs = Modifier
                    .id("form-addmilk")
                    .fillMaxWidth()
                    .toAttrs()
            ) {

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(bottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Span(
                        attrs = Modifier
                            .whiteSpace(WhiteSpace.NoWrap)
                            .margin(right = 10.px)
                            .fontSize(14.px)
                            .color(AppColors.TextColor)
                            .toAttrs()
                    ) {
                        Text("addmilk_miktar".tr())
                    }

                    Input(
                        type = InputType.Text,
                        attrs = CowInputStyle.toModifier()
                            .textAlign(TextAlign.End)
                            .attrsModifier {
                                inputMode(InputMode.Decimal)
                                attr("value", milkQuantity.toString())
                                attr("required", "true")
                                attr("min", "1")
                                attr("max", "1000")
                            }
                            .toAttrs()
                            {
                                onInput {
                                    milkQuantity = it.value.toDouble()
                                    errorText = ""
                                }
                            }
                    )
                }

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(bottom = 4.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Column(
                        modifier = Modifier.fillMaxWidth()
                            .margin(right = 2.px),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Span(
                            attrs = Modifier
                                .whiteSpace(WhiteSpace.NoWrap)
                                .fontSize(12.px)
                                .color(AppColors.TextColor)
                                .toAttrs()
                        ) {
                            Text("summilk_buzmik".tr())
                        }

                        Input(
                            type = InputType.Text,
                            attrs = CowInputStyle.toModifier()
                                .textAlign(TextAlign.End)
                                .attrsModifier {
                                    inputMode(InputMode.Numeric)
                                    attr("value", buzMiktar.toString())
                                    attr("required", "true")
                                    attr("min", "0")
                                    attr("max", milkQuantity.toString())
                                }
                                .toAttrs()
                                {
                                    onInput {
                                        buzMiktar = it.value.toDouble()
                                    }
                                }
                        )
                    }

                    Column(
                        modifier = Modifier.fillMaxWidth()
                            .margin(left = 2.px),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Span(
                            attrs = Modifier
                                .whiteSpace(WhiteSpace.NoWrap)
                                .fontSize(12.px)
                                .color(AppColors.TextColor)
                                .toAttrs()
                        ) {
                            Text("milkview_sagmalsay".tr())
                        }

                        Input(
                            type = InputType.Text,
                            attrs = CowInputStyle.toModifier()
                                .textAlign(TextAlign.End)
                                .attrsModifier {
                                    inputMode(InputMode.Decimal)
                                    attr("value", sagmalSay.toString())
                                    attr("required", "true")
                                    attr("min", "1")
                                    attr("max", "1000")
                                }
                                .toAttrs()
                                {
                                    onInput {
                                        sagmalSay = it.value.toInt()
                                        errorText = ""
                                    }
                                }
                        )
                    }

                }


                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(bottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Span(
                        attrs = Modifier
                            .whiteSpace(WhiteSpace.NoWrap)
                            .margin(right = 10.px)
                            .fontSize(14.px)
                            .color(AppColors.TextColor)
                            .toAttrs()
                    ) {
                        Text("milkview_price".tr())
                    }

                    Input(
                        type = InputType.Text,
                        attrs = CowInputStyle.toModifier()
                            .textAlign(TextAlign.End)
                            .fontSize(16.px)
                            .attrsModifier {
                                inputMode(InputMode.Decimal)
                                attr("value", milkPrice.toString())
                                attr("required", "true")
                                attr("min", "0.01")
                                attr("max", "99999999.99")
                            }
                            .toAttrs()
                            {
                                onInput {
                                    val sanitizedValue = it.value.replace(",", ".")
                                        .replace("[^0-9.]".toRegex(), "")
                                    milkPrice = sanitizedValue.toDoubleOrNull() ?: 0.0

                                }
                            }
                    )
                }

                if (errorText.isNotEmpty()) {
                    ErrorText(errorText)
                }

            }
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }


                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            saveMilk()
                        }
                        .toAttrs()

                ) {
                    Text("addmilk_add".tr())
                }
            }

        }


    }

}