package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.pages.cowlist.CowList
import com.kelimesoft.cowmaster.styles.addPrintStyles


import com.kelimesoft.cowmaster.viewmodals.AppData

@Composable
fun Router() {
    LaunchedEffect(true){
        AppData.appViewModel.getUser()
        addPrintStyles()
    }
    //console.log("route: ", route, "screen: ", AppData.appViewModel.activeScreens.name)
    when(AppData.appViewModel.activeScreens) {
        Screens.Splash -> SplashScreen()
        Screens.Login -> LoginPage()
        //Screens.NoPremium -> NoPremium()
        Screens.Yukselt -> NoPremium()
        //App Pages
        Screens.Home -> DairySummary()
        Screens.CattleList -> CowList()
        Screens.Summary -> DairySummary()
        Screens.Profile -> MyProfile()
        Screens.Milk -> MilkPageData()
        Screens.Finance -> GelirGider()
        Screens.Notifs -> CattleNotifs()
        Screens.Events -> EventsList()
        Screens.Dismiss -> DismissList()
        //no page
        Screens.Page404 -> Page404()
        else -> Page404()

    }
}