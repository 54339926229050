package com.kelimesoft.cowmaster.styles

import com.kelimesoft.cowmaster.models.AppColors

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px


val CowRowStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxWidth()
            .margin(topBottom = 1.px, leftRight = 2.px)
            .borderRadius(r = 6.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms)
            )

    }
    hover {
        Modifier
            .cursor(Cursor.Pointer)
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.2f))
    }


}

