package com.kelimesoft.cowmaster.pages.dismiss

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.DisposedCow
import com.kelimesoft.cowmaster.models.Icons.dismissCow
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.Cursor

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun DismissCowRow(dissCow: DisposedCow, onSelect: () -> Unit){
    Column(modifier = Modifier.fillMaxWidth()
        .onClick {
            onSelect()
        }
        .cursor(Cursor.Pointer)
        , horizontalAlignment = Alignment.Start
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                modifier = Modifier.size(24.px)
                    .margin(right = 4.px),
                src = Values.imgPath2 + dissCow.getSnfIcon()
            )
            Column(horizontalAlignment = Alignment.Start) {
            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically) {
                    SpanText(dissCow.kupe, modifier = Modifier
                        .fontSize(14.px)
                        .padding(2.px)
                        .fontWeight(FontWeight.Medium)
                        .color(AppColors.TextColor)
                    )
                    if (dissCow.name.isNotEmpty()) {
                        Span(
                            attrs = Modifier
                                .color(AppColors.TextColor)
                                .fontSize(13.px)
                                .fontWeight(FontWeight.Normal)
                                .opacity(80.percent)
                                .toAttrs()
                        ) {
                            Text(", ")
                            Text(dissCow.name)
                        }
                    }


            }


                Row(verticalAlignment = Alignment.CenterVertically){
                    Image(
                        modifier = Modifier.size(16.px)
                            .margin(right = 4.px),
                        src = Values.imgPath2 + dismissCow
                    )
                    SpanText(dissCow.sebep.tr(), modifier = Modifier.color(AppColors.DarkRed)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Normal))

                    SpanText("(${Date(dissCow.ddate).toLocaleDateString()})", modifier = Modifier.color(AppColors.DarkRed)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .margin(left = 10.px)
                    )
                }
            }


        }
        VDivider()
    }
}
