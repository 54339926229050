package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.pages.Router
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

@Page("/page404")
@Composable
fun PageNotFound() {
    AppData.appViewModel.activeScreens = Screens.Page404
    Router()
}

@Composable
fun Page404() {
    Box(contentAlignment = Alignment.Center) {
        H2(attrs = Modifier.color(AppColors.TextColor).toAttrs()) {
            Text("404: Aradığınız sayfa ulaşilamıyor")
        }
    }
}