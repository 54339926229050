package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px

@Composable
fun PageBox(modifier: Modifier = Modifier,
            content: @Composable BoxScope.() -> Unit = {}
            ) {


    Box(modifier = Modifier
        .id("page-box")
        .position(Position.Fixed)
        .top(0.px)
        .left(0.px)
        .fillMaxSize()
        .fontFamily(Values.FONT_FAMILY)
        .then(modifier),
        contentAlignment = Alignment.Center
        ) {
        content()
        if (AppData.appViewModel.showProgress){
            ProgressView()
        }
    }
}

@Composable
fun DetailsBox(modifier: Modifier = Modifier,
            content: @Composable BoxScope.() -> Unit = {}

) {
    Box(modifier = Modifier
        .id("details-box")
        .fillMaxWidth()
        .fillMaxHeight()
        .then(modifier),
        contentAlignment = Alignment.Center
    ) {
        content()
    }
}