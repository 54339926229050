package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.CowEvent
import com.kelimesoft.cowmaster.models.CowKiloData
import com.kelimesoft.cowmaster.models.CowMilk
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun CowKiloView(kiloData: CowKiloData?) {

    CowDetayCard(
        title = "detaykilo_title".tr(), button = null
        ){
        SimpleGrid(numColumns= numColumns(base = 2, sm = 3, lg = 4)){
            kiloData?.kiloList?.forEach { kilo ->
                Span(attrs = Modifier
                    .padding(topBottom = 3.px, leftRight = 6.px)
                    .whiteSpace(WhiteSpace.NoWrap)
                    .margin(right = 6.px, bottom = 4.px)
                    .fontSize(13.px)
                    .borderRadius(r = 10.px)
                    .boxShadow(0.px, 1.px, 1.px, 1.px, color = Colors.Black.copyf(alpha = 0.1f))
                    .backgroundColor(Colors.Gray.copyf(alpha = 0.1f))
                    .toAttrs()
                ) {
                    Text(Date(kilo.date).toLocaleDateString())
                    Text(" ")
                    Span(attrs = Modifier
                        .fontSize(14.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                    ){

                        Text(kilo.deger.toDouble().toString())

                    }
                    Text("detaykilo_birim".tr())

                }
            }
        }
        VDivider()
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .maxHeight(300.px)
                .overflow { y(Overflow.Auto) }
                .overflow { x(Overflow.Hidden) }
        ) {
            if (kiloData != null && kiloData.kiloList.size > 1){
                Row(modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ){
                    Span(attrs = Modifier
                        .fillMaxWidth()
                        .color(AppColors.TextColor.copyf(alpha = 0.9f))
                        .margin(2.px)
                        .fontSize(14.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()) {
                        Text("detaykilo_son2".tr())
                    }

                    Span(attrs = Modifier
                        .color(AppColors.TextColor.copyf(alpha = 0.9f))
                        .margin(2.px)
                        .fontSize(15.px)
                        .whiteSpace(WhiteSpace.NoWrap)
                        .fontWeight(FontWeight.SemiBold)
                        .toAttrs()) {
                        Text(kiloData.latestGain.round(2).toString())
                        Text("detaykilo_birim".tr())
                    }
                }


                Row(modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ){
                    Span(attrs = Modifier
                        .fillMaxWidth()
                        .color(AppColors.TextColor.copyf(alpha = 0.9f))
                        .margin(2.px)
                        .fontSize(14.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()) {
                        Text("detaykilo_genel".tr())
                    }

                    Span(attrs = Modifier
                        .color(AppColors.TextColor.copyf(alpha = 0.9f))
                        .margin(2.px)
                        .fontSize(15.px)
                        .whiteSpace(WhiteSpace.NoWrap)
                        .fontWeight(FontWeight.SemiBold)
                        .toAttrs()) {
                        Text(kiloData.totalGain.round(2).toString())
                        Text("detaykilo_birim".tr())
                    }
                }

            }else{
                Span(attrs = Modifier
                    .fillMaxWidth()
                    .color(AppColors.TextColor.copyf(alpha = 0.8f))
                    .margin(2.px)
                    .fontSize(13.px)
                    .fontWeight(FontWeight.Normal)
                    .toAttrs()) {
                    Text("detaykilo_enaz".tr())
                }
            }
        }
    }
}
