package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.models.CowCalf
import com.kelimesoft.cowmaster.models.CowDetay
import com.kelimesoft.cowmaster.models.CowNotif
import com.kelimesoft.cowmaster.utils.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlin.js.Date

class CowDetailViewModel {
    val today = Date()
    var loadingDetails by mutableStateOf(false)

    var cowDetay: CowDetay? by mutableStateOf(null)
    var cowEvents: List<CowEvent>? by mutableStateOf(null)
    var cowImages: List<CowImage>? by mutableStateOf(null)
    var cowIcons: List<CowStateInfo>? by mutableStateOf(null)
    var cowNotifs: List<CowNotif>? by mutableStateOf(null)
    var cowCalves: List<CowCalf>? by mutableStateOf(null)
    var cowMilk: List<CowMilk>? by mutableStateOf(null)

    var showDisiExtra by mutableStateOf(false)

    var listCow: ListCow? = null

    fun setListCow(cow: ListCow){
        listCow = cow
    }


    suspend fun getCowDetails() {
        loadingDetails = true
        try {
            RestApi.getCowDetails(listCow!!.id)?.let { res ->
                Json.decodeFromString<CowDetailsResponse>(res).let { details ->
                    if (details.data != null){
                        cowDetay = details.data.detay
                        cowEvents = details.data.events
                        cowImages = details.data.images
                        //cowIcons = cowdetails.icons
                        cowNotifs = details.data.notifs
                        //cowCalves = cowdetails.calves
                        //cowMilk = cowdetails.milk
                            mainScope.launch{
                                getExtraData()
                                setCowStateInfo()
                            }

                    }else{
                        if (!details.error.isNullOrEmpty()) {
                            AppData.appViewModel.setPage(details.error)
                        }else{}
                    }
                }
            }
        } finally {
            loadingDetails = false
        }
    }


    suspend fun deleteCowImage(image: CowImage) {
        loadingDetails = true
        try {
            RestApi.deleteCowImage(hid = image.hid, image = image.img, fav = image.fav)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { delRes ->
                    if (!delRes.data.isNullOrEmpty()){
                        cowImages?.filter { it.img != image.img }?.let { nlist ->
                            val index = nlist.indexOfFirst { it.img == delRes.data }
                            if (index >= 0) {
                                nlist[index].fav = 1
                            }
                            cowImages = nlist.toList()
                        }
                    }else {
                        cowImages = null
                        if (!delRes.error.isNullOrEmpty()) {
                            AppData.appViewModel.setPage(delRes.error)
                        } else {}
                    }
                }
            }
        } finally {
            loadingDetails = false
        }

    }

    suspend fun addCowImage(base64: String) {
        loadingDetails = true
        try {
            RestApi.addCowImage(hid = cowDetay!!.hid, image = base64)?.let { res ->
                Json.decodeFromString<AddCowImgResponse>(res).let { imRes ->
                    imRes.data?.let { newImg ->
                        val nList = cowImages?.toMutableList() ?: mutableListOf()
                        nList.add(newImg)
                        cowImages = nList.toList()
                    }?.run {
                        if (!imRes.error.isNullOrEmpty()) {
                            AppData.appViewModel.setPage(imRes.error)
                        }
                    }
                }
            }
        } finally {
            loadingDetails = false
        }
    }

    suspend fun setCowFavImage(image: CowImage) {
        loadingDetails = true
        try {
            RestApi.setCowFavImage(hid = image.hid, image = image.img)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { imRes ->
                    imRes.data?.let {
                        cowImages?.let { cl ->
                            val nlist = cl.toMutableList()
                            cowImages = listOf()
                            val fidx = nlist.indexOfFirst { it.fav == 1 }
                            if (fidx >= 0) {
                                nlist[fidx].fav = 0
                            }
                            val index = nlist.indexOfFirst { it.img == image.img }
                            if (index >= 0) {
                                nlist[index].fav = 1
                            }
                            cowImages = nlist.toList()
                        }
                    }?.run {
                        if (!imRes.error.isNullOrEmpty()) {
                            AppData.appViewModel.setPage(imRes.error)
                        }
                    }
                }
            }
        } finally {
            loadingDetails = false
        }

    }

    suspend fun updateCowHerd(herd: HerdItem?): Boolean {
        val herdId = herd?.id ?: 0
        RestApi.updateCowHerd(cowDetay!!.hid, herdId)?.let { res ->
            Json.decodeFromString<ApiResponse>(res).let { hres ->
                hres.data?.let {
                    listCow?.herd = herd
                    return true
                }
            }
        }
        return false
    }


    suspend fun addNewEvent(event: NewEvent): String? {
        var isLast = false;
        if (event.cat != "DD") {
            val sonevt = cowEvents?.sortedByDescending { it.date }?.firstOrNull{it.cat == event.cat}
            if (sonevt != null) {
                if (Date(event.date).isAfter(Date(sonevt.date))) {
                    isLast = true;
                }
            } else {
                isLast = true;
            }
        }
        loadingDetails = isLast
        try {
            RestApi.addEvent(event, isLast)?.let { res ->
                //console.log("addevent-res: ", res)
                Json.decodeFromString<LongResponse>(res).let { eres ->
                    eres.data?.let { evtId ->
                        val nlist = cowEvents?.toMutableList()
                        nlist?.add(event.toCowEvent(evtId))
                        cowEvents = nlist?.toList()?.sortedByDescending { it.date }
                        if (event.cat == "DY"){
                            getExtraData()
                        }
                        if (isLast){
                            refreshListAndState(event.cat)
                        }

                    }
                    return eres.error
                }
            }
            return "Error"
        } finally {
            loadingDetails = false
        }
    }

    private fun refreshListAndState(cat: String){
        mainScope.launch {
            delay(1100)
            AppData.cowListVM.updateSingleCow(cowDetay!!.hid)
            if (cat != "DD"){
                setCowStateInfo()
            }
        }

    }


    suspend fun deleteEvent(eid: Long, cat: String) {
        var isLast = false
        if (cat != "DD") {
            val sonevt = cowEvents?.sortedByDescending { it.date }?.firstOrNull{it.cat == cat}
            if (sonevt != null) {
                if (sonevt.id == eid) {
                    isLast = true;
                }
            }
        }
        loadingDetails = true
        try {
            RestApi.deleteCowEvent(cowDetay!!.hid, eid, cat, isLast)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { dres ->
                }
                Json.decodeFromString<BoolResponse>(res).let { eres ->
                    eres.data?.let {
                        val nlist = cowEvents?.toMutableList()
                        val index = nlist?.indexOfFirst { it.id == eid }
                        if (index != null && index >= 0) {
                            nlist.removeAt(index)
                            cowEvents = nlist
                            if (cat == "DY"){
                                getExtraData()
                            }
                            if (isLast){
                                refreshListAndState(cat)
                            }
                        }
                    }
                }

            }
        } finally {
            loadingDetails = false
        }
    }

    suspend fun addNewMilk(milk: NewCowMilk): String? {
        loadingDetails = true
        try {
            RestApi.addCowMilk(milk)?.let { res ->
                Json.decodeFromString<CowMilkListResponse>(res).let { nmilk ->
                    nmilk.data?.let { milk ->
                        cowMilk = milk
                    }
                    return nmilk.error
                }
            }
            return "Error"
        } finally {
            loadingDetails = false
        }
    }

    suspend fun addNewNotif(notif: PersonNotif): String? {
        loadingDetails = true
        try {
            RestApi.addNotif(notif)?.let { res ->
                //console.log("addnotif-res: ", res)
                Json.decodeFromString<LongResponse>(res).let { nres ->
                    nres.data?.let { nid ->
                        val nlist  = cowNotifs?.toMutableList()
                        nlist?.add(notif.toCowNotif(nid))
                        cowNotifs = nlist?.toList()?.sortedBy { it.start }
                    }
                    return nres.error
                }
            }
            return "Error"
        } finally {
            loadingDetails = false
        }
    }

    suspend fun toggleNotif(nid: Long, done: Int): String? {
        loadingDetails = true
        try {
            RestApi.toggleNotif(nid, done)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { notifres ->
                    notifres.data?.let {
                        val nlist = cowNotifs?.toMutableList()
                        val index = nlist?.indexOfFirst { it.id == nid }
                        if (index != null && index >= 0) {
                            nlist[index].done = done
                            cowNotifs = nlist
                        }
                    }
                    return notifres.error
                }
            }
            return "Error"
        } finally {
            loadingDetails = false
        }
    }

    suspend fun deleteNotif(notif: CowNotif): String? {
        loadingDetails = true
        try {
            RestApi.deleteNotif(notif.id)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { nres ->
                    nres.data?.let {
                        val nlist = cowNotifs?.toMutableList()
                        nlist?.remove(notif)
                        cowNotifs = nlist?.toList()
                    }
                    return nres.error
                }
            }
            return "Error"
        } finally {
            loadingDetails = false
        }
    }

    private suspend fun getExtraData(){
        if (cowDetay?.gender == 0 &&
            (dateDiff(Date(), Date(cowDetay!!.birth)) > 24) ||
            AppData.cowListVM.selectedCow?.snf == 5
        ) {
            getCowCalves(cowDetay!!.kupe)
            getSagmalDonem(cowDetay!!.hid)
            showDisiExtra = true

        }else{
            showDisiExtra = false
            cowCalves = null
            cowMilk = null
        }
    }

    suspend fun getCowCalves(kupe: String) {
        try {
            RestApi.getCowCalves(kupe)?.let { res ->
                Json.decodeFromString<CowCalvesResponse>(res).let { calfData ->
                    if (calfData.data != null){
                        cowCalves = calfData.data
                    }else{
                        cowCalves = listOf()
                    }
                }
            }
        } catch (_: Exception) {
        }
    }

    private suspend fun getSagmalDonem(hid: Long) {
        try {
            RestApi.getSagmalDonem(hid)?.let { res ->
                Json.decodeFromString<CowMilkListResponse>(res).let { mData ->
                    if (mData.data != null){
                        cowMilk = mData.data
                    }else{
                        cowMilk = listOf()
                    }
                }
            }
        } catch (_: Exception) {
        }
    }

    private fun setCowStateInfo(){
        val states = mutableListOf<CowStateInfo>()
        listCow?.let { lcow ->
            if (lcow.res1>0){
                states.add(CowStateInfo(lcow.res1, getStateTitle(lcow.res1), getBilgiText(lcow.res1)))
            }
            if (lcow.res2>0){
                states.add(CowStateInfo(lcow.res2, getStateTitle(lcow.res2), getBilgiText(lcow.res2)))
            }
            cowIcons = states
        }
    }


    private fun getStateTitle(res: Int): String {
        return when (res) {
            1 -> "iconstat_fresh_title".tr()
            2 -> "iconstat_abort_title".tr()
            3 -> "iconstat_notgebe_title".tr()
            4 -> "iconstat_hasta_title".tr()
            5 -> "iconstat_kizgin_title".tr()
            6, 7, 8 -> "iconstat_gebe_title".tr()
            9 -> "iconstat_kuru_title".tr()
            10 -> "iconstat_asi_title".tr()
            11 -> "iconstat_sutkes_title".tr()
            12 -> "iconstat_tarti_title".tr() + getWeight()
            13 -> "iconstat_sagmal_title".tr()
            14 -> "iconstat_sutbuz_title".tr()
            else -> ""
        }
    }

    private fun getBilgiText(res: Int): String? {
        return when (res) {
            6, 7, 8, 9 -> gebelikBilgi(soru = res == 8)
            13 -> sagmalBilgi()
            else -> null
        }
    }


    private fun getWeight(): String {
        var kilo: Int = 0
        for (evt in cowEvents!!) {
            if (evt.cat == "KO") {
                kilo = evt.deger
                break
            }
        }
        /*
        if (kilo > 0 && units == "birim_imperial") {
            kilo = kilo.kgToPound().roundToInt()
        }*/
        return "$kilo Kg"
    }


    private fun gebelikBilgi(soru: Boolean): String {
        var bilgitxt = ""
        val tohumEvent = cowEvents?.firstOrNull { it.cat == "TY" }
        if (tohumEvent != null) {
            val tohumDate = tohumEvent.date
            val cowDogTrh = Date(cowDetay!!.birth).toLocaleDateString()
            val gebelikGun = dateDiff(today, Date(tohumDate), "d")
            val cowAylik = dateDiff(today, Date(cowDogTrh), "m")
            val gestationDay = GestationDay(cowDetay!!.breed).getGestation() + (cowAylik / 42)
            val kalangun = gestationDay - gebelikGun
            val calvingDate = Date(tohumDate).addDays(gestationDay)

            val ekBilgi = when {
                kalangun > 0 -> "detay_toast_sonra".tr().Sprintf(kalangun.toString())
                kalangun == 0 -> "detay_toast_bugun".tr()
                else -> "detay_toast_gecti".tr().Sprintf((-kalangun).toString())
            }

            val gbesure = "detay_toast_gebesure".tr().Sprintf((gebelikGun).toString())
            val dogzaman = "detay_toast_dogurmatrh".tr().Sprintf(calvingDate.toLocaleDateString())
            bilgitxt = "${"detay_toast_tohumtrh".tr().Sprintf(Date(tohumDate).toLocaleDateString())}\n$gbesure\n$dogzaman($ekBilgi)"
        }
        return bilgitxt
    }

    private val isGebe: Boolean
        get() = listCow!!.res1 == 6 || listCow!!.res1 == 7 ||
                listCow!!.res2 == 6 || listCow!!.res2 == 7

    private fun sagmalBilgi(): String {
        var bilgiText = ""
        val sonCalving = cowEvents?.firstOrNull { it.cat == "DY" }
        if (sonCalving != null) {
            val calvingDate = Date(sonCalving.date)
            val daysCalving =  dateDiff(today, calvingDate, "d")
            bilgiText = "detay_toast_sagmaltitle".tr().Sprintf((daysCalving ?: 0).toString()) + "\n"
            val tohumEvent = cowEvents?.firstOrNull { it.cat == "TY" }
            if (tohumEvent != null) {
                val tohumDate = tohumEvent.date
                if (isGebe) {
                    val weanDays = 60
                    val cowDogTrh = cowDetay!!.birth
                    val cowAylik = dateDiff(today, Date(cowDogTrh), "m")
                    val gestationDay = GestationDay(cowDetay!!.breed).getGestation() + (cowAylik ?: 0) / 42
                    val kuruDate = Date(tohumDate).addDays(gestationDay - weanDays)
                    val kuruKalan = dateDiff(kuruDate, today, "d")
                    if (kuruKalan >= 0) {
                        val sagmalSonu = "detay_toast_sagmal_sonu".tr()
                        bilgiText += "$sagmalSonu${kuruDate.toLocaleDateString()}\n($kuruKalan ${"detay_toast_days_later".tr()})"
                    }
                } else {
                    if (daysCalving > 91 && Date(tohumDate).isBefore(calvingDate)) {
                        bilgiText += "**${"detay_toast_sagmal_geciyor".tr()}**"
                    }
                }
            } else {
                if (daysCalving > 91) {
                    bilgiText += "**${"detay_toast_sagmal_geciyor".tr()}**"
                }
            }
        }
        return bilgiText
    }




}