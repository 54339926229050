package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.CikanGrup
import com.kelimesoft.cowmaster.models.DisposedCow
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.network.ApiResponse
import com.kelimesoft.cowmaster.network.DismissListResponse
import com.kelimesoft.cowmaster.network.RestApi
import com.kelimesoft.cowmaster.utils.tr
import kotlinx.serialization.json.Json
import kotlin.js.Date

class DismissVM: BaseVM() {
    val thisYear = Date().getFullYear()
    var disposedList: List<DisposedCow> by mutableStateOf(arrayListOf())
    var dismissYear by mutableStateOf(Date().getFullYear())
    var dismissGrupList: List<CikanGrup> by mutableStateOf(arrayListOf())

    var toastText by mutableStateOf("")

    var selectedCow: DisposedCow? by mutableStateOf(null)

    suspend fun getDisposedCattle(refresh: Boolean = false) {
        AppData.appViewModel.showProgress = true
        try {
            if (!checkUser()) {
                return
            }
            if (refresh.not() && disposedList.isNotEmpty()) {
                return
            }
            RestApi.getDisposedList(dismissYear)?.let { res ->
                selectedCow = null
                Json.decodeFromString<DismissListResponse>(res).let { dres ->
                    //console.log("cikanlist:", dres)
                    if (dres.data != null) {
                        disposedList = dres.data
                        var clist = mutableListOf<CikanGrup>()
                        var clrIndex = 0
                        disposedList.groupBy { it.sebep }.forEach { grup ->
                            var c = clrIndex
                            if (clrIndex + 1 > 8) c = ((clrIndex + 1) % 8)
                            clist.add(CikanGrup(grup.key, grup.value.size, AppColors.pieColorList[c]))
                            clrIndex += 1
                        }
                        dismissGrupList = clist
                    } else if (!dres.error.isNullOrEmpty()) {
                        AppData.appViewModel.setPage(dres.error)
                    }
                }
            }
        } finally {
            AppData.appViewModel.showProgress = false
        }
    }

    suspend fun nextYear() {
        if (dismissYear == thisYear) {
            toastText = "diss_year_limit".tr()
            return
        }
        dismissYear += 1
        dismissGrupList = listOf()
        disposedList = listOf()
        getDisposedCattle(true)
    }

    suspend fun prevYear() {
        if (dismissYear <= thisYear - 4) {
            toastText = "diss_year_limit".tr()
            return
        }
        dismissYear -= 1
        dismissGrupList = listOf()
        disposedList = listOf()
        getDisposedCattle(true)
    }

    suspend fun deleteDismissedCow(delMilk: Boolean) {
        selectedCow?.let {
            try {
                RestApi.delDismissedCow(it.hid, delMilk)?.let { res ->
                    Json.decodeFromString<ApiResponse>(res).let { nres ->
                        nres.data?.let {
                            getDisposedCattle(true)
                            AppData.summaryViewModel.resetRequestCowDelete()
                        }
                    }
                }
            } catch (_: Exception) {
            }
        }
    }
    suspend fun dismissCowBack() {
        selectedCow?.let {
            try {
                RestApi.dismissCowBack(it.hid)?.let { res ->
                    Json.decodeFromString<ApiResponse>(res).let { nres ->
                        nres.data?.let {
                            getDisposedCattle(true)
                            AppData.summaryViewModel.resetRequestCowDelete()
                        }
                    }
                }
            } catch (_: Exception) {
            }
        }
    }




    /*

    suspend fun addNewFinItem(finItem: FinItem) {
        try {
            RestApi.addFinItem(finItem)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { nres ->
                    nres.data?.let {
                        val ndate = Date(finItem.tarih!!)
                        if (ndate.isAfter(startDate) && ndate.isBefore(endDate)){
                            getFinanceList(true)
                        }
                    }

                }
            }
        }catch (_: Exception){}
    }

    suspend fun updateFinItem(finItem: FinItem) {
        try {
            RestApi.updateFinItem(finItem)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { nres ->
                    nres.data?.let {
                        val ndate = Date(finItem.tarih!!)
                        if (ndate.isAfter(startDate) && ndate.isBefore(endDate)){
                            getFinanceList(true)
                        }
                    }

                }
            }
        }catch (_: Exception){}
    }


*/

}


