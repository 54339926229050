package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.models.AppColors
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Canvas
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import kotlin.math.PI

@Composable
fun PieChart(modifier: Modifier, data: List<Double>, colors: List<CSSColorValue>? = null) {
    Box(
        modifier = modifier
            .overflow(Overflow.Hidden)
            .padding(4.px),
        contentAlignment = Alignment.Center
    ) {
        Canvas(
            attrs = Modifier
                .id("pie-canvas")
                .width(160.percent)
                .display(if (data.isEmpty()) DisplayStyle.None else DisplayStyle.Block)
                .toAttrs()
        )
    }
    LaunchedEffect(data) {
        var clrs = mutableListOf<CSSColorValue>()
        var pieColor = colors
        if (colors.isNullOrEmpty()) {
            for (i in data.indices) {
                var c = i
                if (i + 1 > 8) c = ((i + 1) % 8)
                clrs.add(AppColors.pieColorList[c])
            }
            pieColor = clrs.toList()
        }
        delay(200)
        if (data.isEmpty()) {
            //addChart(listOf(100.0), listOf( AppColors.Gray.opacity(0.9f)))
            //val canvas = document.getElementById("pie-canvas") as HTMLCanvasElement
        } else {
            addChart(data, pieColor!!)
        }

    }
}

fun addChart(data: List<Double>, colors: List<CSSColorValue>) {
    val total = data.sumOf { it }
    /*
    data.forEach {
        total += it
    }*/
    val canvas = document.getElementById("pie-canvas") as HTMLCanvasElement
    val context = canvas.getContext("2d") as? CanvasRenderingContext2D
    val colorSize = colors.size - 1
    //console.log("pie-total", total)
    context?.let { ctx ->
        val centerX = canvas.width / 2.0
        val centerY = canvas.height / 2.0
        val radius = canvas.height / 2.0
        var startAngle = 0.0
        data.forEachIndexed { index, item ->
            var colorIndex = index

            if (colorIndex > colorSize) {
                colorIndex = index - colorSize
            }
            val endAngle = startAngle + (item / total) * (2 * PI)
            ctx.beginPath()
            ctx.moveTo(centerX, centerY)
            ctx.arc(centerX, centerY, radius, startAngle, endAngle)
            ctx.closePath()
            //val randomColor = "rgb(${randomColorValue()}, ${randomColorValue()}, ${randomColorValue()})"
            ctx.fillStyle = colors[colorIndex]
            ctx.fill()
            startAngle = endAngle
        }

    }
}


fun createCanvas(size: Int): HTMLCanvasElement {
    val canvas = document.createElement("canvas") as HTMLCanvasElement
    canvas.height = size
    canvas.height = size
    return canvas
}

fun randomColorValue(): Int {
    return (0..255).random()
}