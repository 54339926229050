package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class NewEvent(
    val id: Long = 0,
    val hid: Long,
    val cat: String,
    val date: String,
    val deger: Int,
    val info: String,
    val not: String
){
    fun toCowEvent(id: Long): CowEvent{
        return CowEvent(id = id, hid = this.hid, cat = this.cat, date = this.date, deger = this.deger, info = this.info, not = this.not)
    }
}

@Serializable
data class NewEventBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val event: NewEvent,
    val latest: Boolean
)




enum class Events(
    val text: String,
    val icon: String

    ){

    None("shared_select".tr(), Icons.none),
    DY("durumDY".tr(), Icons.dogumyapti),
    HG("durumHG".tr(), Icons.hasta),
    TY("durumTY".tr(), Icons.tohumlu),
    KG("durumKG".tr(), Icons.kizginlik),
    AY("durumAY".tr(), Icons.asi),
    DW("durumDW".tr(), Icons.dewarming),
    SK("durumSK".tr(), Icons.sutkes),
    KA("durumKA".tr(), Icons.kuru),
    GK("durumGK".tr(), Icons.gebekontrol),
    AB("durumAB".tr(), Icons.dusuk),
    KO("durumKO".tr(), Icons.tarti),
    DD("durumDD".tr(), Icons.diger);



    companion object {
        fun disiEvents(): List<Events> {
            return listOf(DY, HG, KG, TY, AY, KA, KO, DW, DD)
        }
        fun tohumluEvents(): List<Events> {
            return listOf(HG, KG, TY, GK, AB, AY, KA, KO, DW, DD)
        }

        fun gebeEventsLater(): List<Events> {
            return listOf(DY,HG,KG,TY,AB,AY,KA,KO,DW,DD)
        }
        fun gebeEvents170gun(): List<Events> {
            return listOf(DY,HG,KG,TY,AB,AY,GK,KO,DW,DD)
        }
        fun calvingSoon(): List<Events> {
            return listOf(DY,KA,AB,HG,AY,KO,DD)
        }


        fun kuruEvents(): List<Events> {
            return listOf(DY, HG, AB, AY, DD)
        }
        fun buzagiEvents(): List<Events> {
            return listOf(HG, AY, SK, KO, DW, DD)
        }
        fun tosunEvents(): List<Events> {
            return listOf(HG, AY, KO, DW, DD)
        }
        fun topluDisiEvents(): List<Events> {
            return listOf(DY, HG, KG, TY, AY, KO, DW, DD)
        }
        fun topluGebeEvents(): List<Events> {
            return listOf(HG, KG, GK, AY, KA, KO, DD)
        }

    }

}
