package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.pages.summary.HerdListView
import com.kelimesoft.cowmaster.pages.summary.SummaryContent
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.pages.summary.DeletedCowList
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint


@Page("/ozet")
@Composable
fun SummaryPage() {
    AppData.appViewModel.activeScreens = Screens.Summary
    Router()
}

//@Page("/ozet")
@Composable
fun DairySummary() {
    val breakpoint = rememberBreakpoint()
    val summaryVM = AppData.summaryViewModel
    LaunchedEffect(true){
        summaryVM.clearToasts()
        //AppData.appViewModel.getUser()
        summaryVM.getSummary()
    }

    PageBox (modifier = Modifier.pointerEvents(PointerEvents.Auto)) {
        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden)
                .onClick {
                    closeDropDowns()
                },
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally)
        {
            Row(
                modifier = Modifier.fillMaxSize()
                    .overflow(Overflow.Hidden)
            ) {
                if (breakpoint >= Breakpoint.SM){
                    LeftPane(breakpoint)
                }
                SummaryContent(summaryVM)

            }
            if (breakpoint < Breakpoint.SM){
                Box(Modifier.height(Values.bottomBarHeight)) {  }
                Spacer()
                BottomNavigation(breakpoint)
            }
        }
        /*
        if (breakpoint < Breakpoint.SM){
            Box(modifier = Modifier.fillMaxSize()
                .pointerEvents(PointerEvents.None),
                contentAlignment = Alignment.BottomCenter
                ) {
                BottomNavigation(breakpoint)
            }
        }*/

        if (summaryVM.toastText.isNotEmpty()){
            ToastView(summaryVM.toastText, pos = ToastPos.Center){
                summaryVM.toastText = ""

            }
        }

        if (summaryVM.toastErrorText.isNotEmpty()){
            ToastView(summaryVM.toastErrorText, pos = ToastPos.Top, color = Colors.Red){
                summaryVM.toastErrorText = ""
            }
        }

        if (summaryVM.showHerdList){
            DialogView {
                HerdListView(onClose = {
                    summaryVM.showHerdList = false
                })
            }
        }
        if (summaryVM.showDeletedList){
            DialogView {
                DeletedCowList(onRestore = {

                }, onClose = {
                  summaryVM.showDeletedList = false
                })
            }
        }

    }
}










