package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.CowMilk
import com.kelimesoft.cowmaster.styles.CowMilkTitleStyle
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun CowMilkList(milks: List<CowMilk>?, addMilk: () -> Unit) {
    CowDetayCard(
        title = "detaymilk_title".tr(),
        button =
        {
            DetailsButton("detaymilk_add".tr(), addMilk)
        },

        ) {
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceAround
        ) {
            Span(
                attrs = CowMilkTitleStyle
                    .toAttrs()
            ) {
                Text("detaymilk_trh".tr())
            }
            Span(
                attrs = CowMilkTitleStyle
                    .toAttrs()
            ) {
                Text("detaymilk_mik".tr())
            }
            Span(
                attrs = CowMilkTitleStyle
                    .toAttrs()
            ) {
                Text("detaymilk_gun".tr())
            }
            Span(
                attrs = CowMilkTitleStyle
                    .toAttrs()
            ) {
                Text("detaymilk_sgg".tr())
            }
        }
        VDivider()

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .maxHeight(300.px)
                .overflow { y(Overflow.Auto) }
                .overflow { x(Overflow.Hidden) }
        ) {
            milks?.forEach { milk ->
                CowMilkRow(milk)
            }

            if (milks.isNullOrEmpty()) {
                Span(
                    attrs = Modifier
                        .fillMaxWidth()
                        .color(AppColors.TextColor.copyf(alpha = 0.8f))
                        .margin(2.px)
                        .fontSize(15.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text("detaymilk_norecord".tr())
                }
            }
        }
    }
}


@Composable
private fun CowMilkRow(milk: CowMilk) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Column(
                modifier = Modifier.fillMaxWidth()
                    .padding(right = 6.px)
                    .color(AppColors.TextColor)
            ) {
                Span(
                    attrs = Modifier
                        .fillMaxWidth()
                        .textAlign(TextAlign.End)
                        .margin(1.px)
                        .fontSize(13.px)
                        .toAttrs()
                ) {
                    Text(Date(milk.bastrh).toLocaleDateString())
                }
                Span(
                    attrs = Modifier
                        .fillMaxWidth()
                        .textAlign(TextAlign.End)
                        .fontSize(13.px)
                        .margin(1.px)
                        .toAttrs()
                ) {
                    Text(Date(milk.sontrh).toLocaleDateString())
                }


            }
            Span(
                attrs = Modifier
                    .fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .margin(1.px)
                    .fontWeight(FontWeight.Medium)
                    .fontSize(14.px)
                    .toAttrs()
            ) {
                Text(milk.sutmik.round(1).toString())
            }

            Span(
                attrs = Modifier
                    .fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .margin(1.px)
                    .fontWeight(FontWeight.Medium)
                    .fontSize(14.px)
                    .toAttrs()
            ) {
                Text(milk.avg.round(1).toString())
            }

            Span(
                attrs = Modifier
                    .fillMaxWidth()
                    .margin(left = 10.px)
                    .textAlign(TextAlign.Center)
                    .margin(1.px)
                    .fontWeight(FontWeight.Medium)
                    .fontSize(14.px)
                    .toAttrs()
            ) {
                Text(milk.dim.toString())
            }
        }
        VDivider()
    }
}

