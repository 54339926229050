package com.kelimesoft.cowmaster.styles

import kotlinx.browser.document
import kotlinx.dom.appendText
import org.w3c.dom.HTMLStyleElement


fun addPrintStyles(){
    val styleElement = document.createElement("style") as HTMLStyleElement
    styleElement.type = "text/css"
    styleElement.appendText(hidePrintStyle())
    document.head?.appendChild(styleElement)
}

fun hidePrintStyle(): String {
    return """
        @media print { 
            #left-pane { 
                display: none!important;
               } 
        }
    """.trimIndent()
}