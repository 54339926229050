package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.network.NotifListResponse
import com.kelimesoft.cowmaster.utils.*
import kotlinx.serialization.json.Json
import kotlin.js.Date

class NotifListVm: HaveDates, BaseVM() {
    var notifList: List<ListNotif> by mutableStateOf(arrayListOf())
    override var startDate by mutableStateOf(Date().firstDayOfMonth())
    override var endDate by mutableStateOf(startDate.lastDayOfMonth())
    var toastText by mutableStateOf("")
    var toastErrorText by mutableStateOf("")
    override var selectStart by mutableStateOf(startDate)
    override var selectEnd by mutableStateOf(endDate)
    var loadingNotifs by mutableStateOf(false)


    suspend fun getNotifList(refresh: Boolean = false) {
        AppData.appViewModel.showProgress = true
        try {
            if(!checkUser()){
                return
            }
            if (refresh.not() && notifList.isNotEmpty()){
                return
            }
            var firstweekday = startDate.getDay()
            var lastweekday = endDate.getDay()
            if (firstweekday == 0) firstweekday = 7
            if (lastweekday == 0) lastweekday = 7
            var sdate = startDate
            var edate = endDate
            if (firstweekday > 1) {
                //val prevMontDays = startDate.previousMonth().daysInMonth()
                sdate = startDate.addDays(-(firstweekday-1))
            }
            if (lastweekday < 7) {
                edate = endDate.addDays(7 - lastweekday)
            }
            val json = Json {
                ignoreUnknownKeys = true
            }

            RestApi.getNotifList(start = sdate.toISOString(), end = edate.toISOString())?.let { res ->
                //console.log("norif-res:", res)
                json.decodeFromString<NotifListResponse>(res).let { nres ->
                    if (nres.data != null) {
                        notifList = nres.data
                    }
                    else if (!nres.error.isNullOrEmpty()){
                        AppData.appViewModel.setPage(nres.error)
                    }
                    else {
                        notifList = listOf()
                    }
                }
            }
        }finally {
            AppData.appViewModel.showProgress = false
        }


    }

    override suspend fun gotoPrevious(){
        val ndate = startDate.previousMonth().firstDayOfMonth()
        startDate = ndate
        selectStart = ndate
        endDate = ndate.lastDayOfMonth()
        selectEnd = endDate
        getNotifList(true)
    }

    override suspend fun gotoNext(){
        val ndate = startDate.nextMonth().firstDayOfMonth()
        startDate = ndate
        selectStart = ndate
        endDate = ndate.lastDayOfMonth()
        selectEnd = endDate
        getNotifList(true)
    }

    override fun changeSelection(d1: Date, d2: Date){
        selectStart = d1
        selectEnd = d2
    }


    suspend fun addNewNotif(notif: PersonNotif) {
        loadingNotifs = true
        try {
            RestApi.addNotif(notif)?.let { res ->
                Json.decodeFromString<LongResponse>(res).let { nres ->
                    nres.data?.let {
                        val ndate = Date(notif.date)
                        if (ndate.isAfter(startDate) && ndate.isBefore(endDate)){
                            getNotifList(true)
                        }
                    }

                }
            }
        } finally {
            loadingNotifs = false
        }
    }

    suspend fun toggleNotif(nid: Long, done: Int): String? {
        loadingNotifs = true
        try {
            RestApi.toggleNotif(nid, done)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { notifres ->
                    notifres.data?.let {
                        val index = notifList.indexOfFirst { it.id == nid }
                        val oldNotif = notifList[index]
                        val newNotif = oldNotif.copy(done = done)
                        val nlist = notifList.toMutableList()
                        nlist[index] = newNotif
                        notifList = nlist.toList()
                    }
                    return notifres.error
                }
            }
            return "Error"
        } finally {
            loadingNotifs = false
        }
    }

    suspend fun deleteNotif(nid: Long) {
        loadingNotifs = true
        try {
            RestApi.deleteNotif(nid)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { apires ->
                    if(apires.data != null){
                        val index = notifList.indexOfFirst { it.id == nid }
                        val nlist = notifList.toMutableList()
                        nlist.removeAt(index)
                        notifList = nlist.toList()
                    }
                }
            }
        } finally {
            loadingNotifs = true
        }
    }


    /*
    suspend fun delHerd(herd: HerdGrup){
        RestApi.delHerd(herd.id)?.let { res ->
            Json.decodeFromString<HerdEditResponse>(res).let { hres ->
                hres.data?.let {data ->
                    if(data == "success"){
                        val index = herdList.indexOfFirst { it.id == herd.id }
                        herdList.removeAt(index)
                        herdSay--
                    }
                }
            }
        }


    }
    suspend fun saveHerd(herd: HerdGrup){
        RestApi.saveHerd(herd)?.let { res ->
            Json.decodeFromString<HerdEditResponse>(res).let { hres ->
                hres.data?.let {data ->
                    if(data == "success"){
                        val index = herdList.indexOfFirst { it.id == herd.id }
                        herdList[index] = herd
                    }
                }
            }
        }
    }

    suspend fun addNewHerd(herd: HerdGrup){
        RestApi.addNewHerd(herd)?.let { res ->
            Json.decodeFromString<HerdEditResponse>(res).let { hres ->
                hres.data?.let {data ->
                    if(data == "success"){
                        herdList.add(herd)
                        herdList = herdList.toMutableList()

                        herdSay++
                    }
                }
            }
        }
    }

*/


}

interface HaveDates {
    var startDate: Date
    var endDate: Date
    var selectStart: Date
    var selectEnd: Date

    suspend fun gotoPrevious()
    suspend fun gotoNext()
    fun changeSelection(d1: Date, d2: Date)
}

