package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.models.CowCalf
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun CowCalvesList(calves: List<CowCalf>?, addCalf: () -> Unit) {
    CowDetayCard(
        title = "cowcalf_list".tr(),
        button =
        {
            DetailsButton("shared_add".tr(), addCalf)
        },

        ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .maxHeight(300.px)
                .overflow { y(Overflow.Auto) }
                .overflow { x(Overflow.Hidden) }
        ) {
            calves?.forEach { calf ->
                CowCalfRow(calf)
            }

            if (calves.isNullOrEmpty()) {
                Span(
                    attrs = Modifier
                        .fillMaxWidth()
                        .color(AppColors.TextColor.copyf(alpha = 0.8f))
                        .margin(2.px)
                        .fontSize(15.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text("cowcalf_notfound".tr())
                }
            }
        }


    }

}


@Composable
private fun CowCalfRow(calf: CowCalf) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start
        ) {
            Image(
                modifier = Modifier
                    .size(26.px)
                    .margin(right = 6.px),
                src = Values.imgPath2 + calf.getSnfIcon()
            )
            Column(
                modifier = Modifier
                    .color(AppColors.TextColor)
            ) {

                if (calf.ism.isNotEmpty()) {
                    Span(
                        attrs = Modifier
                            .fontSize(14.px)
                            .margin(1.px)
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) {
                        Text(calf.ism)
                    }
                }


                Span(
                    attrs = Modifier
                        .margin(1.px)
                        .fontSize(14.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text(calf.kp)
                }

            }

            Spacer()
            Column(horizontalAlignment = Alignment.End) {
                Span(
                    attrs = Modifier
                        .margin(1.px)
                        .textAlign(textAlign = TextAlign.End)
                        .color(AppColors.TextColor)
                        .fontSize(14.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text("cowcalf_dtrh".tr())
                    Text(Date(calf.dtrh).toLocaleDateString())
                }

                if (calf.ctrh.isNotEmpty()) {
                    Span(
                        attrs = Modifier
                            .margin(1.px)
                            .textAlign(textAlign = TextAlign.End)
                            .color(AppColors.DarkRed)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        Text("${"cowcalf_ctrh".tr()}${Date(calf.ctrh).toLocaleDateString()}")
                        Text(", ")
                        Text(calf.csebep)
                    }
                }
            }
        }
    }
    VDivider()

}
