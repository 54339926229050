package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.models.Durums
import com.kelimesoft.cowmaster.models.MilkPeriod
import com.kelimesoft.cowmaster.models.Numbers
import com.kelimesoft.cowmaster.network.HerdListResponse
import com.kelimesoft.cowmaster.network.SummaryResponse
import kotlinx.serialization.json.Json
import kotlin.js.Date

class SummaryViewModel: BaseVM() {
    var sumItems = SumItems().resetRequest()
    //var summaryData by mutableStateOf(SummaryData())
    var latestSumTime: Long =  0L
    var cattleNumbers: Numbers by mutableStateOf(Numbers())
    var cattleStates: Durums by mutableStateOf(Durums())

    var dailyNotif: Int by mutableStateOf(0)
    var milkSum: MilkPeriod by mutableStateOf(MilkPeriod())
    var notifList: ArrayList<ListNotif> by mutableStateOf(arrayListOf())
    var eventList: ArrayList<ListEvent> by mutableStateOf(arrayListOf())
    var financeSum: FinSum by mutableStateOf(FinSum())
    var herdList: MutableList<HerdItem>? by mutableStateOf(null)
    var disposedCows: Int by mutableStateOf(0)
    var deletedCows: Int by mutableStateOf(0)
    var allCows: Int by mutableStateOf(0)

    var deletedList: List<DeletedCow>? by mutableStateOf(null)


    var toastText by mutableStateOf("")
    var toastErrorText by mutableStateOf("")
    var showHerdList by mutableStateOf(false)
    var showDeletedList by mutableStateOf(false)


    suspend fun getSummary() {
        //Değişiklik yapılmasa bile 200 sn geçtiyse özet yenilenecek
        val simdiLong = Date.now().toLong() / 200000
        if (simdiLong != latestSumTime){
            sumItems = sumItems.resetRequest()
            latestSumTime = simdiLong
        }
        if (sumItems.totalSum() <= 0){
            return
        }
        AppData.appViewModel.showProgress = true
        try {
            if(!checkUser()){
                return
            }
            RestApi.getSummary(sumItems)?.let { res ->
                val json = Json {
                    ignoreUnknownKeys = true
                }
                json.decodeFromString<SummaryResponse>(res).let { sumRes ->
                    if (sumRes.data != null) {
                        val summ = sumRes.data
                        if (summ.numbers != null) cattleNumbers = summ.numbers ?: Numbers()
                        if (summ.durums != null) cattleStates = summ.durums ?: Durums()
                        if (summ.milk2 != null) milkSum = summ.milk2 ?: MilkPeriod()
                        if (summ.notif != null) dailyNotif = summ.notif?.daily ?: 0
                        if (summ.notiflist != null) notifList = summ.notiflist ?: arrayListOf()
                        if (summ.eventlist != null) eventList = summ.eventlist ?: arrayListOf()
                        if (summ.herd != null) herdList = summ.herd ?: arrayListOf()
                        if (summ.fin != null) financeSum = summ.fin ?: FinSum()
                        if (summ.disposed != null) disposedCows = summ.disposed ?: 0
                        if (summ.deleted != null) deletedCows  = summ.deleted ?: 0
                        if (summ.allcows != null) allCows = summ.allcows ?: 0

                        /*
                        if (summ.dismiss != null) summaryData.dismiss = summ.dismiss
                        if (summ.notif != null) summaryData.notif = summ.notif
                        if (summ.events != null) summaryData.events = summ.events

                        if (summ.finance != null) summaryData.finance = summ.finance
                        if (summ.herdsay != null) summaryData.herdsay = summ.herdsay

                         */

                        sumItems = sumItems.requestZero()
                    }
                    else if (!sumRes.error.isNullOrEmpty()){
                        AppData.appViewModel.setPage(sumRes.error)
                    }
                }
            }
        }finally {
            AppData.appViewModel.showProgress = false
        }


    }

    fun resetRequestCowsAdded(){
        sumItems.num = 1
        sumItems.dur = 1
    }

    fun resetRequestCowDismissed(){
        sumItems.num = 1
        sumItems.dur = 1
        sumItems.dis = 1
        sumItems.notif = 1
        sumItems.nlist = 1
        sumItems.elist = 1
        sumItems.herd = 1
    }
    fun bulkEventReset(){
        sumItems.dur = 1
        sumItems.elist = 1
    }

    fun finChangedReset(){
        sumItems.fin = 1
    }

    fun milkChangeReset(){
        sumItems.milk = 1
    }

    fun resetRequestCowDelete(){
       resetRequestList()
    }


    private fun resetRequestList() {
        sumItems = sumItems.resetRequest()
    }

    suspend fun getHerdList(refresh: Boolean = false) {
        if (!refresh && herdList != null){
            return
        }
        AppData.appViewModel.showProgress = true
        try {
            RestApi.getHerdList()?.let { res ->
                //console.log("herd-list", res)
                Json.decodeFromString<HerdListResponse>(res).let { hdata ->
                    if (hdata.error.isNullOrEmpty()){
                        herdList = hdata.data
                    }else{
                        AppData.appViewModel.setPage(hdata.error)
                    }
                }
            }
        }finally {
            AppData.appViewModel.showProgress = false
        }

    }

    suspend fun delHerd(herd: HerdItem){
        RestApi.delHerd(herd.id)?.let { res ->
            Json.decodeFromString<BoolResponse>(res).let { hres ->
                hres.data?.let {
                    if (!herdList.isNullOrEmpty()){
                        val index = herdList!!.indexOfFirst { it.id == herd.id }
                        herdList!!.removeAt(index)
                    }

                }
            }
        }
    }
    suspend fun saveHerd(herd: HerdItem){
        RestApi.saveHerd(herd)?.let { res ->
            Json.decodeFromString<LongResponse>(res).let { hres ->
                hres.data?.let { newId ->
                    if (herd.id > 0){
                        val index = herdList?.indexOfFirst { it.id == herd.id }
                        if (index != null && index >=0){
                            herdList!![index] = herd
                        }else{}
                    }else{
                        var nherd = herd
                        nherd.id = newId
                        herdList?.add(nherd)
                    }
                }
            }
        }
    }


    suspend fun getDeletedList(refresh: Boolean = false) {
        if (!refresh && deletedList != null){
            return
        }
        AppData.appViewModel.showProgress = true
        try {
            RestApi.getDeletedList()?.let { res ->
                //console.log("herd-list", res)
                Json.decodeFromString<DeletedListResponse>(res).let { hdata ->
                    if (hdata.error.isNullOrEmpty()){
                        deletedList = hdata.data
                    }else{
                        AppData.appViewModel.setPage(hdata.error)
                    }
                }
            }
        }finally {
            AppData.appViewModel.showProgress = false
        }

    }


    suspend fun undoDeletedcow(hid: Long){
        RestApi.undoDeletedCow(hid)?.let { res ->
            Json.decodeFromString<ApiResponse>(res).let { hres ->
                hres.data?.let {
                    deletedList = deletedList?.filter { it.hid != hid }
                    deletedCows -= 1
                    allCows += 1
                    resetRequestCowDelete()
                }
            }
        }
    }


    fun clearToasts(){
        toastText = ""
        toastErrorText = ""
    }


}


