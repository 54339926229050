package com.kelimesoft.cowmaster.pages.notif

import StaggeredVerticalGrid2
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.ListNotif
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.styles.NotifSelectButtonStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.NotifListVm
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun NotifGroups(notifListVm: NotifListVm, notifGrup: MutableState<String?>, showTmm: Boolean) {
    val groupedList: Map<String, List<ListNotif>> =
        notifListVm.notifList.filter { notif ->
            (notif.done < if (showTmm) 2 else 1)
                    && Date(notif.start).isBefore(notifListVm.selectEnd.endOfDay())
                    && Date(notif.end).isAfter(notifListVm.selectStart.startOfDay())}.groupBy { it.title }

    StaggeredVerticalGrid2(col = 2, gap = 2){
        groupedList.forEach { grup ->
            Box(modifier = Modifier
                .fillMaxWidth()
                .padding(4.px),
                contentAlignment = Alignment.TopCenter
            ) {
                Row(modifier = NotifSelectButtonStyle.toModifier()
                    .thenIf(grup.key == notifGrup.value){
                        Modifier.background(AppColors.Primary.opacity(0.2f))
                            .border(width = 0.4.px, style = LineStyle.Solid, color = Colors.White.opacity(0.2f))
                    }.onClick {
                        if (notifGrup.value == grup.key) notifGrup.value = null else notifGrup.value = grup.key

                    }
                    .margin(right = 2.px, bottom = 4.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    if (grup.key == notifGrup.value){
                        FaCheck(size = IconSize.SM, modifier = Modifier
                            .padding(right = 10.px)
                            .color(AppColors.TextColor.opacity(0.8f)))
                    }
                    Span(attrs = Modifier
                        .fontSize(14.px)
                        .color(AppColors.TextColor)
                        .fontWeight(FontWeight.SemiBold)
                        .margin(right = 20.px)
                        .toAttrs()) {
                        Text(grup.key.tr())
                        Text(" : ")
                    }
                    Spacer()
                    Span(attrs = Modifier
                        .fontSize(15.px)
                        .color(AppColors.TextColor)
                        .fontWeight(FontWeight.SemiBold)
                        .toAttrs()) {
                        Text(grup.value.size.toString())
                    }

                }
            }

        }
    }
}