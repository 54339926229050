package com.kelimesoft.cowmaster.models
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EventCow(
    val hid: Long = 0L,
    var kupe: String = "",
    var gender: Int = 0,
    var name: String = "",
    var snf: Int = 0,
    var gebe: Int = 0,
    var sagmal: Int = 0,
    var kuru: Int = 0,
    var tohum: Int = 0,
    var fresh: Int = 0,
    var open: Int = 0,
) {

    var selected by  mutableStateOf(false)
    var evtDeger by  mutableStateOf(0)

}

@Serializable
data class BulkEvent(
    var hid: Long,
    var deger: Int
)

@Serializable
data class NewBulkEvent(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val email: String = AppData.cowUser?.email ?: "",
    var cat: String,
    var edate: String,
    var einfo: String,
    var not: String,
    var cows: List<BulkEvent>,
    var pregday: Int = 35,
    var dryday: Int = 60
)




