package com.kelimesoft.cowmaster.pages.milk

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.styles.DateSelectStyle
import com.kelimesoft.cowmaster.styles.SummaryCardstyle
import com.kelimesoft.cowmaster.utils.dateToDbStr
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.MilkVM
import com.kelimesoft.cowmaster.models.MilkPeriod
import com.kelimesoft.cowmaster.models.MilkItem
import com.kelimesoft.cowmaster.styles.DetailButtonStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*


import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import kotlin.js.Date

/*
var milk: Double = 0.0,
var buz: Double = 0.0,
var sagmal: Double = 0.0,
var gelir: Double = 0.0,
var perday: Double = 0.0,
var percow: Double = 0.0,*/

@Composable
fun DairyPeriodMilk(milkVM: MilkVM, onAdd: () -> Unit) {
    val milk = milkVM.dairyPeriodsum
    DairyMilkPeriodCard(milkVM, "milkview_dairyyield".tr(), onAdd = {
        onAdd()
    }) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top,
        ) {

            MilkPeriodItem(Icons.sagmal, "summilk_sutmik".tr(), milk.milk)
            MilkPeriodItem(Icons.biberon, "summilk_buzmik".tr(), milk.buz)
            MilkPeriodItem(Icons.gugum, "summilk_kalan".tr(), milk.milk - milk.buz)
            Box(
                modifier = Modifier.fillMaxWidth(96.percent)
                    .height(1.px)
                    .margin(topBottom = 4.px)
                    .backgroundColor(Colors.Gray)
            ) { }
            MilkPeriodItem(null, "summilk_cowsut".tr(), milk.percow)
            MilkPeriodItem(null, "summilk_dairy".tr(), milk.perday)
            MilkPeriodItem(null, "summilk_cowsay".tr(), milk.sagmal)
            MilkPeriodItem(null, "summilk_gelir".tr(), milk.gelir)
        }
    }

}


@Composable
fun MilkPeriodItem(icon: String?, title: String, mik: Double) {
    Row(
        modifier = Modifier
            .padding(leftRight = 8.px)
            .margin(topBottom = 6.px)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        if (icon != null) {
            Image(
                modifier = Modifier.size(22.px),
                src = icon
            )
        }
        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(left = 2.px)
                .textAlign(textAlign = TextAlign.Start)
                .fontSize(13.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {
            Text(title)
        }

        P(
            attrs = Modifier
                .width(100.px)
                .margin(right = 2.px)
                .textAlign(textAlign = TextAlign.End)
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Medium)
                .toAttrs()
        ) {
            Text(mik.round())
        }

    }
}

/*

@Composable
fun PrevPeriodMilk(milkVM: MilkVM) {
    val prevMilk = milkVM.dairyPreviousMilk!!
    MilkPrevPeriodCard(prevMilk, "Önceki Dönem Süt") {
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top,
        ) {

            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(topBottom = 10.px)
                    .padding(leftRight = 20.px),
                horizontalArrangement = Arrangement.SpaceAround) {
                PrevMilkTotalRow(Icons.sagmal, prevMilk.sutmik)
                PrevMilkTotalRow(Icons.biberon, prevMilk.buzmik)
                PrevMilkTotalRow(Icons.gugum, prevMilk.kalan)
            }


            Box(
                modifier = Modifier.fillMaxWidth(96.percent)
                    .height(1.px)
                    .margin(topBottom = 4.px)
                    .backgroundColor(Colors.Gray)
            ) { }

            PrevMilkRow(null, "summilk_cowsut".tr(), prevMilk.sagmalort)
            PrevMilkRow(null, "summilk_dairy".tr(), prevMilk.gunluk)
            PrevMilkRow(null, "summilk_cowsay".tr(), prevMilk.sagmal)
            PrevMilkRow(null, "summilk_gelir".tr(), prevMilk.gelir)
        }
    }

}
*/

@Composable
fun DairyMilkPeriodCard(milkVM: MilkVM,
                        title: String,
                        onAdd: () -> Unit,
                        content: @Composable ColumnScope.() -> Unit) {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    Column(
        modifier = SummaryCardstyle.toModifier()
            .padding(3.px)
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally

    ) {

        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(3.px)
                .backgroundColor(AppColors.Primary.opacity(0.2f))
                .borderRadius(8.px)
            ,
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround) {
            Span(
                attrs = Modifier
                    .weight(1f)
                    .textAlign(TextAlign.Start)
                    .color(AppColors.TextColor)
                    .fontSize(15.px)
                    .fontWeight(FontWeight.SemiBold)
                    .margin(top = 2.px)
                    .padding(leftRight = 4.px)
                    .toAttrs()
            ) {
                Text(title)
            }
            MilkAddButton(
                if (breakpoint > Breakpoint.SM) "finbar_add".tr() else " + ", AppColors.Primary
            ){
                onAdd()
            }
        }
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(6.px)
                .boxShadow(
                    offsetX = 1.px,
                    offsetY = 1.px,
                    1.px,
                   1.px,
                    color = Colors.Black.copyf(alpha = 0.1f)
                )
                .minWidth(240.px)
                .margin(2.px)
                .borderRadius(r= 6.px)
                .backgroundColor(AppColors.BarColor.opacity(0.5f))
                .height(Values.barHeight),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Box(modifier = DateSelectStyle.toModifier()
                .onClick {
                    scope.launch { milkVM.dairyPrevPeriod() }
                }
                .margin(2.px)
                .size(30.px), contentAlignment = Alignment.Center) {
                FaChevronLeft(size = IconSize.LG, modifier = Modifier.color(AppColors.TextColor))
            }

            Input(
                type = InputType.Date,
                attrs = DateSelectStyle.toModifier()
                    //.display(DisplayStyle.None)
                    .id("dmilk-start")
                    .toAttrs {
                        attr("name", "sdate")
                        attr("placeholder", "Select Date")
                        attr("value", milkVM.dairyStartDate.dateToDbStr())
                        attr("min", "2019-01-01")
                        onChange {
                            var ndate = milkVM.dairyStartDate
                            try {
                                ndate = Date(it.value)
                            } catch (_: Exception) {
                            }
                            milkVM.dairyStartDate = ndate
                        }
                    }
            )

            Spacer()
            Input(
                type = InputType.Date,
                attrs = DateSelectStyle.toModifier()
                    //.display(DisplayStyle.None)
                    .id("dmilk-end")
                    .toAttrs {
                        attr("name", "sdate")
                        attr("placeholder", "Select Date")
                        attr("value", milkVM.dairyEndDate.dateToDbStr())
                        attr("min", milkVM.dairyStartDate.dateToDbStr())
                        onInput {
                            var ndate = milkVM.dairyEndDate
                            try {
                                ndate = Date(it.value)
                            } catch (_: Exception) {
                            }
                            milkVM.dairyEndDate = ndate
                        }
                    }
            )

            Box(modifier = DateSelectStyle.toModifier()
                .onClick {
                    (document.getElementById("dmilk-start") as HTMLInputElement).value = milkVM.dairyStartDate.dateToDbStr()
                    (document.getElementById("dmilk-end") as HTMLInputElement).value = milkVM.dairyEndDate.dateToDbStr()
                    scope.launch {
                        milkVM.dairyNextPeriod()
                    }
                }
                .margin(2.px)
                .size(30.px), contentAlignment = Alignment.Center) {
                FaChevronRight(size = IconSize.LG, modifier = Modifier.color(AppColors.TextColor))
            }
        }

        content()
    }

}

@Composable
fun CowMilkPeriodCard(milkVM: MilkVM,
                      title: String,
                      onAdd: () -> Unit,
                      content: @Composable ColumnScope.() -> Unit) {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    Column(
        modifier = SummaryCardstyle.toModifier()
            .padding(3.px)
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(3.px)
                .backgroundColor(AppColors.DetailBlue.opacity(0.2f))
                .borderRadius(8.px)
            ,
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround) {
            Span(
                attrs = Modifier
                    .weight(1f)
                    .textAlign(TextAlign.Start)
                    .color(AppColors.TextColor)
                    .fontSize(15.px)
                    .fontWeight(FontWeight.SemiBold)
                    .margin(top = 2.px)
                    .padding(leftRight = 4.px)
                    .toAttrs()
            ) {
                Text(title)
            }

            MilkAddButton(
                if (breakpoint > Breakpoint.SM) "finbar_add".tr() else " + ", AppColors.DetailBlue
            ){
                onAdd()
            }
        }

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(6.px)
                .boxShadow(
                    offsetX = 1.px,
                    offsetY = 1.px,
                    1.px,
                    1.px,
                    color = Colors.Black.copyf(alpha = 0.1f)
                )
                .minWidth(240.px)
                .margin(2.px)
                .borderRadius(r= 6.px)
                .backgroundColor(AppColors.BarColor.opacity(0.5f))
                .height(Values.barHeight),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Box(modifier = DateSelectStyle.toModifier()
                .onClick {
                    scope.launch { milkVM.cowPrevPeriod() }
                }
                .margin(2.px)
                .size(30.px), contentAlignment = Alignment.Center) {
                FaChevronLeft(size = IconSize.LG, modifier = Modifier.color(AppColors.TextColor))
            }

            Input(
                type = InputType.Date,
                attrs = DateSelectStyle.toModifier()
                    //.display(DisplayStyle.None)
                    .id("cmilk-start")
                    .toAttrs {
                        attr("name", "sdate")
                        attr("placeholder", "Select Date")
                        attr("value", milkVM.cowStartDate.dateToDbStr())
                        attr("min", "2019-01-01")
                        onChange {
                            var ndate = milkVM.cowStartDate
                            try {
                                ndate = Date(it.value)
                            } catch (_: Exception) {
                            }
                            milkVM.cowStartDate = ndate
                        }
                    }
            )

            Spacer()
            Input(
                type = InputType.Date,
                attrs = DateSelectStyle.toModifier()
                    //.display(DisplayStyle.None)
                    .id("cmilk-end")
                    .toAttrs {
                        attr("name", "sdate")
                        attr("placeholder", "Select Date")
                        attr("value", milkVM.cowEndDate.dateToDbStr())
                        attr("min", milkVM.cowStartDate.dateToDbStr())
                        onInput {
                            var ndate = milkVM.cowEndDate
                            try {
                                ndate = Date(it.value)
                            } catch (_: Exception) {
                            }
                            milkVM.cowEndDate = ndate
                        }
                    }
            )

            Box(modifier = DateSelectStyle.toModifier()
                .onClick {
                    (document.getElementById("cmilk-start") as HTMLInputElement).value = milkVM.cowStartDate.dateToDbStr()
                    (document.getElementById("cmilk-end") as HTMLInputElement).value = milkVM.cowEndDate.dateToDbStr()
                    scope.launch {
                        milkVM.cowNextPeriod()
                    }
                }
                .margin(2.px)
                .size(30.px), contentAlignment = Alignment.Center) {
                FaChevronRight(size = IconSize.LG, modifier = Modifier.color(AppColors.TextColor))
            }
        }

        content()
    }

}


@Composable
fun MilkAddButton(title: String, back: Color.Rgb, onClick: () -> Unit) {
    Button(
        attrs = DetailButtonStyle.toModifier()
            .whiteSpace(WhiteSpace.NoWrap)
            .onClick {
                onClick()
            }
            .fontSize(13.px)
            .height(23.px)
            .backgroundColor(back)
            .color(Colors.White)
            .toAttrs()
    ) {
        Text(title)
    }
}



@Composable
fun MilkPrevPeriodCard(milk: MilkPeriod, title: String, content: @Composable ColumnScope.() -> Unit) {
    val scope = rememberCoroutineScope()
    Column(
        modifier = SummaryCardstyle.toModifier(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(6.px)
                .boxShadow(
                    offsetX = 1.px,
                    offsetY = 1.px,
                    1.px,
                    1.px,
                    color = Colors.Black.copyf(alpha = 0.1f)
                )
                .minWidth(240.px)
                .margin(2.px)
                .borderRadius(r= 6.px)
                .backgroundColor(AppColors.BarColor.opacity(0.5f))
                .height(Values.barHeight),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            SpanText(Date(milk.start).toLocaleDateString(),
                modifier = Modifier
                    .fontSize(14.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.Medium))

            Span(
                attrs = Modifier
                    .weight(1f)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.TextColor)
                    .fontSize(16.px)
                    .fontWeight(FontWeight.SemiBold)
                    .margin(leftRight = 4.px)
                    .padding(leftRight = 4.px)
                    .toAttrs()
            ) {
                Text(title)
            }
            SpanText(Date(milk.end).toLocaleDateString(),
                    modifier = Modifier
                    .fontSize(14.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Medium))

        }

        content()
    }

}

@Composable
private fun PrevMilkRow(icon: String?, title: String, sut: MilkItem) {
    Row(
        modifier = Modifier
            .padding(leftRight = 8.px)
            .margin(topBottom = 6.px)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        if (icon != null) {
            Image(
                modifier = Modifier.size(22.px),
                src = icon
            )
        }
        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(left = 2.px)
                .textAlign(textAlign = TextAlign.Start)
                .fontSize(13.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {
            Text(title)
        }

        P(
            attrs = Modifier
                .width(100.px)
                .margin(right = 10.px)
                .textAlign(textAlign = TextAlign.End)
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Medium)
                .toAttrs()
        ) {
            Text(sut.mik.round())
        }

    }
}

@Composable
fun PrevMilkTotalRow(icon: String, sut: MilkItem) {
    Row {
        Image(
            modifier = Modifier.size(22.px),
            src = icon
        )
        P(
            attrs = Modifier
                .margin(right = 10.px)
                .textAlign(textAlign = TextAlign.End)
                .fontSize(16.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Medium)
                .toAttrs()
        ) {
            Text(sut.mik.round())
        }
    }
}
