package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PersonNotif(
    var hid: Long = 0L,
    var title: String,
    var text: String,
    var date: String,
    var user: String = "all"
){
    fun toCowNotif(id: Long): CowNotif{
        return CowNotif(id = id, title = this.title, text = this.text, start = this.date, end = this.date)
    }
}

@Serializable
data class NewNotifBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val notif: PersonNotif
)

