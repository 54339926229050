package com.kelimesoft.cowmaster.pages.finance

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.FinType
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.pages.cowdetails.DetailsButton
import com.kelimesoft.cowmaster.styles.DetailButtonStyle

import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs

import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text


@Composable
fun FinTitleBar(type: FinType,
                breakpoint: Breakpoint, onAdd: () -> Unit) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .fontFamily(Values.FONT_FAMILY)
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .minWidth(240.px)
            .backgroundColor(AppColors.BarColor.opacity(0.7f))
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText(if (type == FinType.Gelir) "finbar_gelir".tr() else "finbar_gider".tr(),
            modifier = Modifier.fontSize(16.px)
                .color(if(type == FinType.Gelir) AppColors.Primary else AppColors.DarkRed))
        Spacer()
        AddButton(
            if (breakpoint > Breakpoint.SM) "finbar_add".tr() else " + ", type
        ){
            onAdd()
        }

    }
}


@Composable
fun AddButton(title: String, type: FinType, onClick: () -> Unit) {
    Button(
        attrs = DetailButtonStyle.toModifier()
            .whiteSpace(WhiteSpace.NoWrap)
            .onClick {
                onClick()
            }
            .fontSize(13.px)
            .height(23.px)
            .backgroundColor(if (type == FinType.Gelir)
                AppColors.Primary.copyf(alpha = 0.8f)
            else AppColors.DarkRed.opacity(0.8f))
            .color(Colors.White)
            .toAttrs()
    ) {
        Text(title)
    }
}