package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.NumberRow
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.SummaryViewModel
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.px


@Composable
fun NumberSummary(sumVM: SummaryViewModel) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(leftRight = 6.px),
        verticalArrangement = Arrangement.Top
    ) {
        NumberRow("sumnum_inek".tr(), Icons.inek, "inek", sumVM.cattleNumbers.inek)
        NumberRow("sumnum_duve".tr(), Icons.duve, "duve",sumVM.cattleNumbers.duve)
        NumberRow("sumnum_tosun".tr(), Icons.tosun, "tosun",sumVM.cattleNumbers.tosun)
        NumberRow("sumnum_dana".tr(), Icons.dana, "dana",sumVM.cattleNumbers.dana)
        NumberRow("sumnum_buz".tr(), Icons.buzw, "buzagi",sumVM.cattleNumbers.buz)
    }
}

@Composable
fun StateSummary(sumVM: SummaryViewModel) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(leftRight = 6.px),
        verticalArrangement = Arrangement.Top
    ) {
        NumberRow("sumdrm_hasta".tr(), Icons.hasta, "hasta",sumVM.cattleStates.hasta )
        NumberRow("sumdrm_gebe".tr(), Icons.gebe, "gebe",sumVM.cattleStates.gebe)
        NumberRow("sumdrm_sagmal".tr(), Icons.sagmal, "sagmal",sumVM.cattleStates.sagmal)
        NumberRow("sumdrm_kuru".tr(), Icons.kuru, "kuru",sumVM.cattleStates.kuru)
        NumberRow("sumdrm_tohum".tr(), Icons.tohumlu, "tohum",sumVM.cattleStates.tohum)
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            NumberRow("sumdrm_taze".tr(), Icons.taze, "fresh",sumVM.cattleStates.fresh)
            NumberRow("sumdrm_bos".tr(), Icons.open, "open",sumVM.cattleStates.open)
        }
    }
}




