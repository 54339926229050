package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.utils.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.js.Date

enum class MilkTime {
    Monthly, Quarterly, Annual
}

class MilkVM : BaseVM() {
    var dairyMilkList: List<TotalMilk> by mutableStateOf(listOf())
    var dairyStartDate by mutableStateOf(Date().firstDayOfMonth())
    var dairyEndDate by mutableStateOf(dairyStartDate.lastDayOfMonth())
    var dairyPeriodsum: TotalPeriodSum by mutableStateOf(TotalPeriodSum())

    var cowMilkList: List<CowMilkItem> by mutableStateOf(listOf())
    var cowStartDate by mutableStateOf(Date().firstDayOfMonth())
    var cowEndDate by mutableStateOf(cowStartDate.lastDayOfMonth())
    var cowPeriodsum: CowPeridSum by mutableStateOf(CowPeridSum())
    var sagmalCowList: List<SagmalInek> by mutableStateOf(listOf())


    suspend fun getDairyMilk(refresh: Boolean = false) {
        AppData.appViewModel.showProgress = true
        try {
            if (!checkUser()) {
                return
            }
            RestApi.getDairyMilk(
                start = dairyStartDate.toISOString(),
                end = dairyEndDate.toISOString()
            )?.let { res ->
                //console.log("milk-res:", res)
                Json.decodeFromString<TotalMilkResponse>(res).let { mres ->
                    console.log(mres)
                    if (mres.data != null) {
                        dairyPeriodsum = mres.data.sum ?: TotalPeriodSum()
                        dairyMilkList = mres.data.list ?: listOf()
                    }
                }
            }
        } finally {
            AppData.appViewModel.showProgress = false
        }
    }


    suspend fun getCowMilk(refresh: Boolean = false) {
        //startDate.isDate
        AppData.appViewModel.showProgress = true
        try {
            if (!checkUser()) {
                return
            }
            RestApi.getCowMilkData(
                start = cowStartDate.toISOString(),
                end = cowEndDate.toISOString()
            )?.let { res ->
                //console.log("cow-milk-res:", res)
                Json.decodeFromString<CowMilkResponse>(res).let { mres ->
                    if (mres.data != null) {
                        //dairyCurrentMilk = mres.data.current
                        //dairyPreviousMilk = mres.data.previous
                        cowPeriodsum = mres.data.sum ?: CowPeridSum()
                        cowMilkList = mres.data.list ?: listOf()
                    }
                }
            }
        } finally {
            AppData.appViewModel.showProgress = false
        }
    }


    suspend fun dairyPrevPeriod() {
        dairyStartDate = dairyStartDate.previousMonth()
        dairyEndDate = dairyStartDate.lastDayOfMonth()
        getDairyMilk(true)
    }

    suspend fun dairyNextPeriod() {
        dairyStartDate = dairyStartDate.nextMonth()
        dairyEndDate = dairyStartDate.lastDayOfMonth()
        getDairyMilk(true)
    }


    suspend fun cowPrevPeriod() {
        cowStartDate = cowStartDate.previousMonth()
        cowEndDate = cowStartDate.lastDayOfMonth()
        getCowMilk(true)
    }

    suspend fun cowNextPeriod() {
        cowStartDate = cowStartDate.nextMonth()
        cowEndDate = cowStartDate.lastDayOfMonth()
        getCowMilk(true)
    }


    fun groupDailyMilk(list: List<TotalMilk>): List<TotalMilk> {
        var groupList = mutableListOf<TotalMilk>()
        val groupMap = list.groupBy { it.mdate }
        groupMap.forEach { grup ->
            val trh = grup.key
            val sagmik = grup.value.fold(0.0) { sag, milk -> sag + (milk.milk ?: 0.0) }
            val buzmik = grup.value.fold(0.0) { buz, milk -> buz + (milk.buz ?: 0.0) }
            groupList.add(TotalMilk(id = 0, mdate = trh, milk = sagmik, buz = buzmik, cows = 0, price = 0.0))

        }
        return groupList
    }


    fun dayGroupCowMilk(): List<CowMilkItem> {
        var groupList = mutableListOf<CowMilkItem>()
        val groupMap = cowMilkList.groupBy { it.mdate }
        groupMap.forEach { grup ->
            val trh = grup.key
            val sagmik = grup.value.fold(0.0) { sag, milk -> sag + (milk.milk ?: 0.0) }
            groupList.add(CowMilkItem(id = 0, mdate = trh, milk = sagmik))

        }
        return groupList
    }
    /*
    @Serializable
    data class CowMilkItem(
        var id: Long = 0,
        var hid: Long = 0,
        var kupe: String = "",
        var name: String = "",
        var milk: Double? = 0.0,
        var mdate: String? = null,
        var period: MilkTime? = null
    )*/

    fun cowGroupCowMilk(): List<CowMilkItem> {
        var groupList = mutableListOf<CowMilkItem>()
        val groupMap = cowMilkList.groupBy { it.kupe }
        groupMap.forEach { grup ->
            console.log(grup)
            val kupe = grup.key
            val sagmik = grup.value.fold(0.0) { sag, milk -> sag + (milk.milk ?: 0.0) }
            console.log(grup, sagmik)
            groupList.add(CowMilkItem(id = 0, kupe = kupe, milk = sagmik))

        }
        return groupList
    }


    suspend fun addTotalMilk(milk: TotalMilk) {
        try {
            if (!checkUser()) {
                return
            }

            RestApi.addTotalMilk(milk)?.let { res ->
                //console.log("milk-res:", res)
                Json.decodeFromString<LongResponse>(res).let { mres ->
                    console.log(mres)
                    if (milk.mdate!! <= dairyEndDate.dateToDbStr()
                        && milk.mdate!! >= dairyStartDate.dateToDbStr()
                    ) {
                        if (mres.data != null && mres.data > 0) {
                            val tmilk = milk.apply { id = mres.data }
                            val nlist = dairyMilkList.toMutableList()
                            nlist.add(tmilk)
                            dairyMilkList = nlist.toList()
                        }
                    }

                }
            }
        } finally {
            AppData.appViewModel.showProgress = false
        }
    }

    suspend fun addManyMilk(milk: ManyCowMilk) {
        try {
            if (!checkUser()) {
                return
            }
            RestApi.addManyCowMilk(milk)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { mres ->
                    if (mres.error == null) {
                        if (milk.mdate <= cowEndDate.dateToDbStr()
                            && milk.mdate >= cowStartDate.dateToDbStr()
                        ) {
                            getCowMilk(true)
                        }
                        sagmalCowList.forEach {
                            it.milk = 0.0
                        }
                    }

                }
            }
        } finally {
            AppData.appViewModel.showProgress = false
        }
    }


    suspend fun getSagmalCows(refresh: Boolean = false) {
        if (sagmalCowList.isNotEmpty()) {
            return
        }
        try {
            if (!checkUser()) {
                return
            }
            val json = Json {
                ignoreUnknownKeys = true
            }
            RestApi.getSagmalList()?.let { res ->
                json.decodeFromString<SagmalListResponse>(res).let { nres ->
                    if (nres.data != null) {
                        sagmalCowList = nres.data
                    } else if (!nres.error.isNullOrEmpty()) {
                        AppData.appViewModel.setPage(nres.error)
                    } else {
                        sagmalCowList = listOf()
                    }
                }
            }

        } catch (_: Exception) {
        }
    }


    suspend fun deleteDairyMilk(milk: TotalMilk) {
        try {
            if (!checkUser()) {
                return
            }
            RestApi.deleteDairyMilk(milk.id)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { mres ->
                    if (mres.error == null) {
                        if (milk.mdate!! <= dairyEndDate.dateToDbStr()
                            && milk.mdate!! >= dairyStartDate.dateToDbStr()
                        ) {
                            val nlist = dairyMilkList.toMutableList()
                            nlist.remove(milk)
                            dairyMilkList = nlist.toList()
                        }

                    }

                }
            }
        } catch (_: Exception) {
        }
    }

    suspend fun deleteCowMilk(milk: CowMilkItem) {
        try {
            if (!checkUser()) {
                return
            }
            RestApi.deleteCowMilk(milk.id)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { mres ->
                    if (mres.error == null) {
                        if (milk.mdate!! <= cowEndDate.dateToDbStr()
                            && milk.mdate!! >= cowStartDate.dateToDbStr()
                        ) {
                            val nlist = cowMilkList.toMutableList()
                            nlist.remove(milk)
                            cowMilkList = nlist.toList()
                        }

                    }

                }
            }
        } catch (_: Exception) {
        }
    }

}


