package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.models.AppColors
import com.varabyte.kobweb.compose.css.functions.RadialGradient
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.shapes.Shape
import kotlinx.browser.document
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Progress

@Composable
fun ProgressView() {
    /*
    LaunchedEffect(true){
        createProgress()
    }
    DialogView {
        Box(modifier = Modifier.size(10.px),
            contentAlignment = Alignment.Center
        ) {
            Image(src = "progress.svg", modifier = Modifier.styleModifier {
                property("animation", "spin 1.2s linear infinite")
            })
        }

    }
    */
    DialogView {
        Progress()
    }
}




