package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.components.DropDownMenu
import com.kelimesoft.cowmaster.components.ErrorText
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.MilkTime
import com.kelimesoft.cowmaster.models.NewCowMilk
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.DropMenuItemStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.CowDetailViewModel
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import kotlin.js.Date

@Composable
fun AddCowMilk(scope: CoroutineScope, detailVM: CowDetailViewModel, onClose: () -> Unit) {
    var errorText by remember { mutableStateOf("") }
    var milkPeriod: MilkTime by remember { mutableStateOf(MilkTime.Morning) }
    var milkDate by remember { mutableStateOf(Date().dateToDbStr()) }
    var milkQuantity by remember { mutableStateOf(0.0) }


    fun checkMilkDates(): Boolean {
        var withInPeriods = false
        detailVM.cowMilk!!.forEach { mperiod ->
            if (!withInPeriods) {
                if (Date(milkDate).isAfter(Date(mperiod.bastrh).startOfDay()) &&
                    Date(milkDate).isBefore(Date(mperiod.sontrh).endOfDay())
                ) {
                    withInPeriods = true
                }
            }
        }
        if (!withInPeriods) {
            errorText = "addmilk_betweend_dates".tr()
        }
        return withInPeriods
    }

    fun saveMilk() {
        if (!Funcs.checkForm("form-milk")) {
            return
        }
        if (!checkMilkDates()) {
            return
        }

        val nMilk = NewCowMilk(
            hid = detailVM.cowDetay!!.hid,
            milk = milkQuantity.rountTo(2),
            mdate = milkDate,
            period = milkPeriod
        )
        scope.launch {
            val res = detailVM.addNewMilk(nMilk)
            if (res == null) {
                onClose()
            } else {
                errorText = res
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("addmilk_add".tr(), onClose = onClose)

        Column(
            Modifier
                .fillMaxWidth()
                .padding(topBottom = 6.px, leftRight = 8.px)
        ) {

            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.Start
            ) {
                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .margin(top = 4.px)
                        .padding(1.px)
                        .color(AppColors.TextColor.copyf(alpha = 0.6f))
                        .toAttrs()
                ) {
                    Text("addmilk_time".tr())
                }


                Row(modifier = Modifier.fillMaxWidth()
                    .margin(bottom = 6.px)
                    , verticalAlignment = Alignment.CenterVertically
                ) {
                    Box(
                        Modifier
                            .fillMaxWidth(percent = 35.percent)
                            .margin(2.px)
                        ,
                        contentAlignment = Alignment.Center
                    ) {
                        DropDownMenu(
                            menuModifier = Modifier.fillMaxWidth(),
                            butonModifier = CowInputStyle.toModifier()
                                .fillMaxWidth()
                                .padding(6.px)
                                .margin(topBottom = 8.px)
                                .whiteSpace(WhiteSpace.NoWrap)
                                .onClick {
                                    it.stopPropagation()
                                    val menu = document.getElementById("add-drop") as HTMLDivElement
                                    if (menu.style.display == "block") {
                                        menu.style.display = "none"
                                    } else {
                                        closeDropDowns()
                                        menu.style.display = "block"
                                    }
                                },
                            id = "add-drop",
                            content = {
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Text(milkPeriod.text)
                                    Spacer()
                                    FaCaretDown(
                                        modifier = Modifier
                                            .color(AppColors.GrayDark),
                                        size = IconSize.LG
                                    )

                                }
                            }) {
                            MilkTime.entries.forEach { evt ->
                                Box(
                                    modifier = DropMenuItemStyle.toModifier()
                                        .padding(3.px)
                                        .color(AppColors.TextColor)
                                        .fontSize(14.px)
                                        .margin(bottom = 2.px)
                                        .onClick {
                                            if (milkPeriod != evt) {
                                                milkPeriod = evt
                                            }
                                        }
                                ) {

                                    Text(evt.text)

                                }
                            }
                        }
                        if (errorText.isNotEmpty()) {
                            ErrorText(errorText)
                        }

                    }

                    Input(
                        type = InputType.Date,
                        attrs = CowInputStyle.toModifier()
                            .fillMaxWidth()
                            .id("milk-date")
                            .padding(7.px)
                            .attrsModifier {
                                attr("required", "true")
                                attr("value", Date().dateToDbStr())
                                attr("max", Date().dateToDbStr())
                            }
                            .margin(2.px)
                            .toAttrs()
                            {
                                onInput {
                                    milkDate = Date(it.value).dateToDbStr()
                                    errorText = ""
                                    checkMilkDates()
                                }
                            }
                    )
                }

            }

            Form(
                attrs = Modifier
                    .id("form-milk")
                    .fillMaxWidth()
                    .toAttrs()
            ) {

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(bottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Span(
                        attrs = Modifier
                            .whiteSpace(WhiteSpace.NoWrap)
                            .margin(right = 10.px)
                            .fontSize(14.px)
                            .color(AppColors.TextColor)
                            .toAttrs()
                    ) {
                        Text("addmilk_miktar".tr())
                    }

                    Input(
                        type = InputType.Text,
                        attrs = CowInputStyle.toModifier()
                            .attrsModifier {
                                inputMode(InputMode.Decimal)
                                attr("required", "true")
                                attr("min", "1")
                                attr("max", "1000")
                            }
                            .toAttrs()
                            {
                                onInput {
                                    milkQuantity = it.value.toDouble()
                                }
                            }
                    )
                }
                if (errorText.isNotEmpty()) {
                    ErrorText(errorText)
                }

            }
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }


                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            saveMilk()
                        }
                        .toAttrs()

                ) {
                    Text("addmilk_add".tr())
                }
            }

        }


    }

}


