package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.RowButton
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.models.CowNotif
import com.kelimesoft.cowmaster.utils.Sprintf
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toAttrs
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun CowNotifList(notifs: List<CowNotif>?, addNotif: () -> Unit) {
    val detailVM = AppData.cowListVM.cowDetailVM
    val scope = rememberCoroutineScope()
    CowDetayCard(
        title = "detayntf_title".tr(),
        button =
        {
            DetailsButton("shared_add".tr(), addNotif)
        },

        ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .maxHeight(300.px)
                .overflow { y(Overflow.Auto) }
                .overflow { x(Overflow.Hidden) }
        ) {
            notifs?.forEach { notif ->
                CowNotifRow(notif, onComplete = {
                    scope.launch {
                        detailVM.toggleNotif(notif.id, if (notif.done == 0) 1 else 0)
                    }
                }, onDelete = {
                    scope.launch {
                        detailVM.deleteNotif(notif)
                    }
                })
            }

            if (notifs.isNullOrEmpty()) {
                Span(
                    attrs = Modifier
                        .fillMaxWidth()
                        .color(AppColors.TextColor.copyf(alpha = 0.8f))
                        .margin(2.px)
                        .fontSize(15.px)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()
                ) {
                    Text("detayntf_no".tr())
                }
            }
        }


    }

}


@Composable
private fun CowNotifRow(notif: CowNotif,
                        onComplete: () -> Unit,
                        onDelete: ()->Unit
                        ) {
    var showButtons by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .padding(2.px),
            contentAlignment = Alignment.TopEnd
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .fillMaxWidth()
                    .onMouseOver {
                        showButtons = true
                    }
                    .onMouseLeave {
                        showButtons = false
                    },
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                if (showButtons){
                    Row(
                        modifier = Modifier.width(90.px),
                        horizontalArrangement = Arrangement.End
                    ) {
                        RowButton{

                            FaCheck(
                                modifier = Modifier
                                    .color(AppColors.Primary.opacity(0.9f))
                                    .onClick {
                                        onComplete()
                                    },
                                size = IconSize.LG,

                            )
                        }
                        RowButton{
                            FaTrash(modifier = Modifier.color(AppColors.DarkRed.opacity(0.9f))
                                    .onClick {
                                        onDelete()
                                    },
                                size = IconSize.SM
                            )
                        }

                    }
                }else{
                    Box(
                        modifier = Modifier
                            .size(24.px)
                            .borderRadius(r = 50.percent)
                            .backgroundColor(notif.notifColor())
                            .margin(right = 8.px)
                    )
                }

                Column(modifier = Modifier.fillMaxWidth()) {

                    Span(
                        attrs = Modifier
                            .fontSize(14.px)
                            .margin(1.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) {
                        Text(notif.title.tr())
                    }
                    Span(
                        attrs = Modifier
                            .fontSize(13.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Normal)
                            .margin(1.px)
                            .opacity(90.percent)
                            .toAttrs()
                    ) {
                        Text(notif.text.tr().Sprintf(Date(notif.edate).toLocaleDateString()))

                    }

                }

            }


            P(
                attrs = Modifier
                    //.fillMaxWidth(37.percent)
                    .margin(right = 4.px)
                    .textAlign(textAlign = TextAlign.End)
                    .fontSize(14.px)
                    .color(notif.notifColor())
                    .fontWeight(FontWeight.Normal)
                    .padding(2.px)
                    .backgroundColor(AppColors.Gray.copyf(alpha = 0.2f))
                    .borderRadius(r = 6.px)
                    .zIndex(1)
                    .toAttrs()
            ) {
                Text(Date(notif.start).toLocaleDateString())
            }
        }
        VDivider()
    }

}
