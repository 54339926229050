package com.kelimesoft.cowmaster.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class EditedCow(
    val hid: Long,
    var name: String? = null,
    var kupe: String? = null,
    var gender: Int? = null,
    var birth: String? = null,
    var breed: String? = null,
    var dam: String? = null,
    var sire: String? = null,
    var notes: String? = null
)