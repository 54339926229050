package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.FaSignOut
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.styles.DisposedSumStyle
import com.kelimesoft.cowmaster.viewmodals.SummaryViewModel
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text


@Composable
fun DisposedDismisSum(sumVM: SummaryViewModel, onDel: () -> Unit) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .padding(leftRight = 6.px),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Link(modifier = DisposedSumStyle.toModifier()
            .fillMaxWidth()
            .margin(6.px)
            .textDecorationLine(TextDecorationLine.None),
            path = Screens.Dismiss.path

        ){
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(leftRight = 12.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceAround
                ) {
                    FaSignOut(size = IconSize.XXL, modifier = Modifier
                        .padding(4.px)
                        .color(AppColors.NotifNow))
                    Spacer()
                    P(
                        attrs = Modifier
                            .margin(4.px)
                            .padding(4.px)
                            .fontSize(1.6.cssRem)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) {
                        Text("${sumVM.disposedCows}")
                    }
                }


            }

        Row(
            modifier = DisposedSumStyle.toModifier()
                .padding(leftRight = 12.px)
                .margin(6.px)
                .onClick {
                    onDel()
                }
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround
        ) {
            FaTrash(size = IconSize.XXL, modifier = Modifier
                .padding(4.px)
                .color(AppColors.DarkRed))
            Spacer()
            P(
                attrs = Modifier
                    .margin(4.px)
                    .padding(4.px)
                    .fontSize(1.6.cssRem)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .toAttrs()
            ) {
                Text("${sumVM.deletedCows}")
            }
        }

    }
}


@Composable
fun DismissSummary(dismissList: ArrayList<DismissGroup>) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .maxHeight(300.px)
            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Unset)
            .scrollBehavior(ScrollBehavior.Smooth)
            .styleModifier {
                //property("scrollbar-width", "thin")
            }
            .padding(7.px),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {
        dismissList.forEach { dismiss ->
            DismissRow(dismiss)
        }

    }

}
@Composable
fun DismissRow(dismiss: DismissGroup) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {

                FaSignOut(size = IconSize.LG, modifier = Modifier
                    .margin(right = 10.px)
                    .padding(4.px)
                    .color(AppColors.DarkRed.copyf(alpha = 0.7f)))

                Span(attrs = Modifier.fillMaxWidth()
                    .textAlign(textAlign = TextAlign.Start)
                    .fontSize(15.px)
                    .margin(1.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.Medium)
                    .toAttrs()){
                    Text(dismiss.sebep)
                }

                Span(attrs = Modifier.fillMaxWidth()
                    .textAlign(textAlign = TextAlign.End)
                    .fontSize(15.px)
                    .margin(1.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .toAttrs()){
                    Text(dismiss.sayi.toString())
                }


            }

        VDivider()
    }

}
