package com.kelimesoft.cowmaster.pages.eventlist

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.ListEvent
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle

import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.CowListViewModel
import com.kelimesoft.cowmaster.viewmodals.EventListVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize

import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import kotlin.js.Date

@Composable
fun EventListView(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint,
    eventListVM: EventListVM,
    eventGrup: String = "all",
    onAddEvent: () -> Unit
) {
    val scope = rememberCoroutineScope()

    var delEvent: ListEvent? by remember { mutableStateOf(null) }
    var showDelete by remember { mutableStateOf(false) }


    val filteredList: List<ListEvent> =
        eventListVM.eventList.filter { event ->
            (if (eventGrup == "all") event.cat.isNotEmpty() else event.cat == eventGrup)
        }

    Box(modifier = modifier
        //.fillMaxWidth(if (breakpoint > Breakpoint.SM) 50.percent else 98.percent)
        .minWidth(if (breakpoint > Breakpoint.SM) 320.px else 260.px)
        //.maxWidth(if (breakpoint > Breakpoint.SM) 560.px else 1000.px)
        .fillMaxHeight()
        .borderRadius(topRight = 8.px, topLeft = 8.px)
        .backgroundColor(Colors.White)
        .padding(6.px)
    ) {

        Column(
            modifier = modifier
                .fillMaxSize()
                .margin(leftRight = 2.px)
                .overflow(Overflow.Hidden)
        ) {
            EventListBar(breakpoint) {
                onAddEvent()
            }

            //CowSearch(search)
            Column(
                modifier = Modifier
                    .fillMaxWidth()

                    .overflow { y(Overflow.Auto) }
                    .overflow { x(Overflow.Hidden) }

            ) {
                filteredList.forEachIndexed { index, event ->
                    //(n.bastrh <= '%s' AND n.bittrh >= '%s'), son, ilk
                    val selected = Date(event.date).isBefore(eventListVM.selectEnd.endOfDay())
                            && Date(event.date).isAfter(eventListVM.selectStart.startOfDay())
                    if (selected) {
                        EventListRow(event) {
                            delEvent = event
                            showDelete = true
                        }
                    }

                }
            }
        }

        if (showDelete) {
            DialogView {
                DeleteListEvent(onClose = {
                    showDelete = false
                }, onDelete = {
                    if (delEvent != null) {
                        scope.launch {
                            eventListVM.deleteListEvent(delEvent!!)
                            delEvent = null
                            showDelete = false
                        }
                    }


                })
            }
        }
    }

}


@Composable
fun EventListRow(event: ListEvent, onDelete: () -> Unit) {
    var showDel by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier.fillMaxWidth()
            .onMouseOver {
                showDel = true
            }
            .onMouseLeave {
                showDel = false
            },
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .padding(2.px),
            contentAlignment = Alignment.TopEnd
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Image(
                    modifier = Modifier
                        .size(24.px)
                        .margin(right = 8.px)
                    ,
                    src = event.icon
                )

                Column(modifier = Modifier.fillMaxWidth()) {
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(14.px)
                            .margin(1.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        if (event.kupe.isNotEmpty()){
                            Text(event.kupe)
                        }
                        if (event.name.isNotEmpty()){
                            Text(", ")
                            Text(event.name)
                        }
                    }
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(14.px)
                            .margin(1.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        if (event.title.isNotEmpty()){
                            Span(attrs = Modifier
                                .fontWeight(FontWeight.SemiBold)
                                .toAttrs()) {
                                Text(event.title)
                            }

                        }
                        if (event.not.isNotEmpty()){
                            if (event.title.isNotEmpty()){
                                Text(", ")
                            }
                            Text(event.not)
                        }
                    }
                }

            }

            Row {
                if (showDel) {
                    RowButton {
                        FaTrash(
                            modifier = Modifier.color(AppColors.DarkRed.opacity(0.9f))
                                .onClick {
                                    onDelete()
                                },
                            size = IconSize.SM
                        )
                    }
                }
                Column(horizontalAlignment = Alignment.End) {
                    P(
                        attrs = Modifier
                            .margin(1.px)
                            .textAlign(textAlign = TextAlign.End)
                            .color(AppColors.TextColor)
                            .fontSize(13.px)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        Text(Date(event.date).toLocaleDateString())
                    }
                    P(
                        attrs = Modifier
                            .margin(1.px)
                            .textAlign(textAlign = TextAlign.End)
                            .fontSize(12.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Normal)
                            .toAttrs()
                    ) {
                        Text(Date(event.date).toLocaleTimeString())
                    }
                }
            }


        }
        VDivider()
    }

}


@Composable
private fun DeleteListEvent(onDelete: () -> Unit, onClose: () -> Unit) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White), horizontalAlignment = Alignment.Start
    ) {

        DialogTitle("Durum Sil".tr(), onClose = onClose)

        ErrorText(
            "Durum bilgisi silinecek".tr(),
            Modifier
                .textAlign(TextAlign.Start)
                .fontSize(16.px)
                .padding(10.px)
                .color(AppColors.TextColor)
        )

        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(top = 20.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Button(
                attrs = TextButtonStyle.toModifier()
                    .fontSize(16.px)
                    .color(AppColors.DarkRed)
                    .onClick {
                        it.preventDefault()
                        onClose()
                    }
                    .toAttrs()

            ) {
                Text("shared_iptal".tr())
            }

            Spacer()
            Button(
                attrs = MainButtonStyle.toModifier()
                    .backgroundColor(AppColors.Red)
                    .onClick {
                        it.preventDefault()
                        onDelete()
                    }
                    .toAttrs()

            ) {
                Text("shared_sil".tr())
            }
        }


    }

}