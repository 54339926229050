package com.kelimesoft.cowmaster.styles

import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.opacity
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val MainButtonStyle = CssStyle {
    base {
        Modifier
            .width(110.px)
            .height(36.px)
            .borderRadius(r = 6.px)
            .border(0.px)
            .backgroundColor(AppColors.PrimaryDark)
            .color(Colors.White)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "width", duration = 200.ms),
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .width(130.px)
            .backgroundColor(AppColors.PrimaryDark.copyf(alpha = 0.8f))
    }
}

val MobilAddButtonStyle = CssStyle {
    base {
        Modifier
            .width(100.px)
            .borderRadius(r = 10.px)
            .border(0.px)
            .padding(leftRight = 6.px, topBottom = 12.px)
            .backgroundColor(AppColors.PrimaryDark.copyf(alpha = 0.8f))
            .color(Colors.White)
            .fontSize(14.px)
            .fontWeight(FontWeight.Medium)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "width", duration = 200.ms),
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .width(120.px)
            .backgroundColor(AppColors.PrimaryDark.copyf(alpha = 0.8f))
    }
}


val TextButtonStyle = CssStyle {
    base {
        Modifier
            //.height(34.px)
            .borderRadius(r = 6.px)
            .border(0.px)
            .padding(leftRight = 10.px, topBottom = 6.px)
            .fontSize(13.px)
            .backgroundColor(Colors.Transparent)
            .color(AppColors.TextColor)
            .cursor(Cursor.Pointer)
            .opacity(70.percent)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "opacity", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .opacity(90.percent)
            .backgroundColor(AppColors.LightBlue.copyf(alpha = 0.3f))
    }
}

val LeftPaneButtonStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .border(0.px)
            .borderRadius(
                topRight = 6.px,
                bottomRight = 6.px
            )
            .padding(leftRight = 10.px, topBottom = 4.px)
            .margin(bottom = 6.px)
            .fontSize(13.px)
            .backgroundColor(Colors.Black.copyf(alpha = 0.05f))
            .color(Colors.White)
            .cursor(Cursor.Pointer)
            .opacity(90.percent)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "opacity", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .opacity(90.percent)
            .backgroundColor(Colors.Black.copyf(alpha = 0.3f))
    }

}

val BarButtonStyle = CssStyle {
    base {
        Modifier
            //.height(34.px)
            .borderRadius(r = 6.px)
            .border(0.px)
            .padding(leftRight = 6.px, topBottom = 6.px)
            .fontSize(14.px)
            .fontWeight(FontWeight.Medium)
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.1f))
            .color(AppColors.TextColor)
            .cursor(Cursor.Pointer)
            //.opacity(90.percent)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "opacity", duration = 200.ms)
            )
    }
    hover {
        Modifier
            .opacity(90.percent)
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.3f))
    }
}

val DetailButtonStyle = CssStyle {
    base {
        Modifier
            //.height(34.px)
            .borderRadius(r = 6.px)
            .border(0.px)
            .padding(leftRight = 6.px, topBottom = 2.px)
            .fontSize(14.px)
            .fontWeight(FontWeight.Medium)
            .backgroundColor(AppColors.Red)
            .color(AppColors.TextColor)
            .cursor(Cursor.Pointer)
            //.opacity(90.percent)
            .transition(
                CSSTransition(property = "opacity", duration = 200.ms),
                CSSTransition(property = "box-shadow", duration = 200.ms)
            )
    }
    hover {
        Modifier
            .opacity(90.percent)
            .boxShadow(1.px, 1.px, 2.px, 1.px, AppColors.GrayDark.copyf(alpha = 0.4f))
    }
}

val DropDownButtonStyle = CssStyle {
    base {
        Modifier
            //.height(34.px)
            .borderRadius(r = 6.px)
            .border(0.px)
            .padding(leftRight = 6.px, topBottom = 6.px)
            .fontSize(14.px)
            .fontWeight(FontWeight.Medium)
            .backgroundColor(AppColors.GrayDark)
            .color(Colors.White.copyf(alpha = 0.8f))
            .cursor(Cursor.Pointer)
            //.opacity(90.percent)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "color", duration = 200.ms),
            )
    }

    hover {
        Modifier
            .color(Colors.White.copyf(alpha = 0.9f))
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.8f))
    }
}

val NumCardStyle = CssStyle {
    base {
        Modifier
            .styleModifier {
                property("break-inside", "avoid")
            }
            .width(74.px)
            .height(120.px)
            .margin(1.px)
            .padding(6.px)
            .borderRadius(r = 4.px)
            .cursor(Cursor.Pointer)
            .opacity(80.percent)
            .transition(
                CSSTransition(property = "opacity", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .opacity(95.percent)
    }
}

val NumRowStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 10.px)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.2f))
    }
}

val DisposedSumStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 10.px)
            .cursor(Cursor.Pointer)
            .boxShadow(1.px, 1.px, blurRadius = 2.px, color = AppColors.DarkRed.copyf(alpha = 0.1f))
            .transition(
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.2f))
    }
}


val DropMenuItemStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .padding(4.px)
            .cursor(Cursor.Pointer)
            .border(0.px)
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 4.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.2f))
    }
}


val PayMethodStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .padding(4.px)
            .cursor(Cursor.Pointer)
            .border(0.px)
            .borderRadius(r = 5.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.2f))
    }
}


val CloseButtonStyle = CssStyle {
    base {
        Modifier
            .borderRadius(r = 20.px)
            .border(0.px)
            .padding(12.px)
            .backgroundColor(Colors.Red.copyf(alpha = 0.7f))
            .color(Colors.White)
            .cursor(Cursor.Pointer)
            .boxShadow(1.px, 1.px, 1.px, 1.px, color = Colors.Gray.copyf(alpha = 0.2f))
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "opacity", duration = 200.ms),
                CSSTransition(property = "box-shadow", duration = 300.ms),
            )
    }
    hover {
        Modifier
            .opacity(90.percent)
            .boxShadow(1.px, 1.px, 2.px, 2.px, color = Colors.Gray.copyf(alpha = 0.3f))
            .backgroundColor(Colors.Red.copyf(alpha = 0.9f))
    }
}

val RowButtonStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .borderRadius(30.percent)
            .size(24.px)
            .margin(right = 6.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .cursor(Cursor.Pointer)
            .backgroundColor(Colors.Teal.copyf(alpha = 0.3f))
    }
}


val BottomNavButtonStyle = CssStyle {
    base {
        Modifier
            .fillMaxSize()
            .border(0.px)
            .backgroundColor(Colors.Black.copyf(alpha = 0.05f))
            .color(Colors.White)
            .cursor(Cursor.Pointer)
            .opacity(90.percent)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }

    hover {
        Modifier
            .backgroundColor(Colors.Black.copyf(alpha = 0.3f))
    }


}


val NotifSelectButtonStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .backgroundColor(AppColors.DropMenu)
            .borderRadius(r = 10.px)
            .padding(6.px)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "width", duration = 200.ms),
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .backgroundColor(AppColors.Primary.opacity(0.01f))
            .border(0.1.px, LineStyle.Solid, color = Colors.WhiteSmoke.opacity(0.7f))
    }
}

val HerdSelectButtonStyle = CssStyle {
    base {
        Modifier
            .height(16.px)
            .borderRadius(r = 6.px)
            .border(0.px)
            .padding(leftRight = 8.px, topBottom = 2.px)
            .fontSize(13.px)
            .backgroundColor(AppColors.Gray.opacity(0.2f))
            .color(AppColors.TextColor)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "opacity", duration = 200.ms),
                CSSTransition(property = "box-shadow", duration = 200.ms)
            )
    }
    hover {
        Modifier
            .opacity(90.percent)
            .boxShadow(1.px, 1.px, 2.px, 1.px, AppColors.GrayDark.copyf(alpha = 0.4f))
    }
}

