package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.models.EventCow
import com.kelimesoft.cowmaster.models.EventGrup
import com.kelimesoft.cowmaster.models.NewBulkEvent
import com.kelimesoft.cowmaster.network.EventCowListResponse
import com.kelimesoft.cowmaster.network.EventListResponse
import kotlinx.serialization.json.Json
import kotlin.js.Date

class EventListVM: HaveDates, BaseVM() {
    var eventList: List<ListEvent> by mutableStateOf(listOf())
    var eventGrupList: List<EventGrup> by mutableStateOf(arrayListOf())

    var eventCowList: List<EventCow> by mutableStateOf(listOf())
    override var startDate by mutableStateOf(Date().firstDayOfMonth())
    override var endDate by mutableStateOf(startDate.lastDayOfMonth())
    var toastText by mutableStateOf("")
    var toastErrorText by mutableStateOf("")
    override var selectStart by mutableStateOf(startDate)
    override var selectEnd by mutableStateOf(endDate)
    var loadingEvents by mutableStateOf(false)




    suspend fun getEventList(refresh: Boolean = false) {
        AppData.appViewModel.showProgress = true
        try {
            if(!checkUser()){
                return
            }
            if (refresh.not() && eventList.isNotEmpty()){
                return
            }

            var firstweekday = startDate.getDay()
            var lastweekday = endDate.getDay()
            if (firstweekday == 0) firstweekday = 7
            if (lastweekday == 0) lastweekday = 7
            var sdate = startDate
            var edate = endDate
            if (firstweekday > 1) {
                //val prevMontDays = startDate.previousMonth().daysInMonth()
                sdate = startDate.addDays(-(firstweekday-1))
            }
            if (lastweekday < 7) {
                edate = endDate.addDays(7 - lastweekday)
            }

            RestApi.getEventList(start = sdate.toISOString(), end = edate.toISOString())?.let { res ->
                Json.decodeFromString<EventListResponse>(res).let { nres ->
                    if (nres.data != null) {
                        eventList = nres.data
                        createdEventsGroup()
                    }
                    else if (!nres.error.isNullOrEmpty()){
                        AppData.appViewModel.setPage(nres.error)
                    }
                    else {
                        eventList = listOf()
                        eventGrupList = listOf()
                    }
                }
            }
        }finally {
            AppData.appViewModel.showProgress = false
        }
    }

    suspend fun getEventCowList(refresh: Boolean = false) {
        try {
            if(!checkUser()){
                return
            }
            if (refresh.not() && eventCowList.isNotEmpty()){
                return
            }

            RestApi.getEventCowList()?.let { res ->
                Json.decodeFromString<EventCowListResponse>(res).let { nres ->
                    if (nres.data != null) {
                        eventCowList = nres.data
                        createdEventsGroup()
                    }
                    else if (!nres.error.isNullOrEmpty()){
                        AppData.appViewModel.setPage(nres.error)
                    }
                    else {
                        eventList = listOf()
                        eventGrupList = listOf()
                    }
                }
            }
        }finally {
            AppData.appViewModel.showProgress = false
        }
    }

    override suspend fun gotoPrevious(){
        val ndate = startDate.previousMonth().firstDayOfMonth()
        startDate = ndate
        selectStart = ndate
        endDate = ndate.lastDayOfMonth()
        selectEnd = endDate
        getEventList(true)
    }

    override suspend fun gotoNext(){
        val ndate = startDate.nextMonth().firstDayOfMonth()
        startDate = ndate
        selectStart = ndate
        endDate = ndate.lastDayOfMonth()
        selectEnd = endDate
        getEventList(true)
    }

    override fun changeSelection(d1: Date, d2: Date){
        selectStart = d1
        selectEnd = d2
        createdEventsGroup()
    }

    private fun createdEventsGroup(){
        val clist = mutableListOf<EventGrup>()
        var clrIndex = 0
        eventList.filter { Date(it.date).isBefore(selectEnd.endOfDay())
                && Date(it.date).isAfter(selectStart.startOfDay()) }.groupBy { it.cat }.forEach { grup ->
            var c = clrIndex
            if (clrIndex + 1 > 8) c = ((clrIndex + 1) % 8)
            clist.add(EventGrup(grup.key, grup.value.size, AppColors.pieColorList[c]))
            clrIndex += 1
        }
        eventGrupList = clist

    }


    suspend fun saveBulkEvents(bulkEvent: NewBulkEvent) {
        console.log("add-bulk:", bulkEvent.toString())
        loadingEvents = true
        try {
            RestApi.saveBulkEvents(bulkEvent)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { nres ->
                    if (nres.data == true){
                        val ndate = Date(bulkEvent.edate)
                        if (ndate.isAfter(startDate) && ndate.isBefore(endDate)){
                            getEventList(true)
                        }
                    }
                }
            }
        } finally {
            loadingEvents = false
        }
    }


    suspend fun deleteListEvent(event: ListEvent) {
        //loadingEvents = true
        try {
            RestApi.deleteListEvent(event)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { refresh ->
                    val index = eventList.indexOfFirst { it.id == event.id }
                    val nlist = eventList.toMutableList()
                    nlist.removeAt(index)
                    eventList = nlist.toList()
                    if(refresh.data == true){
                        AppData.summaryViewModel.bulkEventReset()
                    }
                }
            }
        }catch (e: Exception){}
    }



}


