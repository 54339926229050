package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.dateDiff
import com.varabyte.kobweb.compose.ui.graphics.Color
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.css.CSSColorValue
import kotlin.js.Date


@Serializable
data class DisposedCow(
    var hid: Long,
    var name: String = "",
    var kupe: String,
    var gender: Int,
    val birth: String,
    val breed: String,
    val sebep: String,
    val ddate: String,
    val dam: String,
    val sire: String,
    val notes: String,
    val img: String
){
    fun getSnfIcon(): String {
        val kacaylik = dateDiff(Date(ddate), Date(birth), "m");
        if (kacaylik <= 6) {
            return Icons.buz
        } else if (kacaylik <= 12) {
            return Icons.dana
        } else if (gender == 1 && kacaylik > 12) {
            return Icons.tosun
        } else if (kacaylik < 28) {
            return Icons.duve
        } else {
            return Icons.inek
        }
    }

}

data class CikanGrup(var sebep: String, var sayi: Int, var color: CSSColorValue? = null)

/*
@Serializable
data class FinItemBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val fin: FinItem
)
*/


