package com.kelimesoft.cowmaster.styles

import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.opacity
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val InputStyles = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .borderRadius(r = 10.px)
            .margin(8.px)
            .padding(8.px)
            .border(0.5.px, color = AppColors.Gray.copyf(alpha = 0.4f), style = LineStyle.Solid)
            .color(AppColors.TextColor)
            .fontSize(17.px)
            .boxShadow(0.px,0.px,null,null, null)
            .backgroundColor(AppColors.LightBlue.copyf(alpha = 0.4f))
            .transition(CSSTransition(property = "border", duration = 500.ms))
    }

    cssRule("::placeholder"){
        Modifier
            .fontSize(15.px)
            .color(AppColors.TextColor.copyf(alpha = 0.7f))
    }
}


val InputCaptchaStyle = CssStyle {
    base {
        Modifier
            .maxWidth(180.px)
            .borderRadius(r = 6.px)
            .margin(4.px)
            .padding(4.px)
            .border(0.5.px, color = AppColors.Gray.copyf(alpha = 0.4f), style = LineStyle.Solid)
            .color(AppColors.TextColor)
            .fontSize(14.px)
            .boxShadow(0.px,0.px,null,null, null)
            .backgroundColor(AppColors.LightBlue.copyf(alpha = 0.4f))
            .transition(CSSTransition(property = "border", duration = 500.ms))
    }

    cssRule("::placeholder"){
        Modifier
            .fontSize(13.px)
            .color(AppColors.TextColor.copyf(alpha = 0.7f))
    }
}

val SearchBarStyles = CssStyle {
    base {
        Modifier
            .minWidth(160.px)
            .borderRadius(r = 6.px)
            .margin(topBottom = 4.px)
            .padding(4.px)
            .border(0.5.px, color = AppColors.Gray.copyf(alpha = 0.4f), style = LineStyle.Solid)
            .color(AppColors.TextColor)
            .fontSize(14.px)
            .boxShadow(0.px,0.px,null,null, null)
            .backgroundColor(AppColors.DropMenu.copyf(alpha = 0.6f))
            .transition(CSSTransition(property = "border", duration = 500.ms))
    }

    cssRule("::placeholder"){
        Modifier
            .fontSize(12.px)
            .color(AppColors.TextColor.copyf(alpha = 0.7f))
    }
}

val CowInputStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .borderRadius(r = 4.px)
            .margin(2.px)
            .padding(8.px)
            .border(0.5.px, color = AppColors.Gray.copyf(alpha = 0.4f), style = LineStyle.Solid)
            .color(AppColors.TextColor)
            .fontSize(15.px)
            .backgroundColor(AppColors.LightBlue.copyf(alpha = 0.2f))
            .transition(CSSTransition(property = "border", duration = 500.ms))
    }

    cssRule("::placeholder"){
        Modifier
            .fontSize(12.px)
            .color(AppColors.TextColor.copyf(alpha = 0.6f))
    }
}


val TopluInputStyle = CssStyle{
    base {
        Modifier
            .fillMaxWidth()
            .borderRadius(r = 2.px)
            .margin(2.px)
            .padding(2.px)
            .border(0.5.px, color = AppColors.Gray.copyf(alpha = 0.4f), style = LineStyle.Solid)
            .color(AppColors.TextColor)
            .fontSize(13.px)
            .backgroundColor(AppColors.LightBlue.copyf(alpha = 0.2f))
            .transition(CSSTransition(property = "border", duration = 500.ms))
    }

    cssRule("::placeholder"){
        Modifier
            .fontSize(12.px)
            .color(AppColors.TextColor.copyf(alpha = 0.6f))
    }
}


val DateSelectStyle = CssStyle {
    base {
        Modifier
            //.height(34.px)
            .borderRadius(r = 6.px)
            .border(0.px)
            .outline(0.px)
            .padding(leftRight = 8.px, topBottom = 4.px)
            .fontSize(13.px)
            .backgroundColor(Colors.Gray.opacity(0.2f))
            .color(AppColors.TextColor)
            .cursor(Cursor.Pointer)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }

    hover {
        Modifier
            .backgroundColor(AppColors.LightBlue.copyf(alpha = 0.7f))
    }
}



