package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.pages.LoginPage
import com.kelimesoft.cowmaster.pages.SummaryPage
import com.kelimesoft.cowmaster.styles.addPrintStyles
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList


@Page("/")
@Composable
fun HomePage() {
    //val ctx = rememberPageContext()
    // Here, I'm assuming these params are always present, but you can
    // use `get` instead of `getValue` to handle the nullable case.
    /*
    try {
        val route = ctx.route.params.getValue("route")
        println("route: $route")
        if(route.isNotEmpty()){
            AppData.appViewModel.activeScreens = Screens.valueOf(route)
        }
    }catch (_: Error){}

     */
    LaunchedEffect(true){
        AppData.appViewModel.getUser()
        addPrintStyles()
    }

        when(AppData.appViewModel.activeScreens){
            Screens.Splash -> {
                SplashScreen()
            }
            Screens.Login -> {
                LoginPage()
            }
            Screens.Summary -> {
                SummaryPage()
            }
            Screens.Profile -> {
                MyProfile()
            }
            else -> {
                SummaryPage()
            }
        }

}

/*

@Page("/")
@Composable
fun Index() {
    AppData.appViewModel.activeScreens = Screens.Home
    Router()
}*/



fun closeDropDowns(){
    val menus = document.getElementsByClassName("app-menu")
    menus.asList().forEach { elm ->
        (elm as HTMLElement).style.display = "none"
    }
}





