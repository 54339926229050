package com.kelimesoft.cowmaster.models

import kotlinx.serialization.Serializable


data class CowPeriodStat(
    var ilktrh: String,
    var sontarih: String,
    val sagsut: Double,
    val gunluk: Double,
    val sagmalort: Double,
    val sagmalsay: Double
)




@Serializable
data class TotalMilk(
    var id: Long = 0,
    var milk: Double? = null,
    var buz: Double? = null,
    var cows: Int? = null,
    var mdate: String? = null,
    var period: MilkTime? = null,
    var price: Double? = 11.25
)

@Serializable
data class CowMilkItem(
    var id: Long = 0,
    var hid: Long = 0,
    var kupe: String = "",
    var name: String = "",
    var milk: Double? = 0.0,
    var mdate: String? = null,
    var period: MilkTime? = null
)

@Serializable
data class DairyMilkData(
    var sum: TotalPeriodSum? = null,
    var list: List<TotalMilk>? = null
)


@Serializable
data class TotalPeriodSum(
    var milk: Double = 0.0,
    var buz: Double = 0.0,
    var sagmal: Double = 0.0,
    var gelir: Double = 0.0,
    var perday: Double = 0.0,
    var percow: Double = 0.0,
)

@Serializable
data class CowMilkData(
    var sum: CowPeridSum? = null,
    var list: List<CowMilkItem>? = null
)

@Serializable
data class CowPeridSum(
    var milk: Double = 0.0,
    var perday: Double = 0.0,
    var percow: Double = 0.0,
    var days: Double = 0.0
)




