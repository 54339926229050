package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.models.MilkPeriod
import com.kelimesoft.cowmaster.models.MilkItem
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowDown
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowUp
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date


@Composable
fun MilkSummary(milk: MilkPeriod) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(leftRight = 6.px),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top,
    ) {

            MilkInfo(milk)
            MilkRow(Icons.sagmal, "summilk_sutmik".tr(), milk.sutmik)
            MilkRow(Icons.biberon, "summilk_buzmik".tr(), milk.buzmik)
            MilkRow(Icons.gugum, "summilk_kalan".tr(), milk.kalan)
            Box(
                modifier = Modifier.fillMaxWidth(96.percent)
                    .height(1.px)
                    .margin(topBottom = 4.px)
                    .backgroundColor(Colors.Gray)
            ) { }

            MilkRow(null, "summilk_cowsut".tr(), milk.sagmalort)
            MilkRow(null, "summilk_dairy".tr(), milk.gunluk)
            MilkRow(null, "summilk_cowsay".tr(), milk.sagmal)
            MilkRow(null, "summilk_gelir".tr(), milk.gelir)
        }

}

@Composable
fun MilkInfo(milk: MilkPeriod) {
    Row(
        modifier = Modifier
            .padding(leftRight = 8.px)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(left = 4.px)
                .textAlign(textAlign = TextAlign.Start)
                .fontSize(13.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {

            Text("summilk_dates".tr()
                .Sprintf("${Date(milk.start).toLocaleDateString()} - ${Date(milk.end).toLocaleDateString()}"))


        }

        P(
            attrs = Modifier
                .fillMaxWidth(37.percent)
                .margin(right = 4.px)
                .textAlign(textAlign = TextAlign.End)
                .fontSize(13.px)
                .color(if (milk.dim < 150 || milk.dim > 200) Colors.Red else AppColors.PrimaryDark)
                .fontWeight(FontWeight.Bold)
                .toAttrs()
        ) {
            Text("summilk_sgg".tr().Sprintf(milk.dim))
        }
    }

}

@Composable
fun MilkRow(icon: String?, title: String, sut: MilkItem) {
    Row(
        modifier = Modifier
            .padding(leftRight = 8.px)
            .margin(topBottom = 6.px)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        if (icon != null) {
            Image(
                modifier = Modifier.size(22.px),
                src = icon
            )
        }
        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(left = 2.px)
                .textAlign(textAlign = TextAlign.Start)
                .fontSize(13.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {
            Text(title)
        }

        P(
            attrs = Modifier
                .width(100.px)
                .margin(right = 2.px)
                .textAlign(textAlign = TextAlign.End)
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .fontWeight(FontWeight.Medium)
                .toAttrs()
        ) {
            Text(sut.mik.round())
        }

    }
}