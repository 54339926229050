package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.tr

enum class NavButtons(
    val title: String,
    val icon: String,
    val destination: String
) {
    Summary("nav_sum".tr(), Icons.dairy, Routes.Summary),
    CowList("nav_list".tr(), Icons.cows, Routes.CowList),
    Milk("nav_milk".tr(), Icons.milk, Routes.Milk),
    Finance("nav_fin".tr(), Icons.finance, Routes.Finance),
    //Notifs("Bildirimler", Icons.cows, "/bildirim"),
}