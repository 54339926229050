package com.kelimesoft.cowmaster.pages.milk

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.kelimesoft.cowmaster.components.MySpacer
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Switch
import com.varabyte.kobweb.silk.components.forms.SwitchSize
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Label

@Composable
fun MilkBar(milkShow: MutableState<MilkTypeScreen>) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .color(AppColors.TextColor)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .backgroundColor(AppColors.BarColor)
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.End
    ) {

        Switch(
            modifier = Modifier.id("dairy-milk-select"),
            checked = milkShow.value == MilkTypeScreen.Dairy,
            onCheckedChange = {
                milkShow.value = if(it) MilkTypeScreen.Dairy else MilkTypeScreen.Cow
            }
        )

        Label(forId = "dairy-milk-select") {
            SpanText("milkview_bardairy".tr(),  modifier = Modifier.margin(left = 4.px))
        }

        MySpacer(Modifier.width(16.px))

        Switch(
            modifier = Modifier.id("cow-milk-select"),
            checked = milkShow.value == MilkTypeScreen.Cow,
            onCheckedChange = {
                milkShow.value = if(it) MilkTypeScreen.Cow else MilkTypeScreen.Dairy
            }
        )

        Label(forId = "cow-milk-select") {
            SpanText("milkview_barbirey".tr(), modifier = Modifier.margin(left = 4.px))
        }


    }
}

@Composable
fun DairyMilkListBar(milkShow: MutableState<MilkGrouping>) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .margin(topBottom = 2.px)
            .color(AppColors.TextColor)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .borderRadius(r = 6.px)
            .backgroundColor(Colors.Gray.opacity(0.1f)),
        verticalAlignment = Alignment.CenterVertically
    ) {

        SpanText("milkview_dairylist".tr(), modifier = Modifier
            .color(AppColors.TextColor).fontSize(14.px).fontWeight(FontWeight.SemiBold))
        Spacer()
        Switch(
            modifier = Modifier.id("permilk-select"),
            size = SwitchSize.SM,
            checked = milkShow.value == MilkGrouping.Daily,
            onCheckedChange = {
                milkShow.value = if(it) MilkGrouping.Daily else MilkGrouping.Permilking
            }
        )

        Label(forId = "permilk-select") {
            SpanText("milkview_persagim".tr(),  modifier = Modifier
                .fontSize(14.px).margin(left = 4.px))
        }

        MySpacer(Modifier.width(16.px))

        Switch(
            modifier = Modifier.id("daily-select"),
            size = SwitchSize.SM,
            checked = milkShow.value == MilkGrouping.Permilking,
            onCheckedChange = {
                milkShow.value = if(it) MilkGrouping.Permilking else MilkGrouping.Daily
            }
        )

        Label(forId = "daily-select") {
            SpanText("milkview_daily".tr(), modifier = Modifier
                .fontSize(14.px)
                .margin(left = 4.px))
        }


    }
}

@Composable
fun CowMilkListBar(milkShow: MutableState<CowMilkGroup>) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .margin(topBottom = 2.px)
            .color(AppColors.TextColor)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .borderRadius(r = 6.px)
            .backgroundColor(Colors.Gray.opacity(0.1f)),
        verticalAlignment = Alignment.CenterVertically
    ) {

        SpanText("milkview_bireylist".tr(), modifier = Modifier
            .color(AppColors.TextColor).fontSize(14.px).fontWeight(FontWeight.SemiBold))
        Spacer()
        Switch(
            modifier = Modifier.id("permilk-select"),
            checked = milkShow.value == CowMilkGroup.Milking,
            size = SwitchSize.SM,
            onCheckedChange = {
                milkShow.value = if(it) CowMilkGroup.Milking else CowMilkGroup.Day
            }
        )
        Label(forId = "permilk-select") {
            SpanText("milkview_persagim".tr(),  modifier = Modifier
                .fontSize(14.px)
                .margin(left = 4.px))
        }

        MySpacer(Modifier.width(16.px))

        Switch(
            modifier = Modifier.id("daily-select"),
            size = SwitchSize.SM,
            checked = milkShow.value == CowMilkGroup.Day,
            onCheckedChange = {
                milkShow.value = if(it) CowMilkGroup.Day else CowMilkGroup.Milking
            }
        )

        Label(forId = "daily-select") {
            SpanText("milkview_daily".tr(), modifier = Modifier
                .fontSize(14.px).margin(left = 4.px))
        }

        MySpacer(Modifier.width(16.px))

        Switch(
            modifier = Modifier.id("cowmilk-select"),
            checked = milkShow.value == CowMilkGroup.Cow,
            size = SwitchSize.SM,
            onCheckedChange = {
                milkShow.value = if(it) CowMilkGroup.Cow else CowMilkGroup.Milking
            }
        )

        Label(forId = "cowmilk-select") {
            SpanText("milkview_sagmal".tr(), modifier = Modifier
                .fontSize(14.px).margin(left = 4.px))
        }


    }
}
