package com.kelimesoft.cowmaster.utils

import kotlinx.browser.window

object AppStorage {
    private val localStorage = window.localStorage
    val sessionId = "session-id"
    val cowOrder = "cow-order"
    val weaningDays = "weaning-days"
    val addWeaningNotif = "add-weaning-days"
    val notifShowAll = "motif-showall"
    val milkPrice = "milk-price"

    fun getBool(key: String, default: Boolean = false): Boolean{
        val str = localStorage.getItem(key) ?: return default
        return  str == "1"
    }

    fun getString(key: String, default: String = ""): String{
        return  localStorage.getItem(key) ?: return default
    }

    fun getInt(key: String, default: Int = 0): Int{
        val str = localStorage.getItem(key) ?: return default
        return  str.toInt()
    }
    fun setInt(key: String, value: Int){
        localStorage.setItem(key, value.toString())
    }

    fun setBool(key: String, value: Boolean){
        localStorage.setItem(key, if (value) "1" else "0")
    }
    fun setString(key: String, value: String){
        localStorage.setItem(key, value)
    }

    fun getDouble(key: String, default: Double = 0.0): Double{
        val str = localStorage.getItem(key) ?: return default
        return  str.toDouble()
    }
    fun setDouble(key: String, value: Double){
        localStorage.setItem(key, value.toString())
    }



    fun remove(key: String){
        localStorage.removeItem(key)
    }


}