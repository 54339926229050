package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.RowButton
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.summary.EventRow
import com.kelimesoft.cowmaster.styles.DetailInfoDataStyle
import com.kelimesoft.cowmaster.styles.DetailInfoTitleStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.CowListViewModel
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun CowEventList(events: List<CowEvent>?, onDurum: () -> Unit) {
    val scope = rememberCoroutineScope()
    val detailVM = AppData.cowListVM.cowDetailVM
    fun deleteEvent(event: CowEvent){
        scope.launch {
            detailVM.deleteEvent(event.id, event.cat)
        }
    }

    CowDetayCard(
        title = "detayevt_title".tr(), button =
        {
            DetailsButton("shared_add".tr(), onDurum)
        },

        ){
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .maxHeight(300.px)
                .overflow { y(Overflow.Auto) }
                .overflow { x(Overflow.Hidden) }
        ) {
            events?.forEach { event ->
                CowEventRow(event){
                    deleteEvent(event)
                }
            }

            if (events.isNullOrEmpty()){
                Span(attrs = Modifier
                    .fillMaxWidth()
                    .color(AppColors.TextColor.copyf(alpha = 0.8f))
                    .margin(2.px)
                    .fontSize(15.px)
                    .fontWeight(FontWeight.Medium)
                    .toAttrs()) {
                    Text("detayevt_nodrm".tr())
                }
            }
        }


    }

}


@Composable
private fun CowEventRow(event: CowEvent, onDelete: () ->Unit) {
    var showDelete by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier.fillMaxWidth()
            .onMouseOver {
                showDelete = true
            }
            .onMouseLeave {
                showDelete = false
            },
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .padding(2.px),
            contentAlignment = Alignment.TopEnd
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Image(
                    modifier = Modifier
                        .size(22.px)
                        .margin(right = 6.px)
                    ,
                    src = Values.imgPath2 + event.icon
                )
                Column(modifier = Modifier.fillMaxWidth()
                    .color(AppColors.TextColor)) {
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Start) {
                            if (event.title.isNotEmpty()){
                                Span(attrs = Modifier
                                    .margin(1.px)
                                    .fontSize(15.px)
                                    .color(AppColors.TextColor)
                                    .fontWeight(FontWeight.Medium)
                                    .toAttrs()) {
                                    Text(event.title)
                                }
                            }

                            if (event.info.isNotEmpty()){
                                Span(attrs = Modifier
                                    .margin(1.px)
                                    .fontSize(13.px)
                                    .color(AppColors.TextColor.copyf(alpha = 0.8f))
                                    .fontWeight(FontWeight.Normal)
                                    .padding(left = 4.px)

                                    .toAttrs()) {
                                    Text("(${event.info})")
                                }
                            }
                        }

                        if (event.not.isNotEmpty()){
                            Span(attrs = Modifier
                                .fillMaxWidth()
                                .fontSize(13.px)
                                .margin(1.px)
                                .color(AppColors.TextColor.copyf(alpha = 0.8f))
                                .fontWeight(FontWeight.Normal)
                                .toAttrs()) {
                                Text(event.not)
                            }
                        }
                }
            }

            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.End
            ) {
                if (showDelete){
                    RowButton{
                        FaTrash(modifier = Modifier.color(AppColors.DarkRed.opacity(0.9f))
                            .onClick {
                                onDelete()
                            },
                            size = IconSize.SM
                        )
                    }
                }

                Column(horizontalAlignment = Alignment.End) {
                    P(
                        attrs = Modifier
                            .margin(1.px)
                            .textAlign(textAlign = TextAlign.End)
                            .color(AppColors.TextColor)
                            .fontSize(13.px)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        Text(Date(event.date).toLocaleDateString())
                    }
                    P(
                        attrs = Modifier
                            .margin(1.px)
                            .textAlign(textAlign = TextAlign.End)
                            .fontSize(12.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Normal)
                            .toAttrs()
                    ) {
                        Text(Date(event.date).toLocaleTimeString())
                    }
                }
            }



        }
        VDivider()
    }

}
