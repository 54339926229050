package com.kelimesoft.cowmaster.network

import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.cowdetails.DisSebep
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class BasicRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
)

@Serializable
data class BasicEmailRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val email: String = AppData.cowUser?.email ?: "",
    val sid: Long = AppData.cowUser?.userSid ?: 0,
)

@Serializable
data class ForgotPassRequest(
    val email: String,
    val lang: String
)

@Serializable
data class ResetPassRequest(
    val email: String,
    val reset: String,
    val new: String
)


@Serializable
data class SummaryRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val email: String = AppData.cowUser?.email ?: "",
    val yetki: UserYetki = AppData.cowUser?.yetki!!,
    var sum: SumItems
)

@Serializable
data class CowListRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val list: String,
    val page: Int,
    val order: String
)

@Serializable
data class CowInfoRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val hid: Long
)


@Serializable
data class DelCowImgRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    var hid: Long,
    var image: String,
    var fav: Int,
)

@Serializable
data class AddCowImgRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    var hid: Long,
    var image: String,
)

@Serializable
data class CowCalvesRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    var kupe: String
)

@Serializable
data class UpdateCowHerdRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val hid: Long,
    val herd: Long
)

@Serializable
data class NewCowListBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val cowlist: List<NewListCow>,
    val weaning: Int = 0,
)

@Serializable
data class NewCowAddBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val cow: NewListCow
)

@Serializable
data class EditedCowAddBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val cow: EditedCow
)

@Serializable
data class DelCowEventtRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val hid: Long,
    val eid: Long,
    val cat: String,
    val latest: Boolean
)

@Serializable
data class DelNotifRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val nid: Long
)

@Serializable
data class ToggleNotifRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val nid: Long,
    val done: Int
)


@Serializable
data class DisposeCowRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val hid: Long,
    val sebep: DisSebep,
    val notes: String,
    val date: String
)

@Serializable
data class DeleteCowRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val hid: Long,
    val milk: Int = 0
)

@Serializable
data class SaveHerdRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val herd: HerdItem,
)

@Serializable
data class DeleteHerdRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val herd: Long,
)

@Serializable
data class NotifListRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val email: String = AppData.cowUser?.email ?: "",
    val hr: Boolean = AppData.cowUser?.isAdmin ?: true || (AppData.cowUser?.yetki?.hr ?: 1) == 1,
    val start: String,
    val end: String

)


@Serializable
data class EventListRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val email: String = AppData.cowUser?.email ?: "",
    val start: String,
    val end: String
)

@Serializable
data class DeleteEventRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val hid: Long,
    val eid: Long,
    val cat: String
)

@Serializable
data class DisposedListRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val email: String = AppData.cowUser?.email ?: "",
    val year: String,
)

@Serializable
data class UndoCowDelRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val hid: Long
)

@Serializable
data class MilkDataRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val email: String = AppData.cowUser?.email ?: "",
    val units: String = "birim_metric",
    val start: String,
    val end: String
)

@Serializable
data class DeleteMilkRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val mid: Long
)







