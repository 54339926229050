package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.utils.AppStorage
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun WeaningSetting() {
    var addWeaningNotif: Boolean by remember { mutableStateOf(AppStorage.getBool(AppStorage.addWeaningNotif, true)) }
    var weaningDays: Int by remember { mutableStateOf(AppStorage.getInt(AppStorage.weaningDays, 60)) }

    Row(verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.End
    ){
        CheckboxInput(
            attrs = Modifier
                .size(18.px)
                .id("wean-check")
                .toAttrs(){
                    onChange {
                        AppStorage.setBool(AppStorage.addWeaningNotif, it.value)
                        addWeaningNotif = it.value
                    }
                },
            checked = addWeaningNotif
        )

        Label(
            attrs = Modifier
                .fontSize(12.px)
                .color(AppColors.DarkRed.copyf(alpha = 0.8f))
                .toAttrs(),
            forId = "wean-check"){
            Text("wean_text".tr())
            Span(attrs = Modifier
                .fontWeight(FontWeight.SemiBold)
                .toAttrs()) {
                Text("$weaningDays")
            }
            Text("wean_days".tr())
        }

        Button(attrs = Modifier
            .margin(leftRight = 4.px)
            .onClick {
                it.preventDefault()
                if (weaningDays > 1){
                    weaningDays -= 1
                    AppStorage.setInt(AppStorage.weaningDays, weaningDays)
                }


            }
            .toAttrs()) {
            Text("-")
        }

        Button(attrs = Modifier
            .margin(left = 4.px, right = 2.px)
            .onClick {
                it.preventDefault()
                if (weaningDays < 180){
                    weaningDays += 1
                    AppStorage.setInt(AppStorage.weaningDays, weaningDays)
                }
            }
            .toAttrs()) {
            Text("+")
        }
    }
}

