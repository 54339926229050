package com.kelimesoft.cowmaster.pages.finance


import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogView
import com.kelimesoft.cowmaster.components.FaEdit
import com.kelimesoft.cowmaster.components.RowButton
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.FinItem
import com.kelimesoft.cowmaster.models.FinType
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.FinanceVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

private val today = Date()

@Composable
fun GelirlerTablo(modifier: Modifier = Modifier, financeVM: FinanceVM) {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    var showAddGelir by remember { mutableStateOf(false) }
    var editFinItem: FinItem? = null

    Box(modifier = modifier
            .fillMaxHeight()
    ) {
        Column(modifier = Modifier.fillMaxSize()
            .margin(top = 3.px)
            .overflow(Overflow.Hidden)
            .backgroundColor(Colors.White)
        ) {
            FinTitleBar(FinType.Gelir, breakpoint) {
                editFinItem = null
                showAddGelir = true
            }

            //CowSearch(search)

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .overflow { y(Overflow.Auto) }
                    .overflow { x(Overflow.Hidden) }

            ) {
                financeVM.gelirList.forEach { gelir ->
                    FinItemRow(gelir, onEdit = {
                        editFinItem = gelir
                        showAddGelir = true
                    }, onDelete = {
                        scope.launch {
                            financeVM.delFinItem(gelir)
                        }
                    })
                }


            }
        }

        if (showAddGelir) {
            DialogView {
                AddFinItem(scope, FinType.Gelir, financeVM, editFinItem){
                    showAddGelir = false
                }
            }
        }
    }


}


@Composable
fun FinItemRow(fin: FinItem, onEdit: () -> Unit, onDelete: () -> Unit) {
    var showButtons by remember { mutableStateOf(false) }

    fun paidColor(): Color.Rgb {
        if (fin.paid == 1) return AppColors.PrimaryDark
        val pdate = fin.pdate
        if (pdate != null) {
            val pDate = Date(pdate)
            // println("today-date-fark: ${today.sureFark(buyuktrh = pdate, olcu = "d")}")
            if (Date(pdate).startOfDay().isBefore(today.startOfDay())) {
                return AppColors.Red
            } else if (dateDiff(pDate, today, "d") < 7) {
                return AppColors.NotifNow
            }
        }
        return AppColors.Gray
    }

    Box(
        contentAlignment = Alignment.BottomEnd,
        modifier = Modifier.fillMaxWidth()
            .onMouseOver {
                showButtons = true
            }
            .onMouseLeave {
                showButtons = false
            }
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.fillMaxWidth()

        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 4.px, topBottom = 4.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {

                Column(modifier = Modifier
                    .fillMaxWidth()) {

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        FaReceipt(size = IconSize.XS, modifier = Modifier.color(AppColors.Gray))
                        fin.gdate?.let { Date(it).toLocaleDateString() }?.let {
                            Span(
                                attrs = Modifier
                                    .margin(left = 2.px, right = 6.px)
                                    .fontSize(12.px)
                                    .color(AppColors.TextColor.opacity(0.8f))
                                    .toAttrs()
                            ){ Text(it) }
                        }

                    }

                    if (fin.notes.isNotEmpty()) {
                        Span(
                            attrs = Modifier
                                .fontSize(14.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.Medium)
                                .toAttrs()
                        ) {
                            Text(fin.notes)
                        }
                        Span(
                            attrs = Modifier
                                .fontSize(13.px)
                                .color(AppColors.TextColor.opacity(0.7f))
                                .toAttrs()
                        ) {
                            Text(fin.cat)
                        }
                    } else {
                        Span(
                            attrs = Modifier
                                .fontSize(14.px)
                                .padding(2.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.Medium)
                                .toAttrs()
                        ) {
                            Text(fin.cat)
                        }
                    }

                }
                Spacer()

                Column(horizontalAlignment = Alignment.End) {
                    Span(
                        attrs = Modifier
                            .textAlign(TextAlign.End)
                            .color(paidColor())
                            .fontWeight(FontWeight.Medium)
                            .fontSize(11.px)
                            .toAttrs()
                    ) {
                        Text(if(fin.paid == 1) "Paid on: " else "Payment Date: ")
                        fin.pdate?.let { Text(Date(it).toLocaleDateString()) }
                    }

                    Span(
                        attrs = Modifier
                            .textAlign(TextAlign.End)
                            .minWidth(100.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.SemiBold)
                            .fontSize(14.px)
                            .toAttrs()
                    ) {
                        Text(fin.tutar.round(2))
                    }
                }
            }

            VDivider()
        }
        if (showButtons) {
            Row(
                modifier = Modifier
                    .backgroundColor(Colors.Gray.opacity(0.1f))
                    .borderRadius(r = 4.px)
                    .padding(1.px)
                    .margin(right = 70.px),
                horizontalArrangement = Arrangement.Start
            ) {
                RowButton {
                    FaEdit(
                        modifier = Modifier
                            .color(AppColors.GrayDark.opacity(0.9f))
                            .onClick {
                                onEdit()
                            },
                        size = IconSize.SM,

                        )
                }
                RowButton {
                    FaTrash(
                        modifier = Modifier.color(AppColors.DarkRed.opacity(0.9f))
                            .onClick {
                                onDelete()
                            },
                        size = IconSize.SM
                    )
                }

            }
        }


    }




}