package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors

import com.kelimesoft.cowmaster.styles.DetailButtonStyle
import com.kelimesoft.cowmaster.styles.DetayCardstyle
import com.kelimesoft.cowmaster.styles.StaggeredItemStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.toModifier

import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun CowDetayCard(
    modifier: Modifier = Modifier,
    title: String,
    button: @Composable() (() -> Unit?)?,
    content: @Composable ColumnScope.() -> Unit) {
    Column(
        modifier = StaggeredItemStyle.toModifier()
            .then(DetayCardstyle.toModifier()
                .then(modifier)
            )
        //.maxWidth(420.px)
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(modifier = Modifier
            .fillMaxWidth()
            .borderRadius(r = 6.px)
            .padding(topBottom = 3.px, leftRight = 6.px)
            .margin(bottom = 4.px)
            .backgroundColor(Colors.Gray.copyf(alpha = 0.1f))
            .boxShadow(1.px, 1.px, color = Colors.Gray.copyf(alpha = 0.1f)),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(attrs = Modifier
                .fillMaxWidth()
                .fontSize(14.px)
                .fontWeight(FontWeight.Medium)
                .color(AppColors.DetailBlue)
                .toAttrs()) {
                Text(title)
            }
            Spacer()
            if (button != null) {
                button()
            }
        }

        content()
    }
}


@Composable
fun DetailsButton(title: String, onClick: () -> Unit) {
    Button(
        attrs = DetailButtonStyle.toModifier()
            .whiteSpace(WhiteSpace.NoWrap)
            .onClick {
                onClick()
            }
            .fontSize(13.px)
            .height(23.px)
            .backgroundColor(AppColors.DetailBlue.copyf(alpha = 0.8f))
            .color(Colors.White)
            .toAttrs()
    ) {
        Text(title)
    }
}