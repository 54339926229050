package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

@Composable
fun MySpacer(modifier: Modifier = Modifier) {
    Div(attrs = Modifier.width(0.px).height(0.px)
        .then(modifier)
        .toAttrs()
    ) {  }
}