package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.network.FinListResponse
import com.kelimesoft.cowmaster.utils.dateToDbLongStr
import com.kelimesoft.cowmaster.utils.firstDayOfMonth
import com.kelimesoft.cowmaster.utils.lastDayOfMonth
import kotlinx.serialization.json.Json
import kotlin.js.Date

class FinanceVM: BaseVM() {
    var gelirList: List<FinItem> by mutableStateOf(listOf())
    var giderList: List<FinItem> by mutableStateOf(listOf())
    var startDate by mutableStateOf(Date().firstDayOfMonth())
    var endDate by mutableStateOf(startDate.lastDayOfMonth())

    var gelirToplam by  mutableStateOf(0.0)
    var giderToplam by  mutableStateOf(0.0)


    suspend fun getFinanceList(refresh: Boolean = false, type: FinType? = null) {
        AppData.appViewModel.showProgress = true
        try {
            if(!checkUser()){
                return
            }
            if (refresh.not() && (gelirList.isNotEmpty() && giderList.isNotEmpty())){
                return
            }
            RestApi.getFinList(type = type, start = startDate.dateToDbLongStr(), end = endDate.dateToDbLongStr())?.let { res ->
                Json.decodeFromString<FinListResponse>(res).let { fres ->
                    console.log(fres)
                    if (fres.data != null) {
                        handleResData(fres.data, type)
                    }
                    else if (!fres.error.isNullOrEmpty()){
                        AppData.appViewModel.setPage(fres.error)
                    }
                    else {
                        gelirList = listOf()
                        giderList = listOf()
                        gelirToplam = 0.0
                        giderToplam = 0.0
                    }
                }
            }
        }finally {
            AppData.appViewModel.showProgress = false
        }
    }


    private fun handleResData(finRes: FinTypeList, type: FinType?){
        if (type == null){
            gelirList = finRes.gelir ?: listOf()
            giderList = finRes.gider ?: listOf()
            gelirToplam = gelirList.fold(0.0) { total, fin -> total + fin.tutar }
            giderToplam = giderList.fold(0.0) { total, fin -> total + fin.tutar }
        }else if (type == FinType.Gider){
            giderList = finRes.gider ?: listOf()
            giderToplam = giderList.fold(0.0) { total, fin -> total + fin.tutar }
        }else if (type == FinType.Gelir){
            gelirList = finRes.gelir ?: listOf()
            gelirToplam = gelirList.fold(0.0) { total, fin -> total + fin.tutar }
        }


    }


    suspend fun addNewFinItems(type: FinType, list: List<FinItem>) {
        try {
            RestApi.addFinItems(type, list)?.let { res ->
                Json.decodeFromString<LongResponse>(res).let { nres ->
                    nres.data?.let {
                        getFinanceList(true, type)
                        AppData.summaryViewModel.finChangedReset()
                    }
                }
            }
        }catch (_: Exception){}
    }

    suspend fun updateFinItem(finItem: FinItem) {
        try {
            RestApi.updateFinItem(finItem)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { nres ->
                    nres.data?.let {
                        getFinanceList(true, finItem.type)
                        AppData.summaryViewModel.finChangedReset()
                    }

                }
            }
        }catch (_: Exception){}
    }

    suspend fun delFinItem(finItem: FinItem) {
        try {
            RestApi.delFinItem(finItem)?.let { res ->
                Json.decodeFromString<BoolResponse>(res).let { nres ->
                    nres.data?.let {
                        getFinanceList(true, finItem.type)
                        AppData.summaryViewModel.finChangedReset()
                    }

                }
            }
        }catch (_: Exception){}
    }



    /*

    suspend fun gotoPrevious(){
        val ndate = startDate.previousMonth().firstDayOfMonth()
        startDate = ndate
        selectStart = ndate
        endDate = ndate.lastDayOfMonth()
        selectEnd = endDate
        getNotifList(true)
    }

    suspend fun gotoNext(){
        val ndate = startDate.nextMonth().firstDayOfMonth()
        startDate = ndate
        selectStart = ndate
        endDate = ndate.lastDayOfMonth()
        selectEnd = endDate
        getNotifList(true)
    }

    fun changeSelection(d1: Date, d2: Date){
        selectStart = d1
        selectEnd = d2
    }


    suspend fun updateNotif(nid: Long, tmm: Int){
        loadingNotifs = true
        try {
            RestApi.updateNotif(nid, tmm)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { apires ->
                    if(apires.data != null){
                        val index = notifList.indexOfFirst { it.id == nid }
                        val oldNotif = notifList[index]
                        val newNotif = Notif(nid, oldNotif.hid,
                            oldNotif.kupe, oldNotif.isim, oldNotif.eid, oldNotif.ecat,
                            oldNotif.etrh, oldNotif.title, oldNotif.text,
                            oldNotif.bastrh, oldNotif.bittrh, tmm)
                        val nlist = notifList.toMutableList()
                        nlist[index] = newNotif
                        notifList = nlist.toList()

                    }
                }
            }
        } finally {
            loadingNotifs = false
        }
    }

    suspend fun deleteNotif(nid: Long) {
        loadingNotifs = true
        try {
            RestApi.delNotif(nid)?.let { res ->
                Json.decodeFromString<ApiResponse>(res).let { apires ->
                    if(apires.data != null){
                        val index = notifList.indexOfFirst { it.id == nid }
                        val nlist = notifList.toMutableList()
                        nlist.removeAt(index)
                        notifList = nlist.toList()
                    }
                }
            }
        } finally {
            loadingNotifs = true
        }
    }


    */


}


