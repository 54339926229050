package com.kelimesoft.cowmaster.models

import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.ui.graphics.Color


object AppColors {
    val Background get() = Color.rgba(220, 224, 228, 1.0f)//rgba(200, 214, 229,1.0)
    val TextColor get() = Color.rgba(34, 47, 62, 0.8f)
    val GrayDark get() = Color.rgba(76, 82, 90, 1.0f)
    val Gray get() = Color.rgba(94, 102, 110, 1.0f)
    //rgba(99, 110, 114,1.0)
    val LightBlue get() = Color.rgba(200, 214, 229, 1.0f)
    val PrimaryDark get() = Color.rgba(15, 185, 177, 1.0f)
    val Primary get() = Color.rgba(43, 203, 186, 1.0f)
    val BarColor get() = Color.rgba(223, 228, 234, 1.0f)
    val DropMenu get() = Color.rgba(245, 246, 250, 1.0f)
    val Red get() = Color.rgba(255, 107, 107, 1.0f)
    val DarkRed get() = Color.rgba(229, 80, 57, 1.0f)
    val DetailBlue get() = Color.rgba(0, 151, 230, 1.0f)


    val NumRed get() = Color.rgba(255, 107, 107, 1.0f)
    val NumBlue get() = Color.rgba(10, 189, 227, 1.0f)
    val NumGreen get() = Color.rgba(0, 210, 211, 1.0f)
    val NumOrange get() = Color.rgba(255, 159, 67, 1.0f)
    val NumYellow get() = Color.rgba(255, 177, 66, 1.0f)
    val NumPurple get() = Color.rgba(112, 111, 211, 1.0f)
    val NumGreen2 get() = Color.rgba(51, 217, 178, 1.0f)

    val NotifNow get() = Color.rgba(255, 159, 67, 1.0f)
    val NotifLater get() = Color.rgba(87, 101, 116, 1.0f)
    val NotifPassed get() = Color.rgba(255, 107, 107, 1.0f)

    var GradientGray = linearGradient(dir = LinearGradient.Direction.ToBottomRight, from = GrayDark, to = Gray)
    var GradientPrimary = linearGradient(dir = LinearGradient.Direction.ToBottomLeft, from = Primary, to = PrimaryDark)

    val herdColorList = listOf(
        Color.rgba(47, 79, 79, 1.0f), Color.rgba(128, 128, 128, 1.0f),
        Color.rgba(106, 90, 205, 1.0f), Color.rgba(255, 0, 255, 1.0f),
        Color.rgba(65, 105, 225, 1.0f), Color.rgba(0, 255, 255, 1.0f),
        Color.rgba(0, 139, 139, 1.0f), Color.rgba(64, 224, 208, 1.0f),
        Color.rgba(0, 255, 255, 1.0f), Color.rgba(0, 128, 0, 1.0f),
        Color.rgba(46, 139, 87, 1.0f), Color.rgba(255, 152, 0, 1.0f),
        Color.rgba(102, 205, 170, 1.0f), Color.rgba(0, 255, 0, 1.0f),
        Color.rgba(128, 0, 0, 1.0f), Color.rgba(165, 42, 42, 1.0f),
        Color.rgba(139, 69, 19, 1.0f), Color.rgba(255, 215, 0, 1.0f),
        Color.rgba(240, 230, 140, 1.0f), Color.rgba(255, 165, 0, 1.0f),
        Color.rgba(255, 99, 71, 1.0f), Color.rgba(220, 20, 60, 1.0f),
        Color.rgba(199, 21, 133, 1.0f), Color.rgba(255, 105, 180, 1.0f)
    )


    val pieColorList = listOf(
        Color.rgba(85, 239, 196, 1f), Color.rgba(255, 118, 117, 1.0f),
        Color.rgba(129, 236, 236, 1f), Color.rgba(116, 185, 255, 1.0f),
        Color.rgba(250, 177, 160, 1f), Color.rgba(162, 155, 254, 1.0f),
        Color.rgba(255, 234, 167, 1f), Color.rgba(253, 121, 168, 1.0f)
    )
    val pieColorHexList = listOf(
        "#55efc4", "#ff7675", "#81ecec", "#74b9ff", "#fab1a0", "#a29bfe", "#ffeaa7", "#fd79a8"
    )
    /*
    Color(0xff55efc4), Color(0xffff7675),Color(0xff81ecec), Color(0xff74b9ff),
    Color(0xfffab1a0), Color(0xffa29bfe), Color(0xffffeaa7), Color(0xfffd79a8)
     */

}

fun Color.Rgb.opacity(float: Float): Color {
    return this.copyf(alpha = float)
}
fun String.hexToRGBA(): Color.Rgb {
        val hex = this.substring(1) // Remove the '#' from the hex color
        val intValue = hex.toInt(16) // Convert the hex string to an integer
        val red = (intValue shr 16) and 0xFF
        val green = (intValue shr 8) and 0xFF
        val blue = intValue and 0xFF
        val alpha = ((intValue shr 24) and 0xFF) / 255.0f
        val rgbaColor = Color.rgba(red, green, blue, alpha)
        return rgbaColor
}