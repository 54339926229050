package com.kelimesoft.cowmaster.models

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.Date


@Serializable
data class FinItem(
    var id: Long = 0L,
    var gdate: String? = null,
    var type: FinType = FinType.Gider,
    var cat: String = "",
    var tutar: Double = 0.0,
    var notes: String = "",
    var method: PayMethod = PayMethod.Cash,
    var pdate: String? = null,
    var paid: Int = 0
)

@Serializable
enum class FinType {
    @SerialName("gelir")
    Gelir,
    @SerialName("gider")
    Gider
}

@Serializable
enum class PayMethod(var text: String) {
    @SerialName("cash")
    Cash("FinPay_Pesin".tr()),
    @SerialName("card")
    Card("FinPay_Card".tr()),
    @SerialName("vade")
    Vade("FinPay_Vade".tr()),
    @SerialName("taksit")
    Taksit("FinPay_Taksit".tr())
}


data class FinTaksit(
    val pdate: Date,
    val tutar: Double
){
    var pDate by  mutableStateOf(pdate)
    var tTutar by  mutableStateOf(tutar)
}



@Serializable
data class FinItemBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val fin: FinItem
)

@Serializable
data class AddFinItemsRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    var type: FinType,
    val list: List<FinItem>
)

@Serializable
data class FinListRequest(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    var type: FinType? = null,
    val start: String,
    val end: String,
)



