package com.kelimesoft.cowmaster.pages.finance

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogView
import com.kelimesoft.cowmaster.models.FinItem
import com.kelimesoft.cowmaster.models.FinType
import com.kelimesoft.cowmaster.viewmodals.FinanceVM
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun GiderlerTablo(modifier: Modifier = Modifier, financeVM: FinanceVM) {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    var showAddGider by remember { mutableStateOf(false) }
    var editFinItem: FinItem? = null
    Box(modifier = modifier
        .fillMaxHeight()
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
                .margin(top = 3.px)
                .overflow(Overflow.Hidden)
                .backgroundColor(Colors.White)
        ) {
            FinTitleBar(FinType.Gider, breakpoint) {
                editFinItem = null
                showAddGider = true
            }

            //CowSearch(search)
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)

            ) {
                financeVM.giderList.forEach { gider ->
                    FinItemRow(gider, onEdit = {
                        editFinItem = gider
                        showAddGider = true
                    }, onDelete = {
                        scope.launch {
                            financeVM.delFinItem(gider)
                        }
                    })
                }


            }
        }

        if (showAddGider) {
            DialogView {
                AddFinItem(scope, FinType.Gider, financeVM, editFinItem) {
                    showAddGider = false
                }
            }
        }
    }

}
