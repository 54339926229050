package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.ListSelect
import com.kelimesoft.cowmaster.pages.Router
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext

@Page("/liste")
@Composable
fun CowListPage() {
    AppData.cowListVM.selectedList = ListSelect.all
    AppData.appViewModel.activeScreens = Screens.CattleList
    Router()
}

@Page("/liste/{grup}")
@Composable
fun CowListGrup() {
    val ctx = rememberPageContext()
    AppData.cowListVM.selectedList = getSelectedList(ctx.route.params.getValue("grup"))
    AppData.appViewModel.activeScreens = Screens.CattleList
    Router()
}

private fun getSelectedList(path: String): ListSelect{
    //console.log("list:" , path)
    return ListSelect.valueOf(path)
}



