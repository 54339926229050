package com.kelimesoft.cowmaster.pages.cowdetails

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.components.DropDownMenu
import com.kelimesoft.cowmaster.components.ErrorText
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.MilkTime
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.kelimesoft.cowmaster.styles.CowInputStyle
import com.kelimesoft.cowmaster.styles.DropMenuItemStyle
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.dateToDbStr
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.CowListViewModel
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import kotlin.js.Date

@Serializable
enum class DisSebep(val text: String){
    @SerialName("dis_died")
    Died("dis_died".tr()),
    @SerialName("dis_sold")
    Sold("dis_sold".tr()),
    @SerialName("dis_culled")
    Culled("dis_culled".tr()),
    @SerialName("dis_lost")
    Lost("dis_sold".tr()),
    @SerialName("dis_other")
    Other("dis_other".tr())
}


@Composable
fun DismissCow(listVM: CowListViewModel, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    var disSebep: DisSebep? by remember { mutableStateOf(null) }
    var disNotes by remember { mutableStateOf("") }
    var disDate by remember { mutableStateOf("") }

    var disError by remember { mutableStateOf("") }

    fun disposeCow(){
        if (disSebep == null){
            disError = "Sürüden çıkarma nedenini ekleyiniz!"
            return
        }
        scope.launch {
            val result = listVM.dismissCow(disSebep!!, disNotes, disDate)
            if (result) {
                onClose()
            }

        }
    }


    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("detay_dismiss".tr(), onClose = onClose)


        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 16.px, topBottom = 30.px)
            ,
            horizontalAlignment = Alignment.Start
        ) {
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .margin(top = 4.px)
                    .padding(leftRight = 1.px)
                    .color(AppColors.TextColor.copyf(alpha = 0.6f))
                    .toAttrs()
            ) {
                Text("dismiss_sebep".tr())
            }
                Box(
                    Modifier
                        .fillMaxWidth()
                        .padding(2.px)
                    ,
                    contentAlignment = Alignment.Center
                ) {
                    DropDownMenu(
                        menuModifier = Modifier.fillMaxWidth(),
                        butonModifier = CowInputStyle.toModifier()
                            .fillMaxWidth()
                            .padding(6.px)
                            .margin(topBottom = 8.px)
                            .whiteSpace(WhiteSpace.NoWrap)
                            .onClick {
                                it.stopPropagation()
                                val menu = document.getElementById("add-drop") as HTMLDivElement
                                if (menu.style.display == "block") {
                                    menu.style.display = "none"
                                } else {
                                    closeDropDowns()
                                    menu.style.display = "block"
                                }
                            },
                        id = "add-drop",
                        content = {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(disSebep?.text ?: "shared_select".tr())
                                Spacer()
                                FaCaretDown(
                                    modifier = Modifier
                                        .color(AppColors.GrayDark),
                                    size = IconSize.LG
                                )

                            }
                        }) {
                        DisSebep.entries.forEach { sebep ->
                            Box(
                                modifier = DropMenuItemStyle.toModifier()
                                    .padding(3.px)
                                    .color(AppColors.TextColor)
                                    .fontSize(14.px)
                                    .margin(bottom = 2.px)
                                    .onClick {
                                        if (disSebep != sebep) {
                                            disSebep = sebep
                                        }
                                        disError = ""
                                    }
                            ) {
                                Text(sebep.text)
                            }
                        }
                    }

                }
            if(disError.isNotEmpty()){
                SpanText(disError, modifier = Modifier
                    .padding(leftRight = 4.px, bottom = 2.px)
                    .fontSize(13.px).color(AppColors.Red))
            }

            Form(
                attrs = Modifier
                    .id("dismiss-form")
                    .fillMaxWidth()
                    .margin(topBottom = 4.px)
                    .toAttrs()
            ) {

                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .margin(top = 4.px)
                        .padding(leftRight = 1.px)
                        .color(AppColors.TextColor.copyf(alpha = 0.6f))
                        .toAttrs()
                ) {
                    Text("dismiss_date".tr())
                }
                Input(
                    type = InputType.Date,
                    attrs = CowInputStyle.toModifier()
                        .attrsModifier {
                            //attr("type", "text")
                            attr("name", "dismissdate")
                            attr("placeholder", "dismiss_date".tr())
                            attr("required", "true")
                            attr("max", Date().dateToDbStr())

                        }
                        .toAttrs()
                        {
                            onInput {
                                disDate = it.value
                            }
                        }
                )

                Input(
                    type = InputType.Text,
                    attrs = CowInputStyle.toModifier()
                        .attrsModifier {
                            attr("name", "disnotes")
                            attr("placeholder", "detay_notes".tr())
                        }
                        .toAttrs()
                        {
                            onInput {
                                disNotes = it.value
                            }
                        }
                )


                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(top = 20.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Button(
                        attrs = TextButtonStyle.toModifier()
                            .fontSize(16.px)
                            .color(AppColors.DarkRed)
                            .onClick {
                                it.preventDefault()
                                onClose()
                            }
                            .toAttrs()

                    ) {
                        Text("shared_iptal".tr())
                    }


                    Spacer()
                    Button(
                        attrs = MainButtonStyle.toModifier()
                            .backgroundColor(AppColors.Red)
                            .onClick {
                                it.preventDefault()
                                disposeCow()
                            }
                            .toAttrs()

                    ) {
                        Text("detay_dismiss".tr())
                    }
                }
            }

        }




    }

}

@Composable
fun DeleteAlertCow(listVM: CowListViewModel, onClose: () -> Unit) {
    var delMilk by remember { mutableStateOf(false) }
    val scope = rememberCoroutineScope()
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White), horizontalAlignment = Alignment.Start
    ) {

        DialogTitle("detay_cowsil".tr(), onClose = onClose)

        ErrorText(
            "detay_cowsil_not".tr(),
            Modifier
                .textAlign(TextAlign.Start)
                .fontSize(16.px)
                .padding(10.px)
                .color(AppColors.TextColor)
        )
        if (listVM.selectedCow!!.snf == 5) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(left = 8.px, top = (-2).px, bottom = 10.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                CheckboxInput(
                    checked = delMilk,
                    attrs = Modifier
                        .size(18.px)
                        .toAttrs()
                        {
                            onChange { elm ->
                                delMilk = elm.value
                            }
                        }
                )
                Label(
                    attrs = Modifier.fontSize(14.px)
                        .color(AppColors.DarkRed.copyf(alpha = 0.7f))
                        .toAttrs()
                ) {
                    Text("detay_cowsil_sut".tr())
                }
            }
        }


        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(top = 20.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Button(
                attrs = TextButtonStyle.toModifier()
                    .fontSize(16.px)
                    .color(AppColors.DarkRed)
                    .onClick {
                        it.preventDefault()
                        onClose()
                    }
                    .toAttrs()

            ) {
                Text("shared_iptal".tr())
            }

            Spacer()
            Button(
                attrs = MainButtonStyle.toModifier()
                    .backgroundColor(AppColors.Red)
                    .onClick {
                        it.preventDefault()
                        scope.launch {
                            val result = listVM.deleteCow(true)
                            if (result) {
                                onClose()
                            }

                        }
                    }
                    .toAttrs()

            ) {
                Text("detay_cowsil_full".tr())
            }
        }


    }

}