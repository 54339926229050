package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.CircleBox
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.styles.DetailButtonStyle
import com.kelimesoft.cowmaster.styles.PaneCardStyle
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.viewmodals.HaveDates
import com.kelimesoft.cowmaster.utils.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date


@Composable
fun AppCalendarView(
    modifier: Modifier = Modifier,
    datesVM: HaveDates,
    //onSelect: (Date, Date) -> Unit
) {
    val scope = rememberCoroutineScope()
    val monthList = "monthlist".tr().split(",")
    val dayslist = "dayslist".tr().split(",")
    val today = Date().toLocaleDateString()
    val listOfDays by mutableStateOf(daysInList1(datesVM.startDate))
    val selectMonth by mutableStateOf(datesVM.startDate.getMonth())
    val selectYear by mutableStateOf(datesVM.startDate.getFullYear())

    CardView(modifier = modifier){
        Column(
            modifier = Modifier.fillMaxWidth()
                .fontFamily(Values.FONT_FAMILY)
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.SpaceEvenly,
            horizontalAlignment = Alignment.CenterHorizontally
            ) {
            //Text(value = date1.value.lastDayOfMonth().toLocaleDateString())
            Row(
                modifier = Modifier
                    .fillMaxWidth(94.percent)
                    .minWidth(310.px)
                    .backgroundColor(Colors.Gray.copyf(alpha = 0.1f))
                    .borderRadius(r = 10.px)
                    .boxShadow(1.px, 2.px, blurRadius = 2.px, color = Colors.Gray.copyf(alpha = 0.3f))
                    //.margin(8.px)
                    .padding(4.px),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaArrowLeft(
                    modifier = Modifier.color(AppColors.DarkRed.opacity(0.8f))
                        .onClick {
                            scope.launch {
                                datesVM.gotoPrevious()
                            }
                        },
                    size = IconSize.LG,
                )


                Spacer()
                SpanText(
                    "${monthList[selectMonth]} - $selectYear",
                    modifier = Modifier
                        .color(AppColors.TextColor)
                        .fontSize(18.px)
                        .fontWeight(FontWeight.SemiBold))

                Spacer()
                FaArrowRight(
                    modifier = Modifier.color(AppColors.DarkRed.opacity(0.8f))
                        .onClick {
                            scope.launch {
                                datesVM.gotoNext()
                            }
                        },
                    size = IconSize.LG,
                )
            }
        }

        Row(
            modifier = Modifier.fillMaxWidth()
                .minWidth(320.px),
            horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.CenterVertically
        ) {
            var mselect = datesVM.selectStart == datesVM.startDate
                    && datesVM.selectEnd == datesVM.endDate
            TumHafta("all", datesVM.startDate.firstDayOfMonth(),
                datesVM.endDate, mselect) { d1, d2 ->
                scope.launch {
                    datesVM.changeSelection(d1, d2)
                }
            }
            DayTitle(dayslist[0])
            DayTitle(dayslist[1])
            DayTitle(dayslist[2])
            DayTitle(dayslist[3])
            DayTitle(dayslist[4])
            DayTitle(dayslist[5], false)
            DayTitle(dayslist[6], false)

        }
        for (week in listOfDays.toList().indices step 7) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .minWidth(320.px),
                horizontalArrangement = Arrangement.SpaceEvenly,
                verticalAlignment = Alignment.CenterVertically
            ) {
                val dt1 = listOfDays[week]
                //console.log("week+6:", week)
                val dt2 = listOfDays[week + 6].endOfDay()
                //console.log("week:", week, "dt2:", dt2.toDateString())
                val hselect = datesVM.selectStart == dt1 && datesVM.selectEnd == dt2
                TumHafta("$week", dt1, dt2, hselect) { d1, d2 ->
                    scope.launch {
                        datesVM.changeSelection(d1, d2)
                    }
                }

                for (i in week..week + 6) {
                    //console.log("i:", i, week)
                    var selected = listOfDays[i].isBefore(datesVM.selectEnd)
                            && listOfDays[i].endOfDay().isAfter(datesVM.selectStart)
                    var txcolor = AppColors.TextColor
                    var daytoday = today == listOfDays[i].toLocaleDateString()
                    var thismonth = datesVM.startDate.getMonth() == listOfDays[i].getMonth()
                    if (!thismonth) txcolor = Colors.Gray.copyf(alpha = 0.8f)
                    Box(modifier = Modifier.weight(1f).padding(2.px),
                        contentAlignment = Alignment.Center
                        ) {
                            CircleBox(
                                modifier = Modifier.onClick {
                                    scope.launch {
                                        val d1 = listOfDays[i]
                                        val d2 = listOfDays[i].endOfDay()
                                        datesVM.changeSelection(d1, d2)
                                    }
                                }
                                    .size(24.px)
                                    .backgroundColor(if (selected) Colors.Gray.copyf(alpha = 0.5f) else Colors.Transparent)
                            ) {
                                if (daytoday) {
                                    Span(
                                        attrs = Modifier
                                            .color(txcolor)
                                            .textAlign(TextAlign.Center)
                                            .fontWeight(700)
                                            .fontSize(17.px)
                                            .textDecorationLine(TextDecorationLine.Underline)
                                            .toAttrs()
                                    ) {
                                        Text(
                                            listOfDays[i].getDate().toString()
                                        )
                                    }

                                } else {
                                    Span(
                                        attrs = Modifier
                                            .color(txcolor)
                                            .textAlign(TextAlign.Center)
                                            .fontWeight(400)
                                            .fontSize(16.px)
                                            .toAttrs()
                                    ) {
                                        Text(
                                            listOfDays[i].getDate().toString()
                                        )
                                    }
                                }
                            }

                    }
                }
            }
        }

    }
}


@Composable
fun RowScope.TumHafta(week: String, d1: Date, d2: Date, selected: Boolean, onSelect: (Date, Date) -> Unit) {
    //var color = Color("#576574")
    //AppColors.GrayDark
    //if (selected) color = Color(0xff576574)

    Button(
        attrs = DetailButtonStyle.toModifier()
            .height(24.px)
            .minWidth(60.px)
            .maxWidth(100.px)
            .onClick {
                onSelect(d1, d2)
            }
            .fontSize(13.px)
            .whiteSpace(WhiteSpace.NoWrap)
            .backgroundColor(if (selected) AppColors.GrayDark else AppColors.GrayDark.copyf(alpha = 0.6f))
            .color(Colors.White)
            .margin(3.px)
            .toAttrs()) {
        Text(
            if (week == "all") "calendar_month".tr() else "calendar_week".tr()
        )
    }

}

@Composable
fun RowScope.DayTitle(gun: String, weekday: Boolean = true) {
    //console.log("daytitle:", gun)
    Button(
        attrs = DetailButtonStyle.toModifier()
            .height(22.px)
            .weight(1f)
            .margin(topBottom = 2.px, leftRight = 2.px)
            .fontSize(12.px)
            .whiteSpace(WhiteSpace.NoWrap)
            .backgroundColor(if (weekday) AppColors.DarkRed.copyf(alpha = 0.6f) else AppColors.DarkRed.copyf(alpha = 0.8f))
            .color(Colors.White)
            .toAttrs()
    ) {
        Text(gun)
    }


}


fun daysInList1(date: Date): List<Date> {
    var gunler = mutableListOf<Date>()
    var dayscount = date.daysInMonth()
    var firstweekday = date.firstDayOfMonth().getDay()
    var prevMontDays = date.previousMonth().daysInMonth()
    var lastweekday = date.lastDayOfMonth().getDay()

    //fist day 0 olarak pazarı geliyor, pazar 7 yapıldı
    if (firstweekday == 0) firstweekday = 7
    if (lastweekday == 0) lastweekday = 7
    //println("firstday: $firstweekday")
    //println("lastday: $lastweekday")
    if (firstweekday > 1) {
        for (gun in (prevMontDays - firstweekday + 2)..prevMontDays) {
            val gundate = setDateDay(date.previousMonth(), gun)
            //console.log("prewmonth: ", gundate.toDateString(), gun)
            gunler.add(gundate)
        }
    }

    for (gun in 1..dayscount) {
        val gundate = setDateDay(date, gun)
        //console.log("currentmonth: ", gundate.toDateString(), gun)
        gunler.add(gundate)
    }

    if (lastweekday < 7) {
        for (gun in 1..(7 - lastweekday)) {
            val gundate = setDateDay(date.nextMonth(), gun)
            //console.log("newxtmonth: ", gundate.toDateString(), gun)
            gunler.add(gundate)
        }
    }
    return gunler.toList()
    //return SnapshotStateList<GunItem>().also { it.addAll(gunler.toList()) }
}

fun setDateDay(date: Date, day: Int): Date {
    return Date(date.getFullYear(), month = date.getMonth(), day = day, hour = date.getHours())
}


@Composable
fun CardView(modifier: Modifier = Modifier, content: @Composable ColumnScope.() -> Unit) {
    Column(
        modifier = PaneCardStyle.toModifier().then(modifier),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        content()
    }

}




