package com.kelimesoft.cowmaster.pages.dismiss

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.CircleBox
import com.kelimesoft.cowmaster.components.PieChart
import com.kelimesoft.cowmaster.components.SummaryCard
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.utils.*
import com.kelimesoft.cowmaster.models.CikanGrup
import com.kelimesoft.cowmaster.viewmodals.DismissVM
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh


@Composable
fun DismissContent(dismissVM: DismissVM){
    val breakpoint = rememberBreakpoint()
    Column(modifier = Modifier
        //bu modifier bilgisini eklediğimde aşağıdaki scroll overflow ancak çalıştı
        .overflow(Overflow.Hidden)
        .fillMaxSize(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        DismissBar(dismissVM)
        Column(modifier = Modifier
            .padding(leftRight = if(breakpoint <= Breakpoint.SM) 2.px else 8.px, topBottom = 2.px)
            //.overflow { y(Overflow.Hidden) }
            .overflow(Overflow.Hidden)
            //.overflow { x(Overflow.Hidden) }
            .fillMaxSize()
            //.scrollBehavior(ScrollBehavior.Smooth)
            .styleModifier {
                property("scrollbar-width", "thin")
            }) {
            SimpleGrid(numColumns = numColumns(base = 1, sm = 2), modifier = Modifier.fillMaxWidth()){
                DismissGrupView(dismissVM)

                DismissCowDetay(dismissVM)
            }
        }
    }
}

@Composable
fun DismissGrupView(dismissVM: DismissVM){
    var selectGrup: String by remember { mutableStateOf("all") }
    Column(modifier = Modifier.fillMaxWidth()
        .padding(2.px),
            verticalArrangement = Arrangement.Top) {
        SummaryCard(title = "diss_ozettitle".tr()) {
            DismissChart(dismissVM, selectGrup){
                selectGrup = it
            }
        }
        SummaryCard(title = if(selectGrup == "all") "diss_liste".tr() else selectGrup) {
            GrupList(dismissVM, selectGrup)
        }
    }
}

@Composable
fun DismissChart(dismissVM: DismissVM, selected: String, onSelect: (String) -> Unit){
    //val colors by remember { mutableStateOf(listOf(AppColors.Primary, Colors.Red)) }
    var sayiList by remember { mutableStateOf(listOf<Double>()) }
    var colors by remember { mutableStateOf(listOf<Color>()) }
    //var chartList by remember { mutableStateOf(listOf<ChartData>()) }
    LaunchedEffect(dismissVM.dismissGrupList){
        //console.log("dismissgrup:", dismissVM.dismissGrupList.toString())
        if (dismissVM.dismissGrupList.isNotEmpty()) {
            //cikanSay = cikanListe.fold(0) { total, item -> total + item.sayi }
            val slist = mutableListOf<Double>()
            val clist = mutableListOf<Color>()
            //val nclist = mutableListOf<ChartData>()
                for (hay in dismissVM.dismissGrupList.indices) {
                    //var c = hay
                    //if (hay + 1 > 8) c = ((hay + 1) % 8)
                    /*
                    nclist.add(
                        ChartData(
                            dismissVM.dismissGrupList[hay].sebep,
                            dismissVM.dismissGrupList[hay].sayi.toDouble(),
                            dismissVM.dismissGrupList[hay].color ?: "#55efc4"
                        )
                    )*/
                    slist.add(dismissVM.dismissGrupList[hay].sayi.toDouble())
                    clist.add((dismissVM.dismissGrupList[hay].color ?: AppColors.Primary) as Color)
                    //dismissVM.dismissGrupList[hay].color = AppColors.pieColorList[c]

                }

            colors = clist.toList()
            sayiList = slist.toList()
            //chartList = nclist.toList()

        }else{
            colors = listOf()
            sayiList = listOf()
        }
    }
    Row(
        modifier = Modifier.fillMaxSize()
            .minHeight(180.px)
            .overflow(Overflow.Hidden)
            .maxHeight(360.px)
            .padding(leftRight = 4.px),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        /*
            PieChartCss(
                dataList = chartList, modifier = Modifier
                    .background(AppColors.Gray)
                    .height(180.px)
                    .width(180.px)
            )

         */


        PieChart(modifier = Modifier
            .minWidth(120.px)
            .weight(0.7f),
            data = sayiList, colors)
        //GelirInfo(finance)

        Column(modifier = Modifier.fillMaxWidth()) {

            Column(
                modifier = Modifier.fillMaxWidth()
                    .maxHeight(190.px)
                    .overflow { y(Overflow.Auto) }
                    .scrollBehavior(ScrollBehavior.Smooth)
                    .styleModifier {
                        property("scrollbar-width", "thin")
                    }
                    .margin(left = (-4).px)
                    .padding(4.px),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Top
            ) {
                dismissVM.dismissGrupList.forEach {
                    DismissGrupRow(it, selected) {
                        onSelect(it.sebep)
                    }
                }

            }
            Row(modifier = Modifier.fillMaxWidth()
                .onClick {
                    onSelect("all")
                }
                .cursor(Cursor.Pointer)
                .margin(2.px)
                .padding(8.px)
                .thenIf(selected == "all"){
                    Modifier.background(AppColors.Gray.opacity(0.1f))
                },
                verticalAlignment = Alignment.CenterVertically) {

                SpanText("diss_toplam".tr(), modifier = Modifier.color(AppColors.TextColor)
                    .fontSize(14.px)
                    .padding(left = 10.px)
                    .fontWeight(FontWeight.SemiBold)
                )
                Spacer()
                SpanText(dismissVM.disposedList.size.toString(),
                    modifier = Modifier
                        .padding(right = 10.px)
                        .color(AppColors.TextColor)
                    .fontSize(14.px).fontWeight(
                    FontWeight.SemiBold)
                    .margin(right = 12.px))
            }
        }

    }

}
@Composable
fun DismissGrupRow(grup: CikanGrup, selected: String, onSelect: () -> Unit){
    Column(modifier = Modifier.fillMaxWidth()
        .onClick {
            onSelect()
        }
        .cursor(Cursor.Pointer)
        .thenIf(selected == grup.sebep){
            Modifier.background(AppColors.Gray.opacity(0.1f))
        }
        , horizontalAlignment = Alignment.Start
    ) {
        Row(modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically) {
            CircleBox(modifier = Modifier
                .background(grup.color)
                .padding(4.px)
                .margin(right = 4.px)
                .size(22.px)) {  }
            SpanText(grup.sebep.tr(), modifier = Modifier.color(AppColors.TextColor).fontSize(14.px))
            Spacer()
            SpanText(grup.sayi.toString(), modifier = Modifier.color(AppColors.TextColor).fontSize(14.px).fontWeight(
                FontWeight.SemiBold)
                .margin(left = 8.px))
        }
        VDivider()
    }
}


@Composable
fun GrupList(dismissVM: DismissVM, selected: String){
    val breakpoint = rememberBreakpoint()
    var filteredListe: List<DisposedCow> =
        if (selected == "all") dismissVM.disposedList else dismissVM.disposedList.filter { it.sebep == selected }

    Column(modifier = Modifier
        .fillMaxSize()
        .padding(2.px)

    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .thenIf(breakpoint <= Breakpoint.SM){
                    Modifier
                        .maxHeight(320.px)

                }.thenIf(breakpoint <= Breakpoint.MD){
                    Modifier
                        .maxHeight(480.px)
                }.thenIf(breakpoint > Breakpoint.MD){
                    Modifier
                        .maxHeight(620.px)
                }
                .overflow { y(Overflow.Auto) }
                .scrollBehavior(ScrollBehavior.Smooth)
                .styleModifier {
                    property("scrollbar-width", "thin")
                }
                .margin(left = (-4).px)
                .padding(4.px),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            filteredListe.forEach { cow ->
                DismissCowRow(cow){
                    dismissVM.selectedCow = cow
                }
            }
        }
    }

}
