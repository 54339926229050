package com.kelimesoft.cowmaster.pages.cowlist

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.HerdItem
import com.kelimesoft.cowmaster.models.ListCow
import com.kelimesoft.cowmaster.models.Values

import com.kelimesoft.cowmaster.pages.cowdetails.CowDetayView
import com.kelimesoft.cowmaster.viewmodals.CowListViewModel
import com.kelimesoft.cowmaster.styles.CowRowStyle
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.kgToPound
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.background
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.math.roundToInt


@Composable
fun ListContent(
    listVM: CowListViewModel, breakpoint: Breakpoint,
    onSelect: () ->Unit,
    onAdd: () -> Unit
) {
    val search = remember { mutableStateOf("") }
    var selectedHerd: MutableState<HerdItem?> = remember { mutableStateOf(null) }
    var lastVisible by remember { mutableStateOf(30) }

    if (lastVisible < listVM.cowList.size - 1) {
        ObserLastListItem {
            if (lastVisible < listVM.cowList.size - 1) {
                lastVisible += 15
            }
        }
    }

    val filteredList: List<ListCow> =
        if (selectedHerd.value != null) {
            listVM.cowList.filter { cow ->
                (cow.herd != null && cow.herd!!.id == selectedHerd.value!!.id) &&
                        (cow.name.lowercase().contains(search.value.lowercase())
                                || cow.kupe.lowercase().contains(search.value.lowercase())
                                || cow.durum.key.lowercase().contains(search.value.lowercase()))
            }
        } else {
            listVM.cowList.filter { cow ->
                cow.name.lowercase().contains(search.value.lowercase())
                        || cow.kupe.lowercase().contains(search.value.lowercase())
                        || cow.durum.key.lowercase().contains(search.value.lowercase())
            }

        }
    Row(
        modifier = Modifier
            .fillMaxSize()
            .overflow(Overflow.Hidden)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth(if (breakpoint > Breakpoint.SM) 60.percent else 100.percent)
                .minWidth(if (breakpoint > Breakpoint.SM) 320.px else 260.px)
                .maxWidth(if (breakpoint > Breakpoint.SM) 560.px else 1000.px)
                .fillMaxHeight()
                .overflow(Overflow.Hidden)
                .backgroundColor(Colors.White)
        ) {
            if(breakpoint > Breakpoint.SM){
                ListBar(search) {
                    onAdd()
                }
                ListInnerBar(selectedHerd =  selectedHerd)
            }else{
                ListInnerBar(search, selectedHerd)
            }

            Column(
                modifier = Modifier
                    .id("lazy-column")
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .padding(leftRight = 6.px)
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)

            ) {
                //console.log("cow-list:", "lazy")
                filteredList.forEachIndexed { index, listCow ->
                    //console.log("listcow:", index, listCow.toString())
                    CowRow(listCow, listVM.selectedCow?.id ?: -1L, index < lastVisible) {
                        listVM.updateSelected(listCow)
                        onSelect()
                    }
                }
                Box(
                    modifier = Modifier.id("last-item")
                        .height(1.px)
                )
            }
        }

        if (breakpoint > Breakpoint.SM){
            CowDetayView(listVM, false){}
        }
    }
}


@Composable
fun CowRow(listCow: ListCow, selected: Long, show: Boolean, onSelect: () -> Unit) {
    val units = "birim_metric"
    fun warnText(): String {
        val stateText = listCow.durum
        if (stateText.key.isNotEmpty()) {
            if (stateText.vals.isNullOrEmpty()) {
                return stateText.key.tr()
            }
            if (stateText.key == "genfunc_kilo_sonucu") {
                if (units == "birim_imperial") {
                    val kilo = stateText.vals!![0].toIntOrNull()
                    if (kilo != null) {
                        return stateText.key.tr().Sprintf((kilo.kgToPound().roundToInt()).toString(), "Lbs")
                    }
                }
                return stateText.key.tr().Sprintf(stateText.vals!![0], "Kg")
            }
            //console.log(*stateText.vals!!.map { it }.toTypedArray())
            return stateText.key.tr().Sprintf(*stateText.vals!!.toTypedArray())
        }
        return ""

    }

    Column(
        modifier = CowRowStyle.toModifier()
            .styleModifier {
                if (selected == listCow.id){
                    background(Colors.Gray.copyf(alpha = 0.1f))
                    border(0.5.px, LineStyle.Solid, color = Colors.Black.copyf(alpha = 0.2f))
                }
            }
            .display(if (show) DisplayStyle.Block else DisplayStyle.None)
            .onClick {
                onSelect()
            }, horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                modifier = Modifier.size(24.px)
                    .margin(right = 4.px),
                src = Values.imgPath2 + listCow.getSnfIcon()
            )
            Column(modifier = Modifier.fillMaxWidth()) {
                P(
                    attrs = Modifier
                        .margin(bottom = 1.px, top = 1.px)
                        .padding(1.px)
                        .color(AppColors.TextColor)
                        .fontWeight(FontWeight.Medium)
                        .fontSize(15.px)
                        .toAttrs()
                ) {
                    Text(listCow.kupe)
                    if (listCow.name.isNotEmpty()) {
                        Text(", ")
                        Span(
                            attrs = Modifier
                                .color(AppColors.TextColor)
                                .fontSize(14.px)
                                .fontWeight(FontWeight.Normal)
                                .opacity(80.percent)
                                .toAttrs()
                        ) {
                            Text(listCow.name)
                        }
                    }
                }

                if (listCow.durum.key.length > 4) {
                    P(
                        attrs = Modifier
                            .margin(bottom = 1.px, top = 1.px)
                            .padding(1.px)
                            .fontSize(11.px)
                            .color(getColor(listCow.uyari))
                            .toAttrs()
                    ) {
                        Text(warnText())
                    }
                }
            }
            //Spacer()
            Column(horizontalAlignment = Alignment.End) {
                Row {
                    if (listCow.res1 > 0) {
                        Image(
                            modifier = Modifier
                                .size(17.px)
                                .margin(right = 2.px),
                            src = Values.imgPath2 + listCow.getIcon(listCow.res1)
                        )
                    }
                    if (listCow.res2 > 0) {
                        Image(
                            modifier = Modifier
                                .size(17.px)
                                .margin(right = 2.px),
                            src = Values.imgPath2 + listCow.getIcon(listCow.res2)
                        )
                    }
                }
                if ((listCow.herd?.id ?: -1) > 0 && (listCow.herd?.color ?: 1) > 0) {
                    Box(
                        modifier = Modifier.size(17.px)
                            .borderRadius(r = 50.percent)
                            .opacity(80.percent)
                            .backgroundColor(AppColors.herdColorList[(listCow.herd?.color ?: 1) - 1])
                            .margin(right = 2.px, top = 1.px),
                        contentAlignment = Alignment.Center
                    ) {
                        P(
                            attrs = Modifier
                                .fontSize(10.px)
                                .color(Colors.White)
                                .toAttrs()
                        ) {
                            Text(listCow.herd?.name?.substring(0, 1) ?: "")
                        }
                    }
                }
            }
        }
        if (selected != listCow.id) VDivider()
    }
}

private fun getColor(uyari: Int): CSSColorValue {
    return when (uyari) {
        1 -> Colors.Red
        2 -> Colors.SaddleBrown
        3 -> AppColors.PrimaryDark
        else -> AppColors.TextColor
    }
}