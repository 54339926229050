package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.kelimesoft.cowmaster.components.DropDownMenu
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.styles.BarButtonStyle
import com.kelimesoft.cowmaster.styles.DropDownButtonStyle
import com.kelimesoft.cowmaster.styles.DropMenuItemStyle
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.models.Routes
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun SummaryBar(haysay: Int, suruSay: Int, dailyNotif: Int) {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .backgroundColor(AppColors.BarColor)
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Button(
            attrs = BarButtonStyle.toModifier()
                .toAttrs()

        ) {
            Link(
                modifier = Modifier
                    .textAlign(textAlign = TextAlign.Left)
                    .textDecorationLine(TextDecorationLine.None)
                    //.fontSize(14.px)
                    .color(AppColors.TextColor),
                path = NavButtons.CowList.destination,

                ) {
                Text("${"sumbar_say".tr()} $haysay")
            }
        }

        Button(
            attrs = BarButtonStyle.toModifier()
                .margin(leftRight = 6.px)
                .onClick {
                    AppData.summaryViewModel.showHerdList = true
                }
                .fontWeight(FontWeight.Medium)
                .color(AppColors.TextColor)
                .toAttrs()

        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                FaLayerGroup(
                    modifier = Modifier
                        .margin(right = 4.px)
                        .color(AppColors.TextColor),
                    size = IconSize.SM
                )
                Text("$suruSay")
            }
        }

        Spacer()

        Link(
            modifier = BarButtonStyle.toModifier()
                .margin(leftRight = 6.px)
                .fontWeight(FontWeight.Bold)
                .color(Colors.Red)
                .textDecorationLine(TextDecorationLine.None),
            path = Routes.Notifs,

            ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                FaBell(
                    style = IconStyle.FILLED,
                    modifier = Modifier
                        .margin(right = 4.px)
                        .color(AppColors.TextColor),
                    size = IconSize.SM

                )

                Text("$dailyNotif")
            }
        }

        BarProfileButton(scope, breakpoint)

    }
}

@Composable
private fun BarProfileButton(scope: CoroutineScope, breakpoint: Breakpoint) {
    DropDownMenu(
        butonModifier = DropDownButtonStyle.toModifier()
        .margin(leftRight = 6.px)
        .onClick {
            it.stopPropagation()
            val menu = document.getElementById("drop1") as HTMLDivElement
            if (menu.style.display == "block") {
                menu.style.display = "none"
            } else {
                menu.style.display = "block"
            }
        },
        rightAlign = true,
        id = "drop1",
        content = {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .minHeight(13.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaUser(
                    modifier = Modifier
                        .margin(right = 4.px)
                        .color(Colors.White),
                    size = IconSize.SM, style = IconStyle.FILLED
                )
                if(breakpoint >= Breakpoint.SM){
                    Text(AppData.cowUser?.email ?: "")
                }

                FaCaretDown(
                    modifier = Modifier
                        .margin(left = 4.px)
                        .color(Colors.White),
                    size = IconSize.SM
                )
            }
        }) {

        Link(
            modifier = DropMenuItemStyle
                .toModifier()
                .color(AppColors.TextColor)
                .fontSize(16.px)
                .textAlign(TextAlign.Center)
                .textDecorationLine(TextDecorationLine.None),
            path = Screens.Profile.path
        ) {
            Text("sumbar_profil".tr())
        }

        VDivider()

        Button(
            attrs = DropMenuItemStyle.toModifier()
                .color(Colors.Red)
                .fontSize(16.px)
                .margin(bottom = 4.px)
                .onClick {
                    scope.launch {
                        AppData.appViewModel.userLogout()
                    }
                }
                .toAttrs()) {
            Text("sumbar_out".tr())
        }
    }
}