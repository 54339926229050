package com.kelimesoft.cowmaster.pages.eventlist

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogTitle
import com.kelimesoft.cowmaster.components.ToastPos
import com.kelimesoft.cowmaster.components.ToastView
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.dateToDbLongStr
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.EventListVM
import com.kelimesoft.cowmaster.components.DropDownMenu
import com.kelimesoft.cowmaster.models.BulkEvent
import com.kelimesoft.cowmaster.models.EventCow
import com.kelimesoft.cowmaster.models.NewBulkEvent
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.kelimesoft.cowmaster.pages.cowdetails.DusukResult
import com.kelimesoft.cowmaster.pages.cowdetails.GebelikSonuc
import com.kelimesoft.cowmaster.pages.cowdetails.TartiResult
import com.kelimesoft.cowmaster.utils.Funcs
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import kotlin.js.Date

enum class EventCowSelect(val text: String){
    All("evt_bulk_tumu".tr()),
    Male("evt_bulk_male".tr()),
    Female("evt_bulk_female".tr()),
    Cows("evt_bulk_cows".tr()),
    Calves("evt_bulk_calf".tr()),
    Pregnant("evt_bulk_preg".tr()),
    Milking("evt_bulk_milk".tr()),
    Dry("evt_bulk_dry".tr()),
    Fresh("evt_bulk_fresh".tr()),
    Open("evt_bulk_open".tr())
}

enum class EvtCowSort {
    Kupe, Name
}


@Composable
fun AddBulkEvent(eventListVM: EventListVM, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    var durumList by remember { mutableStateOf(Events.tosunEvents()) }
    var selectGrup: EventCowSelect by remember { mutableStateOf(EventCowSelect.All) }
    var sortCow: EvtCowSort by remember { mutableStateOf(EvtCowSort.Kupe) }
    var eventSelect: Events by remember { mutableStateOf(Events.None) }
    var eventDate by remember { mutableStateOf(Date().dateToDbLongStr()) }
    var eventInfo by remember { mutableStateOf("") }
    //var eventDeger: MutableState<Int> = remember { mutableStateOf(0) }
    var eventDetay by remember {mutableStateOf("")}
    var allSelected by remember {mutableStateOf(false)}
    var errorText by remember {mutableStateOf("")}
    //var eventCowList = remember { mutableStateListOf<EventCow>() }

    var einfoText: String? =
        when(eventSelect){
            Events.AY -> "adddurum_asiadi".tr()
            Events.TY -> "adddurum_bogaadi".tr()
            Events.HG -> "adddurum_hastaadi".tr()
            else -> null
        }

    LaunchedEffect(true) {
        eventListVM.getEventCowList()
    }

    val eventCowList: List<EventCow> =
        when(selectGrup){
            EventCowSelect.All -> eventListVM.eventCowList.sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Cows ->  eventListVM.eventCowList.filter { it.snf == 5 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Calves -> eventListVM.eventCowList.filter { it.snf == 1 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Pregnant -> eventListVM.eventCowList.filter { it.gebe == 1 || it.tohum == 1 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Milking -> eventListVM.eventCowList.filter { it.sagmal == 1 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Dry -> eventListVM.eventCowList.filter { it.kuru == 1 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Male -> eventListVM.eventCowList.filter { it.gender == 1 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Female -> eventListVM.eventCowList.filter { it.gender == 0 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Fresh -> eventListVM.eventCowList.filter { it.fresh == 1 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
            EventCowSelect.Open -> eventListVM.eventCowList.filter { it.open == 1 }
                .sortedBy { if (sortCow == EvtCowSort.Kupe) it.kupe else it.name}
        }


    fun selectAllCows(select: Boolean){
        allSelected = select
        eventCowList.forEach { ecow ->
            ecow.selected = select
        }
    }

    fun changeEvent(evt: Events){
        if (eventSelect != evt){
            try {
                (document.getElementById("event-info") as HTMLInputElement).value = ""
            }catch (_: Exception){}
            einfoText = null
            eventInfo = ""
            eventSelect = evt
            errorText = ""
            eventCowList.forEach { ecow ->
                ecow.evtDeger = 0
            }
        }
    }

    fun changeGroup(grp: EventCowSelect){
        if (grp != selectGrup){
            selectGrup = grp
            //changeEvent(Events.None)
            when(selectGrup){
                EventCowSelect.Cows,
                EventCowSelect.Female,
                EventCowSelect.Milking,
                EventCowSelect.Open,
                EventCowSelect.Fresh ->  durumList = Events.topluDisiEvents()
                EventCowSelect.Dry -> durumList = Events.kuruEvents()
                EventCowSelect.Pregnant -> durumList = Events.topluGebeEvents()
                else -> durumList = Events.tosunEvents()
            }
            if (!durumList.contains(eventSelect)){
                eventSelect = Events.None
            }
        }
    }

    fun saveBulkEvents(){
        if (!Funcs.checkForm("bulk-form")) {
            return
        }
        val bulkEvents = mutableListOf<BulkEvent>()
        eventCowList.forEach { ecow ->
            if (ecow.selected){
                if (eventSelect == Events.KO) {
                    if (ecow.evtDeger > 0){
                        bulkEvents.add(BulkEvent(ecow.hid, ecow.evtDeger))
                    }
                }else if (eventSelect == Events.GK){
                    bulkEvents.add(BulkEvent(ecow.hid, ecow.evtDeger))
                }else{
                    bulkEvents.add(BulkEvent(ecow.hid, 0))
                }
            }
        }
        if(bulkEvents.isNotEmpty()){
            val edate = Date(eventDate).dateToDbLongStr()
            val bulkEvent = NewBulkEvent(cat = eventSelect.name, edate = edate,
                einfo = eventInfo, not = eventDetay,   cows = bulkEvents.toList(), pregday = 35, dryday = 60)
            //console.log("newbulk-event:", bulkEvent.toString())
            scope.launch {
                eventListVM.saveBulkEvents(bulkEvent)
                AppData.summaryViewModel.bulkEventReset()
                AppData.cowListVM.getCowList()
                onClose.invoke()
            }
        }else{
            errorText = "evt_bulk_notselect".tr()
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("adddurum_add".tr(), onClose = onClose)

        Column(
            Modifier
                .fillMaxWidth()
                .padding(topBottom = 6.px, leftRight = 8.px)
        ) {
            Row (Modifier.flexWrap(FlexWrap.Wrap),
                ){

                EventCowSelect.entries.forEach { eType ->
                    TypeChip(eType, selectGrup == eType){
                        scope.launch {
                            changeGroup(eType)
                        }
                    }
                }
            }

            Box(Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center) {
                DropDownMenu(
                    menuModifier = Modifier.fillMaxWidth(),
                    butonModifier = CowInputStyle.toModifier()
                        .fillMaxWidth()
                        .padding(6.px)
                        .margin(topBottom = 8.px)
                        .whiteSpace(WhiteSpace.NoWrap)
                        .onClick {
                            it.stopPropagation()
                            val menu = document.getElementById("add-drop") as HTMLDivElement
                            if (menu.style.display == "block") {
                                menu.style.display = "none"
                            } else {
                                closeDropDowns()
                                menu.style.display = "block"
                            }
                        },
                    id = "add-drop",
                    content = {
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Image(
                                modifier = Modifier.size(20.px)
                                    .margin(right = 10.px),
                                src = Values.imgPath2 + eventSelect.icon
                            )
                            Text(eventSelect.text)
                            Spacer()
                            FaCaretDown(
                                modifier = Modifier
                                    .color(AppColors.GrayDark),
                                size = IconSize.LG
                            )

                        }
                    }) {
                    durumList.forEach { evt ->
                        Button(
                            attrs = DropMenuItemStyle.toModifier()
                                .padding(3.px)
                                .color(AppColors.TextColor)
                                .fontSize(14.px)
                                .margin(bottom = 2.px)
                                .onClick {
                                    scope.launch {
                                        changeEvent(evt)
                                    }
                                }
                                .toAttrs()) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically
                            ) {

                                Image(
                                    modifier = Modifier.size(20.px)
                                        .margin(right = 10.px),
                                    src = Values.imgPath2 + evt.icon
                                )
                                Text(evt.text)
                            }
                        }
                    }
                }
                /*
                if(errorText.isNotEmpty()){
                    ErrorText(errorText)
                }*/

            }

            Form(attrs = Modifier
                .id("bulk-form")
                .fillMaxWidth()
                .toAttrs()
            ) {
                Column(modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.Start) {
                    Span(
                        attrs = Modifier
                            .fontSize(12.px)
                            .margin(top = 4.px)
                            .padding(1.px)
                            .color(AppColors.TextColor.copyf(alpha = 0.6f))
                            .toAttrs()
                    ) {
                        Text("adddurum_date".tr())
                    }
                Input(
                    type = InputType.DateTimeLocal,
                    attrs = CowInputStyle.toModifier()
                        .id("event-date")
                        .padding(7.px)
                        .attrsModifier {
                            attr("required", "true")
                            attr("value", Date().dateToDbLongStr())
                            attr("max", Date().dateToDbLongStr())
                        }
                        .margin(bottom = 10.px)
                        .toAttrs()
                        {
                            onInput {
                                eventDate = it.value
                            }
                        }
                )
                }
                /*
                if (eventSelect == Events.AB) {
                    DusukResult(eventDeger)
                }else if (eventSelect == Events.KO){
                    TartiResult(eventDeger)
                }else if (eventSelect == Events.GK){
                    GebelikSonuc(eventDeger)
                }*/

                einfoText?.let {
                    Input(
                        type = InputType.Text,
                        attrs = CowInputStyle.toModifier()
                            .id("event-info")
                            .attrsModifier {
                                attr("required", "true")
                                attr("name", "einfo")
                                attr("placeholder", einfoText ?: "")
                            }
                            .margin(bottom = 10.px)
                            .toAttrs()
                            {
                                onInput {
                                    eventInfo = it.value
                                }
                            }
                    )
                }

                Input(
                    type = InputType.Text,
                    attrs = CowInputStyle.toModifier()
                        .attrsModifier {
                            attr("name", "aciklama")
                            attr("placeholder", "adddurum_ackl".tr())
                        }
                        .margin(bottom = 10.px)
                        .toAttrs()
                        {
                            onInput {
                                eventDetay = it.value
                            }
                        }
                )

                Column(modifier = Modifier
                    .fillMaxWidth()
                    .maxHeight(380.px)
                    .minHeight(250.px)
                    .backgroundColor(AppColors.Gray.opacity(0.06f))
                    .padding(2.px)
                    .boxShadow((-1).px, (-2).px, blurRadius = 1.px, color = Colors.Gray.opacity(0.2f))
                    .overflow { x(Overflow.Auto) }
                ) {
                    Row(modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Start)
                    {
                        CheckboxInput(checked = allSelected, attrs = Modifier
                            .id("check-notif-all")
                            .size(17.px)
                            .padding(leftRight = 2.px)
                            .toAttrs {
                                onChange {
                                    scope.launch {
                                        selectAllCows(it.value)
                                    }
                                }
                            }
                        )
                        Label(forId = "check-notif-all", attrs = Modifier
                            .padding(leftRight = 2.px)
                            .fontSize(15.px)
                            .color(AppColors.TextColor)
                            .toAttrs()){
                            Text("evt_bulk_tumu".tr())
                        }
                        SpanText("evt_bulk_select".tr(),
                            modifier = Modifier.margin(left = 14.px)
                                .color(AppColors.Red)
                                .fontSize(11.px)
                        )
                        Spacer()

                        Row(modifier = Modifier.padding(leftRight = 6.px)
                            .cursor(Cursor.Pointer)
                            .onClick {
                                 sortCow = EvtCowSort.Kupe
                            },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Start)
                        {
                            SpanText("evt_bulk_kupe".tr(), modifier = Modifier.color(AppColors.TextColor))
                            FaCaretDown(size = IconSize.XL, modifier = Modifier
                                .padding(topBottom = 2.px, leftRight = 4.px)
                                .color(if(sortCow == EvtCowSort.Kupe) AppColors.NumBlue else AppColors.Gray ))
                        }

                        Row(modifier = Modifier.padding(leftRight = 6.px)
                            .cursor(Cursor.Pointer)
                            .onClick {
                                sortCow = EvtCowSort.Name
                            },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Start)
                        {
                            SpanText("evt_bulk_isim".tr(), modifier = Modifier.color(AppColors.TextColor))
                            FaCaretDown(size = IconSize.XL, modifier = Modifier
                                .padding(topBottom = 2.px, leftRight = 4.px)
                                .color(if(sortCow == EvtCowSort.Name) AppColors.NumBlue else AppColors.Gray ))
                        }
                    }
                    VDivider(modifier = Modifier.backgroundColor(AppColors.GrayDark)
                        .padding(topBottom = 0.2.px)
                        .fillMaxWidth()
                        .opacity(60.percent)
                    )
                    eventCowList.forEach { ecow ->
                        EventCowRow(ecow, eventSelect){ select ->
                            if (!select && allSelected){
                                allSelected =  false
                            }
                            ecow.selected = select
                        }
                    }
                }


            }
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }

                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            if (eventSelect == Events.None){
                                errorText = "adddurum_select".tr()
                            }else{
                                saveBulkEvents()
                            }
                        }
                        .toAttrs()

                ) {
                    Text("adddurum_add".tr())
                }
            }

        }


    }
    if (errorText.isNotEmpty()) {
        ToastView(errorText, pos = ToastPos.Center, color = Colors.Red) {
            errorText = ""
        }
    }

}

@Composable
fun TypeChip(type: EventCowSelect, selected: Boolean, onClick: ()-> Unit) {
    Box(modifier = Modifier
        .cursor(Cursor.Pointer)
        .onClick {
            onClick.invoke()
        }
        .margin(2.px)
        .borderRadius(r = 6.px)
        .backgroundColor(if (selected) AppColors.PrimaryDark.opacity(0.6f) else AppColors.Gray.opacity(0.2f))
        .padding(leftRight = 12.px, topBottom = 2.px),
        contentAlignment = Alignment.Center) {
        SpanText(type.text, modifier = Modifier
            .color(if (selected) Colors.WhiteSmoke else AppColors.TextColor)
            .fontSize(14.px)
            .fontWeight(if (selected) FontWeight.SemiBold else FontWeight.Medium)

        )
    }
}

@Composable
fun EventCowRow(ecow: EventCow, event: Events, onSelect: (Boolean) -> Unit) {
    Row(modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start)
    {
        CheckboxInput(checked = ecow.selected, attrs = Modifier
            .id("check-notif-${ecow.hid}")
            .size(17.px)
            .padding(leftRight = 2.px)
            .toAttrs {
                onChange {
                    onSelect.invoke(it.value)
                }
            }
        )
        Label(forId = "check-notif-${ecow.hid}", attrs = Modifier
            .padding(leftRight = 2.px)
            .fontSize(15.px)
            .color(AppColors.TextColor)
            .toAttrs()){
            Text(ecow.kupe)
        }
        if (ecow.name.isNotEmpty()){
            Label(forId = "check-notif-${ecow.hid}", attrs = Modifier
                .padding(leftRight = 2.px)
                .fontSize(14.px)
                .color(AppColors.TextColor.opacity(0.7f))
                .toAttrs()){
                Text(ecow.name)
            }
        }
        Spacer()
        if (ecow.selected){
            if (event == Events.KO){
                TopluTartiResult(ecow.evtDeger) {
                    ecow.evtDeger = it
                }
            }else if (event == Events.GK){
                TopluGebelikSonuc(ecow.evtDeger) {
                    ecow.evtDeger = it
                }
            }
        }



    }
}

/*
@Composable
fun DusukResult(eventDeger: MutableState<Int>) {
    Row(modifier = Modifier.fillMaxWidth()
        .margin(bottom = 10.px),
        verticalAlignment = Alignment.CenterVertically) {
        Switch(
            checked = eventDeger.value == 1,
            onCheckedChange = {
                eventDeger.value = if(it) 1 else 0
            },

            )
        Span(
            attrs = Modifier
                .margin(left = 8.px)
                .fontSize(14.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("adddurum_sagmaldur".tr())
            if (eventDeger.value == 1){
                Span(
                    attrs = Modifier
                        .color(AppColors.PrimaryDark)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()){
                    Text("adddurum_sagmal".tr())
                }
            } else {
                Span(
                    attrs = Modifier
                        .color(AppColors.GrayDark)
                        .fontWeight(FontWeight.Medium)
                        .toAttrs()){
                    Text("adddurum_kuru".tr())
                }
            }
        }
    }
}

*/
@Composable
fun TopluTartiResult(deger: Int, onInput: (Int) -> Unit ) {
    Row(modifier = Modifier.padding(leftRight = 6.px),
        verticalAlignment = Alignment.CenterVertically) {
        Span(
            attrs = Modifier
                .whiteSpace(WhiteSpace.NoWrap)
                .margin(right = 8.px)
                .fontSize(13.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("Kg".tr())
        }
        Input(
            type = InputType.Text,
            attrs = TopluInputStyle.toModifier()
                .width(90.px)
                .textAlign(TextAlign.End)
                .attrsModifier {
                    inputMode(InputMode.Numeric)
                    attr("required", "true")
                    attr("min", "1")
                }
                .toAttrs {
                    value(deger)
                    onInput {evt ->
                        val inputValue = evt.value.trim() // Get the input value and trim whitespace
                        if (inputValue.isEmpty()) {
                            onInput.invoke(0) // Invoke with 0.0 if empty
                        } else {
                            inputValue.toIntOrNull()?.let {
                                onInput.invoke(it)
                            } ?: onInput.invoke(0) // Fallback to 0.0 if parsing fails
                        }
                    }
                }
        )
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun TopluGebelikSonuc(deger: Int, onInput: (Int) -> Unit ) {
    Row(modifier = Modifier
        .margin(bottom = 1.px),
        verticalAlignment = Alignment.CenterVertically) {

        RadioGroup(
            checkedValue = if (deger == 1) "pozitif" else "negatif",
        ){
            Row(Modifier
                .margin(right = 6.px)
                .cursor(Cursor.Pointer),
                verticalAlignment = Alignment.CenterVertically) {
                RadioInput(
                    value = "pozitif",
                    attrs = Modifier
                        .color(AppColors.PrimaryDark)
                        .size(13.px)
                        .toAttrs()
                        {
                            onChange {
                                onInput.invoke(1)
                            }
                        }
                )

                Span(
                    attrs = Modifier
                        .margin(left = 2.px)
                        .fontSize(12.px)
                        .color(AppColors.PrimaryDark)
                        .onClick {
                            onInput.invoke(1)
                        }
                        .toAttrs()
                ) {
                    Text("adddurum_pozitif".tr())
                }
            }

            Row(Modifier
                .cursor(Cursor.Pointer),
                verticalAlignment = Alignment.CenterVertically) {
                RadioInput(
                    value = "negatif",
                    attrs = Modifier
                        .color(AppColors.Red)
                        .size(13.px)
                        .toAttrs()
                        {
                            onChange {
                                onInput.invoke(0)
                            }
                        }
                )

                Span(
                    attrs = Modifier
                        .margin(left = 2.px)
                        .fontSize(12.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            onInput.invoke(0)
                        }
                        .toAttrs()
                ) {
                    Text("adddurum_negatif".tr())
                }
            }

        }

    }
}


