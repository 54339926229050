package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun VDivider(modifier: Modifier = Modifier) {
    Box(modifier = Modifier
        .fillMaxWidth(97.percent)
        .height(1.px)
        .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.7f))
        .margin(bottom = 3.px, top = 1.px)
        .opacity(30.percent)
        .then(modifier),
        contentAlignment = Alignment.Center
    )
}