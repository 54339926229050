package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.components.LeftPane
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Images
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun NoPremium() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = MainPageStyle.toModifier(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally) {
        Row(
            modifier = Modifier.fillMaxSize()
        ) {
            if (breakpoint >= Breakpoint.SM){
                LeftPane(breakpoint)
            }
            Column(modifier = Modifier.fillMaxWidth()
                .backgroundColor(Colors.White)
                .borderRadius(r = 10.px)
                .padding(20.px)
                .margin(20.px),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center) {

                Image(
                    src = Images.appIcon120
                )

                P(attrs = Modifier
                    .textAlign(TextAlign.Center)
                    .fontSize(20.px)
                    .toAttrs()) {
                    Text("Cow-Master Sürü Yönetim uygulaması mevcut paketiniz ile uyumlu değildir.")
                }
                P(attrs = Modifier
                    .textAlign(TextAlign.Center)
                    .fontSize(20.px)
                    .toAttrs()) {
                    Text("Sürü Yönetim Web Uygulaması +Plus Yönetim paket kullanıcıları için uyumlu ve aktiftir. Cow Master Mobil uygulama üzerinden +PLUS paketlerimizden birini tercih edebilirsiniz")
                }

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Link(
                        modifier = MainButtonStyle.toModifier()
                            .width(200.px)
                            .margin(10.px)
                            .padding(6.px)
                            .fontSize(16.px)
                            .fontFamily(Values.FONT_FAMILY)
                            .color(Colors.White)
                            .whiteSpace(WhiteSpace.NoWrap)
                            .textDecorationLine(TextDecorationLine.None),
                        path = "https://play.google.com/store/apps/details?id=com.kelimesoft.suruyonetimi", ){
                        FaPlay(
                            modifier = Modifier.margin(right = 6.px),
                            size = IconSize.LG)
                        Text("Android Uygulama")
                    }

                    Link(
                        modifier = MainButtonStyle.toModifier()
                            .width(200.px)
                            .margin(10.px)
                            .padding(6.px)
                            .fontSize(16.px)
                            .fontFamily(Values.FONT_FAMILY)
                            .color(Colors.White)
                            .whiteSpace(WhiteSpace.NoWrap)
                            .textDecorationLine(TextDecorationLine.None),
                        path = "https://apps.apple.com/tr/app/cowmaster-herd-management-app/id1532895245", ){
                        FaApple(
                            modifier = Modifier.margin(right = 6.px),
                            size = IconSize.LG)
                        Text("iOS Uygulama")
                    }
                }

                if (AppData.cowUser != null){
                    Span(attrs = TextButtonStyle
                        .toModifier()
                        .fontSize(16.px)
                        .toAttrs()) {
                        Text(AppData.cowUser!!.email)
                    }

                    Button(attrs = MainButtonStyle.toModifier()
                        .backgroundColor(AppColors.Red)
                        .onClick {
                            GlobalScope.launch {
                                AppData.appViewModel.userLogout()
                            }

                        }
                        .toAttrs()) {
                        Text("Çıkış")
                    }
                }
            }

        }

        if (breakpoint < Breakpoint.SM){
            BottomNavigation(breakpoint)
        }

    }

}