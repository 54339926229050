package com.kelimesoft.cowmaster.pages

import StaggeredVerticalGrid2
import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.addDays
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronRight
import com.varabyte.kobweb.silk.components.icons.fa.FaUserGroup
import com.varabyte.kobweb.silk.components.icons.fa.FaUsers
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Page("/profil")
@Composable
fun ProfilePage() {
    AppData.appViewModel.activeScreens = Screens.Profile
    Router()
}

@Composable
fun MyProfile() {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val cowUser = AppData.cowUser
    var profileLogo by remember { mutableStateOf("") }
    LaunchedEffect(Unit, AppData.cowUser) {
        AppData.cowUser?.logo?.let { path ->
            profileLogo = "${Routes.LogoRoot}${path}"
        }
        //console.log("logo:", profileLogo)

    }

    PageBox(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .pointerEvents(PointerEvents.Auto)
    ) {
        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Row(
                modifier = Modifier.fillMaxSize()
            ) {
                if (breakpoint >= Breakpoint.SM) {
                    LeftPane(breakpoint)
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .thenIf(breakpoint <= Breakpoint.SM) {
                            Modifier.overflow { y(Overflow.Auto) }
                                .overflow { x(Overflow.Hidden) }
                                .fillMaxHeight()
                                .scrollBehavior(ScrollBehavior.Smooth)
                                .styleModifier {
                                    property("scrollbar-width", "thin")
                                }
                        }
                        .thenIf(breakpoint > Breakpoint.SM) {
                            Modifier.overflow(Overflow.Hidden)
                        }
                        .padding(leftRight = 4.px)
                ) {
                    Column(
                        modifier = Modifier
                            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                            .fillMaxSize(),
                        verticalArrangement = Arrangement.Top,
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        StaggeredVerticalGrid2(col = if (breakpoint <= Breakpoint.SM) 1 else 2, gap = 10) {
                            SummaryCard(title = "Hesap Bilgileri") {
                                Column(
                                    modifier = Modifier.fillMaxWidth()
                                        .padding(leftRight = 6.px),
                                    verticalArrangement = Arrangement.Top
                                ) {
                                    Row(modifier = Modifier.fillMaxWidth()) {
                                        Image(
                                            modifier = Modifier
                                                .borderRadius(r = 30.percent)
                                                .maxWidth(148.px)
                                                .attrsModifier {
                                                    //on error özelliği ile attr ekledim, resim var ama alınamadı/ başka folder da
                                                    attr(
                                                        "onerror",
                                                        "this.onerror=null;this.src=\"${Routes.WebRoot}${Images.noImage}\";"
                                                    )
                                                }
                                                .objectFit(ObjectFit.Fill)
                                                .padding(6.px),
                                            src = profileLogo.ifEmpty { Values.imgPath1 + Images.noImage },

                                            )

                                        Column(
                                            modifier = Modifier.fillMaxWidth()
                                                .padding(6.px),
                                            horizontalAlignment = Alignment.Start
                                        ) {
                                            HesapBilgiRow("Kullanıcı Adı", cowUser?.email ?: "")
                                            VDivider()
                                            HesapBilgiRow("İşletme Adı", cowUser?.name ?: "")
                                            VDivider()
                                            HesapBilgiRow("İşletme Numarası", cowUser?.dairyId ?: "")
                                            VDivider()

                                        }


                                    }

                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                            .padding(6.px),
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {
                                        /*
                                        Button(attrs = TextButtonStyle
                                            .toModifier()
                                            .color(AppColors.NumOrange)
                                            .fontWeight(FontWeight.SemiBold)
                                            .fontSize(14.px)

                                            .onClick {

                                            }
                                            .toAttrs()) {
                                            Text("Şifre Değiştir")
                                        }*/

                                        Button(attrs = TextButtonStyle.toModifier()
                                            .color(Colors.Red)
                                            .fontWeight(FontWeight.SemiBold)
                                            .fontSize(14.px)
                                            .onClick {
                                                scope.launch {
                                                    AppData.appViewModel.userLogout()
                                                }
                                            }
                                            .toAttrs()) {
                                            Text("Çıkış Yap")
                                        }
                                    }

                                }

                            }
                            SummaryCard(title = "Paket Bilgileri") {
                                Column(modifier = Modifier.fillMaxWidth()){
                                    val paket = AppData.cowUser?.paket ?: 0
                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                            .padding(4.px),
                                        verticalAlignment = Alignment.CenterVertically
                                        ) {
                                        Box(modifier = Modifier
                                            .padding(4.px)
                                            .size(36.px)
                                            .backgroundColor(AppColors.PrimaryDark)
                                            .borderRadius(50.percent),
                                            contentAlignment = Alignment.Center

                                        ) {
                                            FaUserGroup(size = IconSize.LG,
                                                modifier = Modifier.padding(4.px)
                                                //.backgroundColor(AppColors.Primary)
                                                .color(Color.white)
                                                //.borderRadius(r = 50.percent)
                                            )
                                        }


                                        Column(modifier = Modifier.fillMaxWidth()
                                            .padding(4.px)

                                        ) {

                                            SpanText("paket${paket}_title".tr(),
                                                modifier = Modifier.fontSize(FontSize.Large)
                                                .color(AppColors.Primary)
                                                    .padding(2.px)
                                            )
                                            SpanText("paket${paket}_user_sayi".tr(),
                                                modifier = Modifier.fontSize(FontSize.Medium)
                                                .color(AppColors.TextColor)
                                                    .padding(2.px)
                                            )
                                        }
                                    }

                                    Row(modifier = Modifier.fillMaxWidth()
                                        .padding(6.px)){
                                        Row(modifier = Modifier.fillMaxWidth()
                                            .height(54.px)
                                            .backgroundColor(AppColors.Red)
                                            .borderRadius(4.px)
                                            .padding(3.px)
                                            .margin(3.px),
                                            verticalAlignment = Alignment.CenterVertically
                                        ) {
                                            Image(
                                                modifier = Modifier
                                                    .borderRadius(r = 50.percent)
                                                    .size(40.px)
                                                    .objectFit(ObjectFit.Fill)
                                                    .padding(6.px)
                                                    .backgroundColor(Color.white)
                                                ,
                                                src = Values.imgPath1 + Icons.cows,

                                                )

                                            SpanText("paket${paket}_hayvan_sayi".tr(),
                                                modifier = Modifier.fontSize(FontSize.Large)
                                                    .color(Color.white)
                                                    .padding(2.px)
                                            )
                                        }

                                        Row(modifier = Modifier.fillMaxWidth()
                                            .height(54.px)
                                            .backgroundColor(AppColors.GrayDark)
                                            .borderRadius(4.px)
                                            .padding(3.px)
                                            .margin(3.px),
                                            verticalAlignment = Alignment.CenterVertically
                                        ) {
                                            SpanText("Paket Detayları",
                                                modifier = Modifier.fontSize(FontSize.Large)
                                                    .color(Color.white)
                                                    .padding(2.px)
                                            )
                                            Spacer()
                                            FaChevronRight(size = IconSize.LG,
                                                modifier = Modifier.padding(4.px)
                                                    //.backgroundColor(AppColors.Primary)
                                                    .color(Color.white)
                                                //.borderRadius(r = 50.percent)
                                            )
                                        }
                                    }


                                    Span(attrs = Modifier
                                        .padding(6.px)
                                        .color(AppColors.TextColor)
                                        .toAttrs()){
                                        Text("Premium Süre Bitiş Tarihi: ")
                                        AppData.cowUser?.let { cuser ->
                                            Span(attrs = Modifier
                                                .fontWeight(FontWeight.SemiBold)
                                                .toAttrs()){
                                                Text(Date(cuser.startDate).addDays(365).toLocaleDateString())
                                            }
                                        }

                                    }


                                }
                            }

                            SummaryCard(title = "Kullanıcılar") {

                            }


                        }

                    }

                }

            }

            if (breakpoint < Breakpoint.SM) {
                BottomNavigation(breakpoint)
            }

        }

    }
}


@Composable
private fun HesapBilgiRow(
    title: String,
    text: String
) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .padding(2.px),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText(title,
            modifier = Modifier
                .textAlign(TextAlign.Start)
                .color(AppColors.Gray)
                .fontSize(FontSize.Small)
        )

        SpanText(
            text,
            modifier =  Modifier
                .fillMaxWidth(60.percent)
                .textAlign(TextAlign.Start)
                .color(AppColors.TextColor)
                .fontSize(FontSize.Medium)
        )
    }

}