package com.kelimesoft.cowmaster.pages.milk

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.DialogView
import com.kelimesoft.cowmaster.models.Icons
import com.kelimesoft.cowmaster.pages.cowdetails.AddCowMilk
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.rountTo
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.MilkVM
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CowMilkView(milkVM: MilkVM) {
    val breakpoint = rememberBreakpoint()
    var showAdd by remember { mutableStateOf(false) }

    Box(modifier = Modifier
        .fillMaxSize()) {
        Column(modifier = Modifier.fillMaxSize()
            .padding(2.px)
            .overflow { y(Overflow.Hidden) }
            .overflow { x(Overflow.Hidden) }
        ) {
            CowPeriodMilk(milkVM){
                showAdd = true
            }
            CowMilkList(milkVM)

        }
        if (showAdd){
            DialogView {
                AddManyCowMilk(milkVM) {
                    showAdd = false
                }
            }
        }
    }

    if (breakpoint > Breakpoint.SM) {
        Box(modifier = Modifier.width(2.px)) { }

    } else {
        Box(modifier = Modifier.width(4.px)) { }
    }
}


@Composable
fun CowPeriodMilk(milkVM: MilkVM, onAdd: () -> Unit) {
    val milk = milkVM.cowPeriodsum
    CowMilkPeriodCard(milkVM, "milkview_birey".tr(), onAdd = {
        onAdd()
    }) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top,
        ) {

            MilkPeriodItem(Icons.sagmal, "summilk_sutmik".tr(), milk.milk)
            //MilkPeriodItem(Icons.biberon, "summilk_buzmik".tr(), milk.buz)
            //MilkPeriodItem(Icons.gugum, "summilk_kalan".tr(), milk.milk - milk.buz)
            Box(
                modifier = Modifier.fillMaxWidth(96.percent)
                    .height(1.px)
                    .margin(topBottom = 4.px)
                    .backgroundColor(Colors.Gray)
            ) { }
            MilkPeriodItem(null, "summilk_cowsut".tr(), milk.percow)
            MilkPeriodItem(null, "summilk_dairy".tr(), milk.perday)
            MilkPeriodItem(null, "summilk_period_cowavg".tr(), (milk.days * milk.percow).rountTo(1))
            //MilkPeriodItem(null, "summilk_cowsay".tr(), milk.sagmal)
            //MilkPeriodItem(null, "summilk_gelir".tr(), milk.gelir)
        }
    }

}