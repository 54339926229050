package com.kelimesoft.cowmaster.models

enum class Screens(
    val path: String
){
    Home("/"),
    Summary(path = "/ozet"),

    Milk(path = "/sut"),
    Finance(path = "/gelir-gider"),
    Profile(path = "/profil"),
    Settings(path = "/ayarlar"),
    Notifs(path = "/bildirim"),
    Events(path = "/durumliste"),
    Dismiss(path = "/cikan"),
    Splash(path = "/splash"),
    Login(path = "/login"),
    NoPremium(path = "/nopremium"),
    CattleList(path = "/liste"),
    Page404(path = "/page404"),
    Yukselt(path = "/yukselt"),
    /*
    CattleHastaList(path = "/liste-hasta"),
    CattleGebeList(path = "/liste-gebe"),
    CattleSagmalList(path = "/liste-sagmal"),
    CattleKuruList(path = "/liste-kuru"),
    CattleTohumList(path = "/liste-tohum"),
    CattleSagmalList(path = "/liste-fresh"),
    CattleHastaList(path = "/liste-open"),
    CattleGebeList(path = "/liste-gebe"),
    CattleSagmalList(path = "/liste-sagmal"),*/

}


