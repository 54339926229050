package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.SummaryViewModel
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowDown
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowUp
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date


@Composable
fun NotifSummary(notifList: ArrayList<ListNotif>) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .maxHeight(300.px)
            .overflow { y(Overflow.Auto) }
            .scrollBehavior(ScrollBehavior.Smooth)
            .styleModifier {
                property("scrollbar-width", "thin")
            }
            .padding(leftRight = 4.px),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {
        notifList.forEach { notif ->
            NotifRow(notif)
        }

    }

}
@Composable
fun NotifRow(notif: ListNotif) {

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                .padding(2.px),
            contentAlignment = Alignment.TopEnd
        ) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(leftRight = 6.px)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Box(
                    modifier = Modifier
                        .size(24.px)
                        .borderRadius(r = 50.percent)
                        .backgroundColor(notif.notifColor())
                        .margin(right = 8.px)
                )

                Column(modifier = Modifier.fillMaxWidth()) {
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(14.px)
                            .margin(1.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        Span(attrs = Modifier
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()) {
                            Text(notif.title.tr())
                        }
                        Text(", ")
                        Text(notif.kupe)



                    }
                    P(
                        attrs = Modifier
                            .fillMaxWidth()
                            .textAlign(textAlign = TextAlign.Start)
                            .fontSize(13.px)
                            .color(AppColors.TextColor)
                            .fontWeight(FontWeight.Normal)
                            .margin(1.px)
                            .opacity(90.percent)
                            .toAttrs()
                    ) {
                        Text(notif.text.tr().Sprintf(Date(notif.edate).toLocaleDateString()))

                    }
                }

            }
            P(
                attrs = Modifier
                    //.fillMaxWidth(37.percent)
                    .margin(right = 4.px)
                    .textAlign(textAlign = TextAlign.End)
                    .fontSize(14.px)
                    .color(notif.notifColor())
                    .fontWeight(FontWeight.Normal)
                    .padding(2.px)
                    .backgroundColor(AppColors.Gray.copyf(alpha = 0.2f))
                    .borderRadius(r = 6.px)
                    .zIndex(1)
                    .toAttrs()
            ) {
                Text(Date(notif.start).toLocaleDateString())
            }
        }
        VDivider()
    }

}
