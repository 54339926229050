package com.kelimesoft.cowmaster.models

import kotlinx.serialization.Serializable

@Serializable
data class HerdItem(
    var id: Long = -1L,
    var name: String = "",
    var color: Int = (1..24).random(),
    var count: Int = 0
)
//herd":{"id":3590360504,"name":"sağılır","clr":22,"numbers":0}