package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.cowmaster.models.AppColors
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import kotlinx.browser.window
import kotlinx.coroutines.*
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.Promise

enum class ToastPos {
    Top, Bottom, Center
}

@OptIn(DelicateCoroutinesApi::class)
@Composable
fun ToastView(text: String,
              pos: ToastPos = ToastPos.Bottom,
              duration: Long = 4000,
              color: CSSColorValue = AppColors.GrayDark, onClose: () -> Unit) {

    LaunchedEffect(true){
        delay(duration)
        onClose()
    }

    Box(
        modifier = Modifier.fillMaxSize()
            .pointerEvents(PointerEvents.None)
            //.backgroundColor(Colors.Transparent)
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.05f))
            .zIndex(99),
        contentAlignment = when (pos) {
            ToastPos.Top -> Alignment.TopCenter
            ToastPos.Bottom -> Alignment.BottomCenter
            else -> Alignment.Center
        }
    ) {
        Column(
            modifier = Modifier
                .minWidth(280.px)
                .margin(topBottom = 120.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
           P(attrs = Modifier
               .margin(topBottom = 10.px)
               .padding(8.px)
               .color(Colors.White)
               .fontSize(16.px)
               .backgroundColor(color)
               .opacity(75.percent)
               .borderRadius(r = 10.px)
               .toAttrs()

           ){
               Text(text)
           }
        }

    }
}


fun sleep(ms: Int): Promise<Unit> {
    return Promise<Unit> { resolve, _ ->
        window.setTimeout({
            resolve(Unit)
        }, ms)
    }
}

suspend fun sleep1(ms: Int, onClose: () -> Unit) = suspendCoroutine { continuation ->
    window.setTimeout({
        continuation.resume(Unit)
        onClose()
    }, ms)

}