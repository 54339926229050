package com.kelimesoft.cowmaster.models

import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown

enum class CowOrder(
    val text: String,
    val sql: String
) {
    Kupe("Küpe", "kupe_asc"),
    KupeDown("Küpe", "kupe_desc"),
    Isim("İsim", "isim_asc"),
    IsimDown("İsim", "isim_desc"),
    Dogum("DoğumTrh", "dogum_asc"),
    DogumDown("DoğumTrh", "dogum_desc"),
    Uyari("Uyarı", "uyari_asc"),
    Kayit("Kayıt", "kayit_asc"),
    KayitDown("Kayıt", "kayit_desc")
}
