package com.kelimesoft.cowmaster.pages.milk

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.RowButton
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors

import com.kelimesoft.cowmaster.models.MilkGrouping
import com.kelimesoft.cowmaster.models.TotalMilk
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.styles.DateSelectStyle
import com.kelimesoft.cowmaster.utils.round
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.MilkVM
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf

import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import kotlinx.coroutines.launch

import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import kotlin.js.Date

@Composable
fun DairyMilkList(milkVM: MilkVM) {
    val breakpoint = rememberBreakpoint()
    var milkGrouping = remember { mutableStateOf(MilkGrouping.Daily) }

    Box(
        modifier = Modifier.fillMaxWidth()
            .overflow(Overflow.Hidden)
            .padding(leftRight = 4.px)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .minWidth(if (breakpoint > Breakpoint.SM) 320.px else 260.px)
                .overflow(Overflow.Hidden)
                .padding(leftRight = 4.px)
                .backgroundColor(Colors.White)
        ) {
            DairyMilkListBar(milkGrouping)

            DairyMilkContent(milkVM, milkGrouping.value)
        }
        if (breakpoint > Breakpoint.SM) {
            Box(modifier = Modifier.width(2.px)) { }

        } else {
            Box(modifier = Modifier.width(4.px)) { }
        }
    }

}


@Composable
fun DairyMilkContent(milkVM: MilkVM, grup: MilkGrouping) {
    val scope = rememberCoroutineScope()

    var milkList: List<TotalMilk> by remember { mutableStateOf(listOf()) }

    LaunchedEffect(grup, milkVM.dairyMilkList) {
        if (grup == MilkGrouping.Daily) {
            milkList = milkVM.dairyMilkList
        } else {
            milkList = milkVM.groupDailyMilk(milkVM.dairyMilkList)
        }
    }
    fun screenHeight(): Int {
        return window.innerHeight
    }

    //CowSearch(search)
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(160.px)
            .maxHeight((screenHeight() * 0.54).px)
            .color(AppColors.TextColor)
            .padding(leftRight = 6.px)
            .overflow { y(Overflow.Auto) }
            .overflow { x(Overflow.Hidden) }

    ) {

        Row(
            modifier = Modifier.fillMaxWidth()
                .fontSize(14.px)
                .padding(top = 2.px)
        ) {
            SpanText(
                "milkview_date".tr(), Modifier.fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .weight(1f)
                    .padding(leftRight = 6.px, topBottom = 4.px)
                    .border(0.5.px, color = AppColors.TextColor.opacity(0.4f), style = LineStyle.Solid)
            )
            SpanText(
                "milkview_sagilan".tr(), Modifier.fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .weight(1f)
                    .padding(leftRight = 6.px, topBottom = 4.px)
                    .border(0.5.px, color = AppColors.TextColor.opacity(0.4f), style = LineStyle.Solid)
            )
            SpanText(
                "milkview_buz".tr(), Modifier.fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .weight(1f)
                    .padding(leftRight = 6.px, topBottom = 4.px)
                    .border(0.5.px, color = AppColors.TextColor.opacity(0.4f), style = LineStyle.Solid)
            )
            SpanText(
                "milkview_kalan".tr(), Modifier.fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .weight(1f)
                    .padding(leftRight = 6.px, topBottom = 4.px)
                    .border(0.5.px, color = AppColors.TextColor.opacity(0.4f), style = LineStyle.Solid)
            )
        }

        milkList.forEach { dmilk ->
            //(n.bastrh <= '%s' AND n.bittrh >= '%s'), son, ilk
            DairyMilkRow(dmilk, onDelete = {
                scope.launch {
                    milkVM.deleteDairyMilk(dmilk)
                }
            })
        }
    }

}


@Composable
fun DairyMilkRow(milk: TotalMilk, onDelete: () -> Unit) {
    var showButtons by remember { mutableStateOf(false) }
    var expanded by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier.fillMaxWidth()
            .color(AppColors.TextColor),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 4.px)
                .fillMaxWidth()
                .thenIf(condition = milk.id > 0L) {
                    Modifier.onMouseOver {
                        showButtons = true
                    }
                        .onMouseLeave {
                            showButtons = false
                        }
                },
            verticalAlignment = Alignment.CenterVertically,
        ) {

            Box(
                modifier = Modifier.fillMaxWidth()
                    .weight(1f),
                contentAlignment = Alignment.CenterStart
            ) {

                if (milk.id == 0L) {
                    Box(modifier = DateSelectStyle.toModifier()
                        .padding(2.px)
                        .onClick {
                            expanded = !expanded
                        }
                        .margin(2.px)
                        .size(24.px), contentAlignment = Alignment.Center
                    ) {
                        if (expanded) {
                            FaChevronDown(size = IconSize.SM)
                        } else {
                            FaChevronRight(size = IconSize.SM)
                        }
                    }

                }

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    SpanText(
                        Date(milk.mdate!!).toLocaleDateString(),
                        modifier = Modifier
                            .fontSize(13.px)
                            .textAlign(TextAlign.Right)
                            .margin(right = 4.px)
                    )
                    if (milk.id > 0L) {
                        SpanText(
                            milk.period?.text ?: "",
                            modifier = Modifier
                                .fontSize(0.7.cssRem)
                                .margin(right = 2.px)
                        )
                    }
                }

                if (showButtons) {
                    Row(
                        horizontalArrangement = Arrangement.Start
                    ) {
                        RowButton {
                            FaTrash(
                                modifier = Modifier.color(AppColors.DarkRed.opacity(0.9f))
                                    .onClick {
                                        onDelete()
                                    },
                                size = IconSize.SM
                            )
                        }

                    }
                }
            }


            SpanText(
                milk.milk?.round(1) ?: "0.0",
                Modifier.fillMaxWidth()
                    .fontSize(13.px)
                    .textAlign(TextAlign.End)
                    .weight(1f),
            )
            SpanText(
                milk.buz?.round(1) ?: "0.0",
                Modifier.fillMaxWidth()
                    .fontSize(13.px)
                    .textAlign(TextAlign.End)
                    .weight(1f),
            )
            SpanText(
                (milk.milk ?: 0.0).minus(milk.buz ?: 0.0).round(1),
                Modifier.fillMaxWidth()
                    .fontSize(14.px)
                    .textAlign(TextAlign.End)
                    .weight(1f),
            )


        }
        VDivider()
    }

}