package com.kelimesoft.cowmaster.pages.eventlist

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.CircleBox
import com.kelimesoft.cowmaster.components.PieChart
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.cowdetails.DetailsButton
import com.kelimesoft.cowmaster.styles.DetailButtonStyle
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.EventListVM
import com.kelimesoft.cowmaster.models.EventGrup
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text

/*
@Composable
fun EventsContent(eventListVM: EventListVM){
    val breakpoint = rememberBreakpoint()
    Column(modifier = Modifier
        //bu modifier bilgisini eklediğimde aşağıdaki scroll overflow ancak çalıştı
        .overflow(Overflow.Hidden)
        .fillMaxSize(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        EventListBar()
        Column(modifier = Modifier
            .padding(leftRight = if(breakpoint <= Breakpoint.SM) 2.px else 8.px, topBottom = 2.px)
            .overflowY(Overflow.Auto)
            .overflowX(Overflow.Hidden)
            .fillMaxSize()
            .scrollBehavior(ScrollBehavior.Smooth)
            .styleModifier {
                property("scrollbar-width", "thin")
            }) {

            SimpleGrid(numColumns = numColumns(base = 1, sm = 2), modifier = Modifier.fillMaxWidth()){
                EventsDateGroup(eventListVM)

                EventListView(
                    modifier = Modifier.fillMaxWidth(60.percent).margin(top = 6.px, left = 6.px),
                    breakpoint,
                    eventListVM, onAddNotif = {
                        //showAddNotif = true
                    }
                )
            }
        }
    }
}
*/


@Composable
fun EventGroupChart(eventListVM: EventListVM, selected: String, onSelect: (String) -> Unit){
    //val colors by remember { mutableStateOf(listOf(AppColors.Primary, Colors.Red)) }
    var sayiList by remember { mutableStateOf(listOf<Double>()) }
    //var colors by remember { mutableStateOf(listOf<Color>()) }
    var grupList by remember { mutableStateOf(mapOf<String, List<ListEvent>>()) }
    //var chartList by remember { mutableStateOf(listOf<ChartData>()) }
    LaunchedEffect(eventListVM.eventGrupList){
        val slist = mutableListOf<Double>()
        grupList = eventListVM.eventList.groupBy { it.cat }
        grupList.forEach {
            slist.add(it.value.size.toDouble())
        }
        sayiList = slist
    }
    Row(
        modifier = Modifier.fillMaxSize()
            .minHeight(180.px)
            .overflow(Overflow.Hidden)
            .maxHeight(360.px)
            .padding(4.px),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        PieChart(modifier = Modifier
            .minWidth(120.px)
            .weight(0.7f),
            data = sayiList)
        //GelirInfo(finance)
        Column(
            modifier = Modifier.fillMaxSize()
                .overflow(Overflow.Hidden)
                .padding(4.px),
        ) {


            Column(
                modifier = Modifier.fillMaxWidth()
                    .maxHeight(200.px)
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Unset)
                    .scrollBehavior(ScrollBehavior.Smooth)
                    .styleModifier {
                        property("scrollbar-width", "thin")
                    }
                    .padding(4.px),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Top
            ) {
                eventListVM.eventGrupList.forEach {
                    EventGrupRow(it, selected) {
                        onSelect(it.cat)
                    }
                }


            }
            if (selected != "all"){
                AllSelectButton("evt.selectall".tr()){
                    onSelect("all")
                }
            }
        }

            /*
            Row(modifier = Modifier.fillMaxWidth()
                .onClick {
                    onSelect("all")
                }
                .cursor(Cursor.Pointer)
                .margin(2.px)
                .padding(8.px)
                .thenIf(selected == "all"){
                    Modifier.background(AppColors.Gray.opacity(0.1f))
                },
                verticalAlignment = Alignment.CenterVertically)
            {

                SpanText("diss_toplam".tr(), modifier = Modifier.color(AppColors.TextColor)
                    .fontSize(14.px)
                    .padding(left = 10.px)
                    .fontWeight(FontWeight.SemiBold)
                )
                Spacer()
                SpanText(eventListVM.eventList.size.toString(),
                    modifier = Modifier
                        .padding(right = 10.px)
                        .color(AppColors.TextColor)
                    .fontSize(14.px).fontWeight(
                    FontWeight.SemiBold)
                    .margin(right = 12.px))
            }

             */


    }

}


@Composable
fun EventListBar(breakpoint: Breakpoint, onAdd: () -> Unit) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .fontFamily(Values.FONT_FAMILY)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .padding(6.px)
            .minWidth(240.px)
            .backgroundColor(AppColors.BarColor)
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText("evt_list_title".tr(), modifier = Modifier
            .color(AppColors.TextColor)
            .fontSize(14.px)
            .fontWeight(FontWeight.SemiBold)
            .padding(2.px)
        )

        DetailsButton(
            if (breakpoint > Breakpoint.SM) "evt_list_add".tr() else " + "
        ){
            onAdd()
        }


    }
}

@Composable
fun EventGrupRow(grup: EventGrup, selected: String, onSelect: () -> Unit){
    Column(modifier = Modifier.fillMaxWidth()
        .cursor(Cursor.Pointer)
        .thenIf(selected == grup.cat){
            Modifier.background(AppColors.Gray.opacity(0.1f))
        }
        .onClick {
                 onSelect()
        }
        , horizontalAlignment = Alignment.Start
    ) {
        Row(modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically) {
            CircleBox(modifier = Modifier
                .background(grup.color)
                .padding(4.px)
                .margin(right = 4.px)
                .size(22.px)) {
                Image(grup.icon, modifier = Modifier.size(14.px))
            }
            SpanText(grup.title, modifier = Modifier.color(AppColors.TextColor).fontSize(14.px))
            Spacer()
            SpanText(grup.sayi.toString(), modifier = Modifier.color(AppColors.TextColor).fontSize(14.px).fontWeight(
                FontWeight.SemiBold)
                .margin(left = 8.px))
        }
        VDivider()
    }

}

@Composable
fun AllSelectButton(title: String, onClick: () -> Unit) {
    Button(
        attrs = DetailButtonStyle.toModifier()
            .whiteSpace(WhiteSpace.NoWrap)
            .onClick {
                onClick()
            }
            .fontSize(13.px)
            .height(23.px)
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.8f))
            .color(Colors.White)
            .margin(top = 2.px)
            .toAttrs()
    ) {
        Text(title)
    }
}




