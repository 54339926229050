package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.AddNewHerd
import com.kelimesoft.cowmaster.components.AlertBox
import com.kelimesoft.cowmaster.components.AlertInfo
import com.kelimesoft.cowmaster.models.HerdItem
import com.kelimesoft.cowmaster.styles.HerdSelectButtonStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.EmptyCoroutineContext.get


@Composable
fun HerdListView(selectable: Boolean = false, cowHerd: HerdItem? = null, onSelect: ((HerdItem?) -> Unit)? = null, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    val sumVM = AppData.summaryViewModel
    var selectHerd: HerdItem? by remember { mutableStateOf(null) }

    var boxAlert: AlertInfo? by remember { mutableStateOf(null) }
    var showDialog by remember { mutableStateOf(false) }

    LaunchedEffect(true, selectable) {
        sumVM.getHerdList()
    }

    Column(
        modifier = Modifier.fillMaxWidth(96.percent)
            .padding(4.px)
            .backgroundColor(Colors.White)
            .borderRadius(r = 10.px)
            .boxShadow(2.px, (-1).px, 2.px, color = AppColors.Gray.copyf(0.5f))
            .minHeight(300.px)
            .maxHeight(500.px)
            .overflow(Overflow.Hidden)
            .maxWidth(480.px),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        DialogTitle("herdlist_title".tr()) {
            onClose()
        }
        VDivider()
        Column(
            modifier = Modifier
                .fillMaxSize()
                .overflow { y(Overflow.Auto) }
                .padding(top = 6.px, leftRight = 6.px)
                .backgroundColor(Colors.Gray.copyf(alpha = 0.05f))
                //.boxShadow((-1).px, (-1).px, 2.px, 0.px, color = Colors.Gray.copyf(alpha = 0.4f), inset = true)
        ) {
            sumVM.herdList?.forEach { herdGrup ->
                HerdRow(herdGrup, selectable, cowHerd == herdGrup,
                    onSelect = { herd ->
                        if (onSelect != null) {
                            onSelect(herd)
                        }
                    },
                    onDelete = {
                    boxAlert = AlertInfo(title = "herdlist_sil".tr().Sprintf(herdGrup.name),
                    text = "herdlist_siltext".tr(),
                    confirmText = "shared_sil".tr(), onClose = {
                        boxAlert = null
                    }, onConFirm = {
                        scope.launch {
                            sumVM.delHerd(herdGrup)
                            boxAlert = null
                        }
                    })
                }, onEdit = {
                    selectHerd = herdGrup
                    showDialog = true
                })
            }
        }
        Spacer()
        Button(attrs = TextButtonStyle.toModifier()
            .fontSize(14.px)
            .fontWeight(FontWeight.SemiBold)
            .color(AppColors.DetailBlue)
            .onClick {
                selectHerd = null
                showDialog = true
            }

            .toAttrs()
        ) {
            Text("herdlist_add".tr())
        }
    }

    if (showDialog) {
        DialogView {
            AddNewHerd(selectHerd) {
                showDialog = false
            }
        }
    }

    if (boxAlert != null ) {
        DialogView {
            AlertBox(boxAlert!!)
        }
    }
}

@Composable
fun HerdRow(herdGrup: HerdItem, selectable: Boolean, inThis: Boolean = false, onSelect: (HerdItem?) -> Unit,
            onDelete: () -> Unit, onEdit: () -> Unit) {
    var showButons by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier.fillMaxWidth()
            .margin(bottom = 2.px)
            .padding(topBottom = 6.px, leftRight = 4.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(modifier = Modifier.fillMaxWidth()
            .onMouseOver {
                showButons = true
            }
            .onMouseLeave {
                showButons = false
            },
            verticalAlignment = Alignment.CenterVertically
        ) {
            //console.log("herd", herdGrup)
            Box(
                modifier = Modifier
                    .size(24.px)
                    .borderRadius(r = 50.percent)
                    .backgroundColor(if (herdGrup.color > 0) AppColors.herdColorList[herdGrup.color - 1] else Colors.Transparent)
                    .margin(right = 8.px)
            )
            Span(
                attrs = Modifier
                    .fontSize(16.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.Medium)
                    .toAttrs()
            ) {
                Text(herdGrup.name)
            }
            Spacer()

            if (selectable) {
                Row(horizontalArrangement = Arrangement.End
                ) {
                    if (inThis) {
                        Span(
                            attrs = HerdSelectButtonStyle.toModifier()
                                .color(AppColors.Red)
                                .onClick {
                                    onSelect(null)
                                }
                                .toAttrs()
                        ) {
                            Text("Seçimi Kaldır")
                        }
                    }else {
                        Span(
                            attrs = HerdSelectButtonStyle
                                .toModifier()
                                .onClick {
                                    onSelect(herdGrup)
                                }
                                .toAttrs()
                        ) {
                            Text("Seç")
                        }

                    }
                }
            }else if (showButons) {
                Row(
                    modifier = Modifier.width(90.px),
                    horizontalArrangement = Arrangement.End
                ) {
                    RowButton {
                        FaTrash(
                            modifier = Modifier.color(AppColors.DarkRed.copyf(0.9f))
                                .onClick {
                                    onDelete()
                                },
                            size = IconSize.SM
                        )
                    }
                    RowButton {
                        FaEdit(modifier = Modifier.color(Colors.DarkGray)
                                .onClick {
                                    onEdit()
                                },
                            size = IconSize.SM
                        )
                    }

                }
            }

            Span(
                attrs = Modifier
                    .width(80.px)
                    .fontSize(16.px)
                    .textAlign(TextAlign.End)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.SemiBold)
                    .toAttrs()
            ) {
                Text(herdGrup.count.toString())
            }
        }
        VDivider()
    }
}


