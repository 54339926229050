package com.kelimesoft.cowmaster.viewmodals

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.network.*
import com.kelimesoft.cowmaster.pages.cowdetails.DisSebep
import com.kelimesoft.cowmaster.utils.AppStorage
import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.utils.dateDiff
import com.kelimesoft.cowmaster.viewmodals.CowDetailViewModel
import kotlinx.browser.localStorage
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlin.js.Date

enum class ListSelect {
    none, all, hasta, gebe, sagmal, kuru, tohum, fresh, open, inek, duve, tosun, dana, buzagi
}

class CowListViewModel: BaseVM() {
    var latestScreen = ListSelect.none
    var selectedList = ListSelect.all
    var cowList: MutableList<ListCow> by mutableStateOf(mutableListOf())
    var selectedCow: ListCow? by mutableStateOf(null)
        private set
    var cowOrder: CowOrder by mutableStateOf(CowOrder.Kayit)
    var loadingDetails by mutableStateOf(false)
    var cowDetailVM = CowDetailViewModel()
    var selectHerdSet: MutableSet<HerdItem> by mutableStateOf(mutableSetOf())

    init {
        cowOrder = CowOrder.valueOf(localStorage.getItem(AppStorage.cowOrder) ?: "Kayit")
    }

    suspend fun getList() {
        if (!checkUser()) {
            return
        }
        if (selectedList == latestScreen) {
            return
        } else {
            selectedCow = null
            cowList = mutableListOf()
        }
        latestScreen = selectedList
        getCowList()
    }

    suspend fun cowDetayUpdated(cow: EditedCow, updateState: Boolean) {
        //cowList = mutableListOf()
        if(updateState){
            updateSingleCow(selectedCow!!.id)
            AppData.summaryViewModel.resetRequestCowsAdded()
            cowDetailVM.getCowDetails()
        }else{
            cow.kupe?.let {
                cowDetailVM.cowDetay?.kupe = it
            }
            cow.name?.let {
                cowDetailVM.cowDetay?.name = it
            }
            cow.dam?.let {
                cowDetailVM.cowDetay?.dam = it
            }
            cow.sire?.let {
                cowDetailVM.cowDetay?.sire = it
            }
            cow.breed?.let {
                cowDetailVM.cowDetay?.breed = it
            }
            cow.notes?.let {
                cowDetailVM.cowDetay?.notes = it
            }

        }


    }




    suspend fun updateSingleCow(hid: Long) {
            cowList.indexOfFirst { it.id == hid }.let {
                val newList = cowList.toMutableList()
                getSingleCow(selectedCow!!.id)?.let { cow ->
                    newList[it] = cow
                    cowList = newList
                    selectedCow = cow
                }
            }
    }

    fun updateSingleCowHerd(hid: Long, herd: HerdItem?) {
        cowList.indexOfFirst { it.id == hid }.let {
            val newList = cowList
            newList[it].herd = herd
            cowList = newList
        }
    }

    suspend fun getCowList(page: Int = 1) {
        if (page == 1) {
            AppData.appViewModel.showProgress = true
        }
        try {
            val json = Json {
                ignoreUnknownKeys = true
            }
            RestApi.getCowList(selectedList.name, page, cowOrder.sql)?.let { res ->
                json.decodeFromString<CowListResponse>(res).let { lres ->
                    lres.data?.let { listData ->
                        if (listData.cowlist != null) {
                            if (page == 1) {
                                selectHerdSet = mutableSetOf()
                                cowList = listData.cowlist!!
                                //addToHerdSet(listData.cowlist!!)
                            } else {
                                cowList.addAll(listData.cowlist!!)
                                addToHerdSet()
                            }
                            if (page == 1 && listData.count > 60) {
                                getCowList(2)
                            } else {
                                addToHerdSet()
                            }
                        }
                        //console.log("listData: ", listData)
                    }.run {
                        if (!lres.error.isNullOrEmpty()) {
                            AppData.appViewModel.setPage(lres.error)
                        }
                    }
                }
            }
        } finally {
            AppData.appViewModel.showProgress = false
        }

    }

    private suspend fun getSingleCow(hid: Long): ListCow? {
        val json = Json {
            ignoreUnknownKeys = true
        }
        RestApi.getSingleCow(hid)?.let { res ->
            json.decodeFromString<SingleCowResponse>(res).let { cres ->
                cres.data?.let { cow ->
                    return cow
                }
            }
        }
        return null


    }

    fun chanceSort(order: CowOrder) {
        if (order != cowOrder) {
            cowOrder = order
            sortCowlist()
            AppStorage.setString(AppStorage.cowOrder, cowOrder.name)
        }
    }

    private fun sortCowlist() {
        when (cowOrder) {
            CowOrder.Kupe -> {
                cowList = cowList.sortedBy { it.kupe }.toMutableList()
            }

            CowOrder.KupeDown -> {
                cowList = cowList.sortedByDescending { it.kupe }.toMutableList()
            }

            CowOrder.Isim -> {
                cowList = cowList.sortedBy { it.name }.toMutableList()
            }

            CowOrder.IsimDown -> {
                cowList = cowList.sortedByDescending { it.name }.toMutableList()
            }

            CowOrder.Uyari -> {
                cowList = cowList.sortedBy { it.uyari }.toMutableList()
            }

            CowOrder.Dogum -> {
                cowList = cowList.sortedBy { it.birth }.toMutableList()
            }

            CowOrder.DogumDown -> {
                cowList = cowList.sortedByDescending { it.birth }.toMutableList()
            }

            CowOrder.Kayit -> {
                cowList = cowList.sortedBy { it.id }.toMutableList()
            }

            CowOrder.KayitDown -> {
                cowList = cowList.sortedByDescending { it.id }.toMutableList()
            }
        }
    }

    private fun addToHerdSet() {
        val newSet = mutableSetOf<HerdItem>()
        cowList.forEach {
            it.herd?.let { herd ->
                if (herd.id > 0) {
                    newSet.add(herd)
                }
            }

        }
        selectHerdSet = newSet
        //console.log("selectherdset: ", selectHerdSet.toString())
    }


    suspend fun dismissCow(sebep: DisSebep, notes: String, date: String): Boolean {
        if (!Funcs.checkForm("dismiss-form")) {
            return false
        }
        try {
            RestApi.disposeCow(selectedCow!!.id, sebep,  notes, date)?.let { res ->
                //console.log("cowdetay:", res)
                Json.decodeFromString<BoolResponse>(res).let { dres ->
                    if (dres.data != null && dres.data) {
                        cowList.indexOfFirst { it.id == selectedCow!!.id }.let {
                            val newList = cowList.toMutableList()
                            newList.removeAt(it)
                            cowList = newList.toMutableList()
                        }
                        selectedCow = null
                        AppData.summaryViewModel.resetRequestCowDismissed()
                        return true
                    }
                }
            }
        } catch (e: Exception) {
            console.log(e.toString())
        }
        return false

    }


    suspend fun deleteCow(delMilk: Boolean): Boolean {
        try {
            RestApi.deleteCow(selectedCow!!.id, delMilk)?.let { res ->
                //console.log("cowdetay:", res)
                Json.decodeFromString<BoolResponse>(res).let { dres ->
                    if (dres.data != null && dres.data) {
                        cowList.indexOfFirst { it.id == selectedCow!!.id }.let {
                            val newList = cowList.toMutableList()
                            newList.removeAt(it)
                            cowList = newList.toMutableList()
                        }
                        selectedCow = null
                        AppData.summaryViewModel.resetRequestCowDelete()
                        return true
                    }
                }
            }
        } catch (e: Exception) {
            console.log(e.toString())
        }
        return false

    }


    fun updateSelected(cow: ListCow) {
        if (cow != selectedCow) {
            selectedCow = cow
        }
    }


    fun addNewCowToList(cow: NewListCow){
        val snf = setCowSnf(Date(cow.dogum), cow.gender)
        val lcow = ListCow(id = cow.hid, kupe = cow.kupe, name = cow.name, birth = cow.dogum, snf = snf)
        cowList.add(lcow)
    }

    private fun setCowSnf(birth: Date, gender: Int): Int {
        val kacGunluk = dateDiff(Date(), birth, "d")//birth.differenceInDays(today)
        var sinifi = 5 // inek
        when {
            kacGunluk <= 182 -> sinifi = 1 // buzağı
            kacGunluk <= 365 -> sinifi = 2 // dana
            gender == 1 -> sinifi = 3 // tosun
            kacGunluk < 851 -> sinifi = 4 // düve
        }
        return sinifi
    }


}


