package com.kelimesoft.cowmaster.models

enum class MilkTypeScreen {
    Cow, Dairy
}

enum class MilkGrouping {
    Daily, Permilking
}

enum class CowMilkGroup {
    Milking, Day, Cow
}