package com.kelimesoft.cowmaster.pages.dismiss

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Icons
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.utils.yasiniBul
import com.kelimesoft.cowmaster.viewmodals.DismissVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaRightFromBracket
import com.varabyte.kobweb.silk.components.icons.fa.FaTrashCan
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import kotlin.js.Date

@Composable
fun DismissCowDetay(dismissVM: DismissVM) {
    val scope = rememberCoroutineScope()
    var showDeleteCow by remember { mutableStateOf(false) }
    var showBackCow by remember { mutableStateOf(false) }

    Box(
        modifier = Modifier.padding(2.px),
        contentAlignment = Alignment.TopCenter
    ) {
        dismissVM.selectedCow?.let { cowDetay ->
            SummaryCard(
                title = "diss_dty_title".tr(),

                ) {
                DismissInfoLine("detay_kupe".tr(), cowDetay.kupe, kupe = true)
                DismissInfoLine("detay_name".tr(), cowDetay.name)
                DismissInfoLine(
                    "detay_gender".tr(),
                    if (cowDetay.gender == 1) "shared_male".tr() else "shared_female".tr()
                )
                DismissInfoLine("detay_birth".tr(), Date(cowDetay.birth).toLocaleDateString())
                //InfoLine("detay_irk".tr(), cowDetay.)
                DismissInfoLine("diss_cikyasi".tr(), cowDetay.birth.yasiniBul(Date(cowDetay.ddate)))
                DismissInfoLine("diss_ciktrh".tr(), Date(cowDetay.ddate).toLocaleDateString())
                DismissInfoLine("diss_ciksebep".tr(), cowDetay.sebep.tr(), sebep = true)
                DismissInfoLine("diss_ciknotes".tr(), cowDetay.notes, sebep = true)
                DismissInfoLine("detay_anne".tr(), cowDetay.dam)
                DismissInfoLine("detay_baba".tr(), cowDetay.sire)
                //InfoLine("detay_anne".tr(),cowDetay.anne)
                //InfoLine("detay_baba".tr(), cowDetay.baba, true)

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(leftRight = 8.px, bottom = 8.px),
                    horizontalArrangement = Arrangement.End
                ) {
                    Button(
                        attrs = DetailButtonStyle.toModifier()
                            .margin(leftRight = 6.px)
                            .height(28.px)
                            .minWidth(110.px)
                            .maxWidth(160.px)
                            .onClick {
                                showBackCow = true
                            }
                            .backgroundColor(AppColors.DetailBlue.opacity(0.8f))

                            .color(Colors.White)
                            .toAttrs()

                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            FaRightFromBracket(
                                modifier = Modifier
                                    .margin(right = 4.px)
                                    .styleModifier {
                                        property("transform", "scaleX(-1)")
                                    }
                                    .color(Colors.White),
                                size = IconSize.SM
                            )
                            Text("diss_back_to1".tr())
                        }
                    }

                    Button(
                        attrs = DetailButtonStyle.toModifier()
                            .margin(leftRight = 4.px)
                            .height(28.px)
                            .maxWidth(160.px)
                            .onClick {
                                showDeleteCow = true
                            }
                            .backgroundColor(AppColors.DarkRed.opacity(0.8f))
                            .fontWeight(FontWeight.Medium)
                            .color(Colors.White)
                            .toAttrs()

                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            FaTrashCan(
                                modifier = Modifier
                                    .margin(right = 4.px)
                                    .color(Colors.White),
                                size = IconSize.SM
                            )
                            Text("detay_sil".tr())
                        }
                    }
                }
            }
        }

        if (showDeleteCow) {
            val milky = dismissVM.selectedCow?.getSnfIcon() == Icons.inek
            DialogView {
                DeleteDismissedCow(milky, onDelete = { delMilk ->
                    //console.log("del-request:", delMilk)
                    scope.launch {
                        dismissVM.deleteDismissedCow(delMilk)
                        showDeleteCow = false
                    }
                }, onClose = {
                    showDeleteCow = false
                })

            }

        } else if (showBackCow) {
            DialogView {
                CowBackToDairy(onBack = {
                    scope.launch {
                        dismissVM.dismissCowBack()
                        showBackCow = false
                    }
                }) {
                    showBackCow = false
                }
            }
        }
    }

}

@Composable
private fun DismissInfoLine(title: String, value: String,
                            sebep: Boolean = false, kupe: Boolean = false) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(2.px)
    ) {
        Row(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 2.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Span(
                attrs = DetailInfoTitleStyle
                    .toModifier()
                    .fontSize(15.px)
                    .toAttrs()
            ) {
                Text(title)
            }

            Span(attrs = DetailInfoDataStyle
                .toModifier()
                .fillMaxWidth(64.percent)
                .fontWeight(FontWeight.Medium)
                .thenIf(sebep) {
                    Modifier.color(AppColors.DarkRed)
                }
                .thenIf(kupe) {
                    Modifier.color(AppColors.PrimaryDark)
                }
                .fontSize(16.px)
                .toAttrs()
            ) {
                Span(
                    attrs = DetailInfoTitleStyle
                        .toModifier()
                        .toAttrs()
                ) {
                    Text(": ")
                }
                Text(value)
            }
        }
        VDivider()

    }
}


@Composable
fun CowBackToDairy(onBack: () -> Unit, onClose: () -> Unit) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("detay_dismiss".tr(), onClose = onClose)

        Form(
            attrs = Modifier
                .id("dismiss-form")
                .fillMaxWidth()
                .padding(leftRight = 20.px, topBottom = 40.px)
                .toAttrs()
        ) {
            ErrorText(
                "diss_back_not".tr(),
                Modifier
                    .textAlign(TextAlign.Start)
                    .fontSize(16.px)
                    .padding(10.px)
                    .color(AppColors.TextColor)
            )
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }


                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        .backgroundColor(AppColors.DetailBlue)
                        .onClick {
                            it.preventDefault()
                            onBack()
                        }
                        .toAttrs()

                ) {
                    Text("diss_back_to".tr())
                }
            }
        }


    }

}

@Composable
fun DeleteDismissedCow(milky: Boolean, onDelete: (Boolean) -> Unit, onClose: () -> Unit) {
    var delMilk by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White), horizontalAlignment = Alignment.Start
    ) {

        DialogTitle("diss_ciksil".tr(), onClose = onClose)

        ErrorText(
            "detay_cowsil_not".tr(),
            Modifier
                .textAlign(TextAlign.Start)
                .fontSize(16.px)
                .padding(10.px)
                .color(AppColors.TextColor)
        )
        if (milky) {
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(left = 8.px, top = (-2).px, bottom = 10.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                CheckboxInput(
                    checked = delMilk,
                    attrs = Modifier
                        .size(18.px)
                        .toAttrs()
                        {
                            onChange { elm ->
                                delMilk = elm.value
                            }
                        }
                )
                Label(
                    attrs = Modifier.fontSize(14.px)
                        .color(AppColors.DarkRed.copyf(alpha = 0.7f))
                        .toAttrs()
                ) {
                    Text("detay_cowsil_sut".tr())
                }
            }
        }


        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(top = 20.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Button(
                attrs = TextButtonStyle.toModifier()
                    .fontSize(16.px)
                    .color(AppColors.DarkRed)
                    .onClick {
                        it.preventDefault()
                        onClose()
                    }
                    .toAttrs()

            ) {
                Text("shared_iptal".tr())
            }

            Spacer()
            Button(
                attrs = MainButtonStyle.toModifier()
                    .backgroundColor(AppColors.Red)
                    .onClick {
                        it.preventDefault()
                        onDelete(delMilk)
                    }
                    .toAttrs()

            ) {
                Text("detay_cowsil_full".tr())
            }
        }

    }

}