package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Images.appIcon120
import com.kelimesoft.cowmaster.models.NavButtons
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.styles.LeftPaneButtonStyle
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier

import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun LeftPane(breakpoint: Breakpoint, path: String = Values.imgPath1) {
    val ctx = rememberPageContext()
    ctx.router.addRouteInterceptor {

    }

    Column(
        modifier = Modifier
            .id("left-pane")
            .width(
                if (breakpoint >= Breakpoint.LG) 270.px
                else if (breakpoint >= Breakpoint.MD) 230.px
                else if (breakpoint > Breakpoint.SM) 130.px
                else 100.px
            )
            .fillMaxHeight()
            .backgroundImage(AppColors.GradientGray)
            .boxShadow(1.px, 0.px, 0.px, color = AppColors.Gray),

        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.Start
    ) {

        AppIcon(breakpoint, path)

        NavButtons.entries.forEach { nav ->
            Button(
                attrs = LeftPaneButtonStyle.toModifier()
                    .toAttrs()
            ) {
                ReplaceLink(
                    modifier = Modifier
                        .textAlign(textAlign = TextAlign.Left)
                        .textDecorationLine(TextDecorationLine.None)
                        .fontSize(if (breakpoint > Breakpoint.MD) 16.px else 14.px)
                        .color(Colors.White),
                    path = nav.destination,
                    replaceLink = true

                ) {
                    Row(modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = if (breakpoint <= Breakpoint.SM) Arrangement.Center else Arrangement.Start,
                        verticalAlignment = Alignment.CenterVertically
                        ) {
                        Image(
                            modifier = Modifier
                                .size(
                                    if (breakpoint >= Breakpoint.LG) 40.px
                                    else if (breakpoint >= Breakpoint.MD) 38.px
                                    else if (breakpoint > Breakpoint.SM) 36.px
                                    else 42.px
                                )
                                .margin(right = if (breakpoint <= Breakpoint.SM) 0.px else 10.px,
                                     bottom = 4.px),
                            src = path + nav.icon
                        )
                        if (breakpoint > Breakpoint.SM){
                            Text(nav.title)
                        }
                    }

                }
            }
        }

    }
}


@Composable
fun AppIcon(breakpoint: Breakpoint, path: String) {
    ReplaceLink(
        modifier = Modifier.fillMaxWidth()
            .textDecorationLine(TextDecorationLine.None),
        path = Screens.Home.path){
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(6.px)
                .cursor(Cursor.Pointer)
                .backgroundImage(AppColors.GradientPrimary),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth(),

                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    modifier = Modifier
                        .size(70.px),
                    src = path + appIcon120

                )
                if (breakpoint > Breakpoint.SM) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .maxHeight(90.px)
                            .margin(4.px),
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.Center
                    ) {

                        P(
                            attrs = Modifier
                                .textAlign(textAlign = TextAlign.Center)
                                .fontSize(20.px)
                                .margin(1.px)
                                .color(Colors.White)
                                .toAttrs()
                        ) {
                            Text("Cow Master")
                        }



                        if (breakpoint > Breakpoint.MD) {
                            P(
                                attrs = Modifier
                                    .textAlign(textAlign = TextAlign.Center)
                                    .fontSize(14.px)
                                    .margin(1.px)
                                    .color(Colors.White)
                                    .opacity(60.percent)
                                    .toAttrs()
                            ) {
                                Text("leftpn_app".tr())
                            }
                        }


                    }
                }

            }

            if (breakpoint <= Breakpoint.SM) {
                P(
                    attrs = Modifier
                        .textAlign(textAlign = TextAlign.Center)
                        .fontSize(13.px)
                        .margin(1.px)
                        .color(Colors.White)
                        .opacity(90.percent)
                        .toAttrs()
                ) {
                    Text("Cow Master")
                }
            }
        }
    }

}





