package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.components.LeftPane
import com.kelimesoft.cowmaster.models.AppColors
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.get

@Page("/fileupload")
@Composable
fun FileUpload() {
    val breakpoint = rememberBreakpoint()
    var showAdd = remember { mutableStateOf(false) }
    var username by remember { mutableStateOf("") }
    var fileExist by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                closeDropDowns()
            }
            .backgroundColor(AppColors.Background),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally) {
        Row(
            modifier = Modifier.fillMaxSize()
        ) {

            if (breakpoint >= Breakpoint.SM){
                LeftPane(breakpoint)
            }
            Box(modifier = Modifier
                .fillMaxSize()
                .padding(6.px)
                .backgroundColor(Colors.Gray),
                contentAlignment = Alignment.Center

            ) {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    verticalArrangement = Arrangement.Top,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    P {
                        Text("Cattle List")
                    }

                    Button(attrs = Modifier
                        .draggable(Draggable.True)
                        .onClick {
                            showAdd.value= true
                        }
                        .toAttrs()) {
                        Text("Show Add Cow")
                    }

                    TextInput(value = username, attrs = Modifier
                        .borderRadius(r = 20.px)
                        .margin(5.px)
                        .padding(6.px)
                        .fontSize(20.px)
                        .border(0.px)
                        .textAlign(TextAlign.Left)
                        .onDrop {
                            print("some dopped")
                        }
                        .toAttrs()
                    {
                        onInput { it->
                            username = it.value
                        }
                    })

                    Box(modifier = Modifier
                        .width(100.px)
                        .height(100.px)
                        .backgroundColor(if(fileExist) Color.blue else Color.white)
                        .onDrop {
                            it.preventDefault()
                            //console.log("some dopped")
                            it.dataTransfer?.items?.let { items ->
                                for (i in 0 until items.length){
                                    if(items[i]?.kind == "file"){
                                        val file = items[i]?.getAsFile()
                                    }
                                }
                            }
                        }
                        .onDragOver {
                            it.preventDefault()
                            //fileExist = true
                        }
                        .onDragEnter {
                            it.preventDefault()
                            fileExist = true
                        }
                        .onDragLeave {
                            it.preventDefault()
                            fileExist = false
                        }

                    ){

                    }


                }

                if (showAdd.value){
                    AddCow(showAdd)
                }
            }
        }
        if (breakpoint < Breakpoint.SM){
            BottomNavigation(breakpoint)
        }

    }

}


@Composable
fun AddCow(show: MutableState<Boolean>) {
    Column(modifier = Modifier
        .width(500.px)
        .height(600.px)
        .backgroundColor(Color.magenta),
        horizontalAlignment = Alignment.CenterHorizontally
        )
    {
        Button(attrs = Modifier
            .onClick {
                show.value = false
            }
            .toAttrs()) {
            Text("Kapat")
        }
    }
}