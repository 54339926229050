package com.kelimesoft.cowmaster.components

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.MobilAddButtonStyle
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text

@Composable
fun MobileAddButton(modifier: Modifier = Modifier, title: String) {
    Button(
        attrs = MobilAddButtonStyle.toModifier()
            .position(Position.Fixed)
            .right(10.px)
            .bottom(Values.bottomBarHeight)
            .then(modifier)
            .toAttrs()
    ) {
        Text("shared_add".tr())
    }
}