package com.kelimesoft.cowmaster.models

import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.Date

@Serializable
data class NewCowMilk(
    val hid: Long,
    var milk: Double = 0.0,
    val mdate: String,
    var period: MilkTime = MilkTime.Morning
)

@Serializable
data class NewMilkBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val milk: NewCowMilk
)


@Serializable
data class TotalMilkBinding(
    @SerialName("session-id")
    val sessionId: String = AppData.sessionId,
    val sid: Long = AppData.cowUser?.userSid ?: 0,
    val milk: TotalMilk
)

@Serializable
enum class MilkTime(var text: String) {
    @SerialName("m")
    Morning("milk_morning".tr()),
    @SerialName("n")
    Noon("milk_noon".tr()),
    @SerialName("e")
    Evening("milk_evening".tr())
}


private fun getMilkID(): Long {
    val idStr = Date.now().toLong().toString()
    return idStr.substring(idStr.length - 10).toLong()
}
