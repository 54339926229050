package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Images
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.LoginVM
import com.kelimesoft.cowmaster.components.AlertBox
import com.kelimesoft.cowmaster.components.AlertInfo
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRotateRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

//@Page("/login")
@Composable
fun LoginPage() {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var forgotPassDialog by remember { mutableStateOf(false) }
    var boxAlert: AlertInfo? by remember { mutableStateOf(null) }

    val loginVM = LoginVM()

    LaunchedEffect(true) {
        loginVM.getLoginCaptcha()
    }

    PageBox(
        modifier = Modifier.fillMaxSize()
            .backgroundColor(AppColors.Background)
            .minWidth(240.px)
    ) {
        if (loginVM.showRenewPass){
            formRenewPass(loginVM, scope, breakpoint)
        }else{
            formLogin(loginVM, scope, breakpoint){
                forgotPassDialog = true
            }
        }

        if (forgotPassDialog) {
            DialogView {
                ForgetPass(loginVM, scope, onClose =  {
                    forgotPassDialog = false
                }, onSuccess = {
                    forgotPassDialog = false
                    boxAlert = AlertInfo("login_passsent".tr(), "login_senttext".tr(), "",
                        "shared_tmm".tr(), onClose = {
                        boxAlert = null
                    }){
                        boxAlert = null
                    }
                })
            }
        }

        if (loginVM.errorText.isNotEmpty()) {
            ToastView(loginVM.errorText, pos = ToastPos.Bottom, color = Colors.Red) {
                loginVM.errorText = ""
            }
        }
        if (boxAlert != null ){
            DialogView {
                AlertBox(boxAlert!!)
            }

        }

    }
}


@Composable
fun ForgetPass(loginVM: LoginVM, scope: CoroutineScope,
               onClose: () -> Unit,
               onSuccess: () -> Unit) {

    var sendSuccess by remember { mutableStateOf(false) }

    LaunchedEffect(true) {
        loginVM.getForgotCaptcha()
    }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("login_forgot".tr(), onClose = onClose)


        Form(
            attrs = Modifier
                .id("forgot-form")
                .fillMaxWidth()
                .padding(leftRight = 20.px, topBottom = 40.px)
                .toAttrs()
        ) {
            Input(
                type = InputType.Email,
                attrs = InputStyles.toModifier()
                    .attrsModifier {
                        attr("name", "user")
                        attr("placeholder", "login_eposta".tr())
                        attr("minlength", "6")
                        attr("required", "true")
                    }
                    .toAttrs()
                    {
                        onInput {
                            loginVM.userNameForgot = it.value
                        }
                    }
            )


            if (sendSuccess) {
                Span(
                    attrs = SuccesTextStyle.toModifier()
                        .margin(4.px)
                        .toAttrs()
                ) {
                    Text("Geçici giriş şifreniz e-posta adresinize ve Mobil cihazınıza göndrilmiştir.")
                }
            }else {
                Row(
                    modifier = Modifier.fillMaxWidth()
                        .margin(top = 20.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    if (loginVM.forgetCaptcha.isNotEmpty()) {
                        Span(
                            attrs = CaptchaStyle.toModifier()
                                .margin(left = 8.px, top = 6.px, right = 4.px)
                                .toAttrs()
                        ) {
                            Text(loginVM.forgetCaptcha)
                        }
                        FaArrowRotateRight(
                            modifier = Modifier.color(AppColors.NumBlue)
                                .onClick {
                                    loginVM.getForgotCaptcha()
                                },
                            size = IconSize.SM
                        )

                        Input(
                            type = InputType.Text,
                            attrs = InputCaptchaStyle.toModifier()
                                .attrsModifier {
                                    attr("name", "captcha")
                                    attr("placeholder", "login_captext".tr())
                                    attr("required", "true")
                                }
                                .toAttrs()
                                {
                                    onInput {
                                        loginVM.userForgotCaptcha = it.value
                                    }
                                }
                        )
                    }
                    Spacer()
                    Button(
                        attrs = MainButtonStyle.toModifier()
                            .onClick {
                                it.preventDefault()
                                scope.launch {
                                    val result = loginVM.requestPass()
                                    if(result){
                                        sendSuccess = true
                                        onSuccess()
                                    }
                                }
                            }
                            .toAttrs()

                    ) {
                        Text("login_iste".tr())
                    }
                }
            }
        }
    }
}

@Composable
fun formLogin(loginVM: LoginVM, scope: CoroutineScope,
              breakpoint: Breakpoint, showForgot: () ->Unit){
    var showPass by remember { mutableStateOf(false) }

    Form(
        action = "",
        attrs = Modifier
            .id("login-form")
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD)
                    84.percent else 96.percent
            )
            .padding(topBottom = 20.px, leftRight = 30.px)
            .maxWidth(600.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .toAttrs()
    ) {
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Image(
                modifier = Modifier
                    .borderRadius(r = 8.px)
                    .size(76.px),
                src = Images.appIcon120,
                alt = "cow master herd app login"

            )
        }

        P(
            attrs = Modifier
                .margin(top = 1.px, bottom = 20.px)
                .textAlign(TextAlign.Center)
                .fontSize(19.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("login_giris".tr())
        }


        Input(
            type = InputType.Email,
            attrs = InputStyles.toModifier()
                .attrsModifier {
                    attr("name", "user")
                    attr("placeholder", "login_eposta".tr())
                    attr("minlength", "6")
                    attr("required", "true")
                }
                .toAttrs()
                {
                    onInput {
                        loginVM.userName = it.value
                    }
                }
        )
        Input(
            type = if (showPass) InputType.Text else InputType.Password,
            attrs = InputStyles.toModifier()
                .margin(topBottom = 4.px, leftRight = 8.px)
                .attrsModifier {
                    attr("name", "sifre")
                    attr("placeholder", "login_pass".tr())
                    attr("required", "true")
                    attr("minlength", "6")
                }
                .toAttrs()
                {
                    onInput {
                        loginVM.userPass = it.value
                    }
                }
        )

        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(left = 8.px, top = (-2).px, bottom = 10.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            CheckboxInput(
                checked = showPass,
                attrs = Modifier
                    .size(18.px)
                    .toAttrs()
                    {
                        onChange { elm ->
                            showPass = elm.value
                        }
                    }
            )
            Label(
                attrs = Modifier.fontSize(14.px)
                    .color(AppColors.TextColor.copyf(alpha = 0.9f))
                    .toAttrs()
            ) {
                Text("login_seepass".tr())
            }
        }






        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(0.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (loginVM.loginCaptcha.isNotEmpty()) {
                Span(
                    attrs = CaptchaStyle.toModifier()
                        .margin(left = 8.px, right = 4.px)
                        .toAttrs()
                ) {

                    Text(loginVM.loginCaptcha)

                }
                FaArrowRotateRight(
                    modifier = Modifier.color(AppColors.NumBlue)
                        .onClick {
                            loginVM.getLoginCaptcha()
                        },
                    size = IconSize.SM
                )

                Input(
                    type = InputType.Text,
                    attrs = InputCaptchaStyle.toModifier()
                        .attrsModifier {
                            attr("name", "captcha")
                            attr("placeholder", "login_captext".tr())
                            attr("required", "true")
                        }
                        .toAttrs()
                        {
                            onInput {
                                loginVM.userLoginCaptcha = it.value
                            }
                        }
                )
            }

            Spacer()
            Button(
                attrs = MainButtonStyle.toModifier()
                    .whiteSpace(WhiteSpace.NoWrap)
                    .onClick {
                        it.preventDefault()
                        loginVM.loginUser(scope)
                    }
                    .toAttrs()

            ) {
                Text("login_gir".tr())
            }
        }

        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(top = 20.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround
        ) {
            Button(
                attrs = TextButtonStyle.toModifier()
                    .color(Colors.IndianRed)
                    .fontSize(15.px)
                    .onClick {
                        it.preventDefault()
                        showForgot()

                    }
                    .toAttrs()

            ) {
                Text("login_forgot".tr())
            }
            Button(
                attrs = TextButtonStyle.toModifier()
                    .color(Colors.DeepSkyBlue)
                    .fontSize(15.px)
                    .onClick {
                        it.preventDefault()
                    }
                    .toAttrs()
            ) {
                Text("login_new".tr())
            }
        }
    }
}


@Composable
fun formRenewPass(loginVM: LoginVM, scope: CoroutineScope, breakpoint: Breakpoint){
    var showPass by remember { mutableStateOf(false) }

    Form(
        action = "",
        attrs = Modifier
            .id("renew-form")
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD)
                    84.percent else 96.percent
            )
            .padding(topBottom = 20.px, leftRight = 30.px)
            .maxWidth(600.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .toAttrs()
    ) {
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Image(
                modifier = Modifier
                    .borderRadius(r = 8.px)
                    .size(76.px),
                src = Images.appIcon120,
                alt = "cow master herd app renew pass"

            )
        }

        P(
            attrs = Modifier
                .margin(top = 1.px, bottom = 20.px)
                .textAlign(TextAlign.Center)
                .fontSize(19.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("renew_form".tr())
        }


        Input(
            type = if (showPass) InputType.Text else InputType.Password,
            attrs = InputStyles.toModifier()
                .margin(topBottom = 4.px, leftRight = 8.px)
                .attrsModifier {
                    attr("name", "pass1")
                    attr("placeholder", "renew_pass1".tr())
                    attr("required", "true")
                    attr("minlength", "7")
                }
                .toAttrs()
                {
                    onInput {
                        loginVM.renewPass1 = it.value
                    }
                }
        )

        Input(
            type = if (showPass) InputType.Text else InputType.Password,
            attrs = InputStyles.toModifier()
                .margin(topBottom = 4.px, leftRight = 8.px)
                .attrsModifier {
                    attr("name", "pass2")
                    attr("placeholder", "renew_pass2".tr())
                    attr("required", "true")
                    attr("minlength", "7")
                }
                .toAttrs()
                {
                    onInput {
                        loginVM.renewPass2 = it.value
                    }
                }
        )

        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(left = 8.px, top = (-2).px, bottom = 10.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            CheckboxInput(
                checked = showPass,
                attrs = Modifier
                    .size(18.px)
                    .toAttrs()
                    {
                        onChange { elm ->
                            showPass = elm.value
                        }
                    }
            )
            Label(
                attrs = Modifier.fontSize(14.px)
                    .color(AppColors.TextColor.copyf(alpha = 0.9f))
                    .toAttrs()
            ) {
                Text("login_seepass".tr())
            }
        }

        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(0.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Spacer()
            Button(
                attrs = MainButtonStyle.toModifier()
                    .whiteSpace(WhiteSpace.NoWrap)
                    .onClick {
                        it.preventDefault()
                        loginVM.resetUserPass(scope)

                    }
                    .toAttrs()

            ) {
                Text("renew_change".tr())
            }
        }

    }
}