package com.kelimesoft.cowmaster.pages.summary

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.AddNewHerd
import com.kelimesoft.cowmaster.components.AlertBox
import com.kelimesoft.cowmaster.components.AlertInfo
import com.kelimesoft.cowmaster.models.DeletedCow
import com.kelimesoft.cowmaster.models.HerdItem
import com.kelimesoft.cowmaster.models.opacity
import com.kelimesoft.cowmaster.styles.HerdSelectButtonStyle
import com.kelimesoft.cowmaster.styles.RowButtonStyle
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.EmptyCoroutineContext.get


@Composable
fun DeletedCowList(onRestore: () ->Unit, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    val sumVM = AppData.summaryViewModel

    var boxAlert: AlertInfo? by remember { mutableStateOf(null) }
    var showDialog by remember { mutableStateOf(false) }

    LaunchedEffect(true) {
        sumVM.getDeletedList()
    }

    Column(
        modifier = Modifier.fillMaxWidth(96.percent)
            .padding(4.px)
            .backgroundColor(Colors.White)
            .borderRadius(r = 10.px)
            .boxShadow(2.px, (-1).px, 2.px, color = AppColors.Gray.copyf(0.5f))
            .minHeight(400.px)
            .maxHeight(600.px)
            .overflow(Overflow.Hidden)
            .maxWidth(480.px),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        DialogTitle("Silinen hayvanlar".tr()) {
            onClose()
        }
        VDivider()
        Column(
            modifier = Modifier
                .fillMaxSize()
                .overflow { y(Overflow.Auto) }
                .padding(top = 6.px, leftRight = 6.px)
                .backgroundColor(Colors.Gray.copyf(alpha = 0.05f))
                //.boxShadow((-1).px, (-1).px, 2.px, 0.px, color = Colors.Gray.copyf(alpha = 0.4f), inset = true)
        ) {
            if (sumVM.deletedList.isNullOrEmpty()){
                SpanText(text = "Son 3 gün içinde silinen hayvan listeniz gösterilir, bu süre içinde Geri Alma imkanı sağlar.",
                    modifier = Modifier
                        .color(AppColors.DarkRed)
                    .fontSize(0.8.cssRem))
            }
            sumVM.deletedList?.forEach { delCow ->
                DeletedCowRow(delCow){
                    scope.launch {
                        sumVM.undoDeletedcow(delCow.hid)
                    }

                }
            }
        }
        Spacer()

    }

    if (boxAlert != null ) {
        DialogView {
            AlertBox(boxAlert!!)
        }
    }
}

@Composable
private fun DeletedCowRow(cow: DeletedCow, onRestore: () -> Unit) {
    var showButons by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier.fillMaxWidth()
            .margin(bottom = 2.px)
            .padding(topBottom = 6.px, leftRight = 4.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(modifier = Modifier.fillMaxWidth()
            .onMouseOver {
                showButons = true
            }
            .onMouseLeave {
                showButons = false
            },
            verticalAlignment = Alignment.CenterVertically
        ) {
            //console.log("herd", herdGrup)
            Span(
                attrs = Modifier
                    .fontSize(14.px)
                    .color(AppColors.TextColor)
                    .fontWeight(FontWeight.Medium)
                    .toAttrs()
            ) {
                Text(cow.kupe)
                if(cow.name.isNotEmpty()){
                    Span(
                        attrs = Modifier
                            .fontSize(13.px)
                            .color(AppColors.TextColor.opacity(0.7f))
                            .fontWeight(FontWeight.Normal)
                            .toAttrs()
                    ) {
                        Text(", ${cow.name}")
                    }
                }
            }
            Spacer()

            if (showButons) {
                Row(
                    modifier = TextButtonStyle.toModifier()
                        .color(AppColors.PrimaryDark)
                        .padding(leftRight = 6.px, topBottom = 2.px)
                        .margin(right = 6.px)
                        .onClick {
                            onRestore()
                        }
                    ,
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.End
                ) {
                    SpanText(text = "Geri Al", modifier = Modifier
                        .fontSize(0.9.cssRem))
                    FaIcon("undo", modifier = Modifier
                        .margin(left = 6.px)
                        .onClick {
                            onRestore()
                        },IconCategory.SOLID,
                        size = IconSize.LG
                    )

                }
            }

            Span(
                attrs = Modifier
                    .fontSize(15.px)
                    .textAlign(TextAlign.End)
                    .color(AppColors.DarkRed)
                    .fontWeight(FontWeight.Medium)
                    .toAttrs()
            ) {
                Text(cow.date)
            }
        }
        VDivider()
    }
}


