package com.kelimesoft.cowmaster.pages.notif

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.cowdetails.DetailsButton
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.AppStorage
import com.kelimesoft.cowmaster.utils.tr
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Switch
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun NotifBar(showTmm: MutableState<Boolean>, search: MutableState<String>,
              breakpoint: Breakpoint,onAdd: () -> Unit) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .fontFamily(Values.FONT_FAMILY)
            .padding(6.px)
            .boxShadow(
                offsetX = 1.px,
                offsetY = 1.px,
                color = Colors.Black.copyf(alpha = 0.1f)
            )
            .minWidth(240.px)
            .backgroundColor(AppColors.BarColor)
            .height(Values.barHeight),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        NotifSearch(search)

        Row(
            modifier = Modifier.margin(2.px),
            verticalAlignment = Alignment.CenterVertically) {
            Switch(
                checked = showTmm.value,
                onCheckedChange = {
                    showTmm.value = it
                    AppStorage.setBool(AppStorage.notifShowAll, it)
                },
                colorScheme = ColorSchemes.BlueGray,
                modifier = Modifier.color(AppColors.Primary)
            )
            SpanText("notifbar_showtmm".tr(), modifier = Modifier
                .fontSize(15.px)
                .color(AppColors.TextColor)
                .margin(left = 2.px, right = 4.px)
            )
        }


        DetailsButton(
            if (breakpoint > Breakpoint.SM) "notifbar_add".tr() else " + "
        ){
            onAdd()
        }


    }
}

@Composable
fun NotifSearch(text: MutableState<String>) {
    Input(
        type = InputType.Text,
        attrs = SearchBarStyles.toModifier()
            .attrsModifier {
                attr("placeholder", "list_barara".tr())
            }
            .toAttrs {
                onInput {
                    text.value = it.value
                }
            }
    )
}
